// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-dialog-container {
  position: fixed;
  top: 50%;
  left: 50%;
  opacity: 0;
  z-index: -1;
}
.modal-dialog-container.visible {
  opacity: 1;
  z-index: 50;
}
.modal-dialog-container.scrollable .modal-dialog {
  max-height: 800px;
  overflow-y: auto;
}
.modal-dialog-container.modal-lrg .modal-dialog {
  width: 775px !important;
  margin-left: -387px !important;
}
.modal-dialog-container.modal-xlrg .modal-dialog {
  width: 975px !important;
  margin-left: -487px !important;
}
.modal-dialog-container .modal-dialog {
  position: relative;
  min-height: 200px;
  width: 550px;
  margin-left: -275px;
  padding: 20px;
  background-color: #ffffff;
  border: 3px solid #7F6C2E;
  padding-left: 30px;
  display: block;
  margin-bottom: 0px;
}
.modal-dialog-container .modal-dialog.suprise {
  margin-bottom: -10000px;
}
.modal-dialog-container .modal-dialog h2 {
  margin-top: 0;
}
.modal-dialog-container .modal-dialog .modal-body {
  padding: 0;
  margin-bottom: 34px;
}
.modal-dialog-container .modal-dialog > .close-dialog {
  position: absolute;
  top: 0;
  margin-top: 20px;
  right: 0;
  margin-right: 20px;
  height: 24px;
  width: 24px;
  background-image: url('hover-x.51d99dd21ec0e20c.svg');
  background-position: 99% 3%;
  background-repeat: no-repeat;
}
.modal-dialog-container .modal-dialog > .close-dialog:hover {
  background-image: url('unselected-x.94f7db39b7176d24.svg');
  cursor: pointer;
}
.modal-dialog-container .modal-dialog .buttons {
  text-align: right;
}
.modal-dialog-container .modal-dialog .buttons button {
  text-align: center;
  margin-left: 10px;
}
.modal-dialog-container .modal-dialog .buttons .modal-buttons {
  display: inline-block;
}
.modal-dialog-container .modal-dialog > #final-modal-item {
  padding-bottom: 60px;
}
.modal-dialog-container .modalOverflow {
  overflow-y: scroll;
}
`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
