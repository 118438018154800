export const GLOBALS = {
    timeFormat: 'HH:mm',
    dateFormat: 'DD/MM/YYYY',
    dateTimeFormat: 'DD/MM/YYYY HH:mm',
    dayNameFormat: 'dddd DD/MM/YYYY',
    errorToastTranslationStart: 'ERROR_TOAST.ERROR_CODE.',
    timeFormatRegex: /^(\d{2}):(\d{2}).*/,
    workItemOtherValue: 'other',
    rteArrangementMaxLength: 5000,
    recursiveMaxLimit:20
  };

  export const UserRole = {
    journalOffice: "JournalOffice"
  }