// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sections-form {
  padding: 20px 0;
}
.sections-form .section-buttons {
  display: flex;
  justify-content: flex-end;
  padding: 20px 0 0;
}
.sections-form .section-fixed-time {
  padding: 10px 0;
}
.sections-form .title-wrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 10px 0;
  gap: 5px;
}
.sections-form .title-wrap .section-title {
  flex-grow: 1;
}
.sections-form .title-wrap .text-wrapper {
  display: block;
}
.sections-form .title-wrap .text-wrapper textarea {
  resize: vertical;
  min-width: 100%;
  min-height: 20px;
}
.sections-form .title-wrap .translation-labels {
  width: calc(50% - 5px);
  display: flex;
  justify-content: space-between;
  font-family: 'Montserrat-Light', sans-serif;
  padding-top: 10px;
  font-weight: bold;
  padding: 0;
}
.sections-form .title-wrap .translate-btn-wrapper {
  display: flex;
  flex-direction: row-reverse;
  padding-top: 10px;
}
.sections-form .flex-reverse {
  flex-direction: row-reverse;
}
.sections-form .time-wrap {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}
.sections-form .time-wrap__start {
  width: 100%;
  padding: 0 10px 10px 0;
}
.sections-form .time-wrap__start .time-wrapper {
  width: 100%!important;
}
.sections-form .time-wrap__end {
  width: 100%;
}
.sections-form .time-wrap__end .time-wrapper {
  width: 100%!important;
}
.sections-form .section-duration {
  padding: 10px 0;
}
.sections-form .section-duration .text-wrapper {
  width: 49.5%;
}
.sections-form .section-details {
  padding: 10px 0;
}
.sections-form .section-details .text-wrapper {
  width: 100%;
}
.sections-form .section-details .text-wrapper textarea {
  width: 100%!important;
}
.sections-form .section-arrangements {
  padding: 10px 0;
}
.sections-form .section-arrangements .members-wrap {
  display: flex;
  justify-content: space-between;
}
.sections-form .section-arrangements .members-wrap__checkbox {
  margin-top: -5px;
}
.sections-form .section-arrangements .members-wrap__checkbox input {
  margin-left: 5px;
}
.sections-form .proposed-title {
  padding: 10px 0;
}
.sections-form .proposed-title .text-wrapper {
  width: 100%;
}
.sections-form .proposed-title .text-wrapper textarea {
  width: 100%!important;
}
.sections-form .section-dissenting {
  padding: 10px 0;
}
.sections-form .add-new-workitem {
  display: block;
  margin: 1rem 0;
}
.sections-form .translate-placeholder {
  padding-top: 10px;
}
.sections-form .translate-placeholder p {
  margin-left: 30px;
}
.dragable {
  display: flex !important;
  align-content: center;
  align-items: center;
  padding: 5px 0;
}
.dragable ::ng-deep oir-work-item-instance {
  width: 100%;
}
.dragable ::ng-deep oir-work-item-instance .instance {
  border: 1px solid #7F6C2E !important;
  border-left: 5px solid #b39841 !important;
}
.dragable .drag-element {
  width: 18px;
}
.dragable .drag-element img {
  position: relative;
  margin-top: 14px;
}
.cdk-drag-placeholder {
  opacity: 0;
}
.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
.dragable-items.cdk-drop-list-dragging .dragable:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
