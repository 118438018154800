// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.datepicker {
  position: relative;
  font-family: Montserrat-Light;
  font-size: 15px;
}
.datepicker :-ms-input-placeholder {
  color: #777;
}
.datepicker.invalid {
  background-color: #ffe5e9;
  border: 1px solid #c30e2e;
}
.datepicker.invalid .input {
  background-color: #ffe5e9;
  color: #c30e2e;
}
.datepicker .clear-input {
  position: absolute;
  z-index: 5;
  bottom: 5px;
  right: 40px;
  height: 24px;
  width: 24px;
  background-image: url('cross.94f7db39b7176d24.svg');
  background-repeat: no-repeat;
  background-position: center;
  visibility: hidden;
}
.datepicker .clear-input:hover {
  background-image: url('cross-hover.51d99dd21ec0e20c.svg');
  cursor: pointer;
}
.datepicker .input {
  height: 40px;
  background-color: #ffffff;
  border: 1px solid #d4cfbe;
  padding: 8px 11px 8px 11px;
  font-family: Montserrat-Light;
  font-size: 15px;
  position: relative;
}
.datepicker .input input {
  background-color: transparent;
  width: 100%;
  border: none;
  outline: none;
  cursor: inherit;
}
.datepicker .input input::-ms-clear {
  display: none;
}
.datepicker .input .button {
  transition: background-image 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  position: absolute;
  top: 8px;
  right: 11px;
  background-image: url('unselected-cal.e059ca7647f9d3ba.svg');
  border: none;
  height: 24px;
  width: 24px;
}
.datepicker .input .button.active {
  background-image: url('selected-cal.9603b1f4a14fbef4.svg');
}
.datepicker .input:hover:not(.disabled) {
  cursor: pointer;
}
.datepicker .input:hover:not(.disabled) .button {
  transition: background-image 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  background-image: url('selected-cal.9603b1f4a14fbef4.svg');
}
.datepicker .input:hover:not(.disabled) .clear-input {
  visibility: visible;
}
.datepicker .input.disabled {
  background-color: #f4f2ea;
}
.datepicker .calendar {
  z-index: 11;
  position: absolute;
  left: 0;
  width: 310px;
  min-height: 315px;
  background-color: #ffffff;
  margin-bottom: 80px;
}
.datepicker .calendar.visible {
  display: block;
  z-index: 20;
}
.datepicker .calendar .calendar-head {
  -webkit-user-select: none;
          user-select: none;
}
.datepicker .calendar .calendar-head .circle {
  display: inline-block;
  width: 30px;
  height: 30px;
  background-color: #b39841;
  color: #ffffff;
  border-radius: 15px;
  position: relative;
}
.datepicker .calendar .calendar-head .circle .icon {
  width: 10px;
  height: 10px;
  position: absolute;
  left: 11px;
  top: 10px;
  border: 2px solid #ffffff;
  border-bottom: none;
  border-right: none;
  transform: rotate(-45deg);
}
.datepicker .calendar .calendar-head .circle .icon.fwd {
  left: 7px;
  transform: rotate(135deg);
}
.datepicker .calendar .calendar-head .circle:hover,
.datepicker .calendar .calendar-head .circle:focus,
.datepicker .calendar .calendar-head .circle:active {
  cursor: pointer;
  background-color: #f4f2ea;
}
.datepicker .calendar .calendar-head .circle:hover .icon,
.datepicker .calendar .calendar-head .circle:focus .icon,
.datepicker .calendar .calendar-head .circle:active .icon {
  border-color: #b39841;
}
.datepicker .calendar .calendar-head .title {
  padding-top: 2px;
  width: 100%;
  display: inline-block;
  vertical-align: top;
  text-align: center;
  font-family: Montserrat-Semibold;
  font-size: 20px;
  font-weight: 600;
  color: #b39841;
  margin-bottom: 3px;
}
.datepicker .calendar .calendar-body {
  font-family: Montserrat-Light;
  color: #444444;
  font-size: 15px;
  border-top: solid 1.5px #5e5e5e;
  margin: 7px 2px 0px 2px;
  padding: 12px 2px 0 0;
}
.datepicker .calendar .calendar-body .day-label-container .day-label {
  width: 38px;
  height: 34px;
  text-align: center;
  float: left;
  margin: 0 2px 1px 0;
}
.datepicker .calendar .calendar-body .box-container .box {
  padding-top: 6px;
  width: 38px;
  height: 38px;
  background-color: #f4f2ea;
  border: 1px solid #F9F8F4;
  float: left;
  margin: 0 2px 1px 0;
  text-align: center;
}
.datepicker .calendar .calendar-body .box-container .box:hover {
  background-color: #b39841;
  border-color: #ffffff;
  color: #ffffff;
  cursor: pointer;
}
.datepicker .calendar .calendar-body .box-container .box.active {
  background-color: #b39841;
  border-color: #ffffff;
  color: #ffffff;
}
.datepicker .calendar .calendar-body .box-container .box.disabled {
  background-color: #f4f2ea;
  border: 1px solid #F9F8F4;
  color: #999999;
}
.datepicker ::ng-deep .dropdown-menu {
  max-height: 300px;
  overflow-y: scroll;
  margin: 0px;
  padding: 0px;
  top: 26px;
}
.datepicker ::ng-deep option:hover,
.datepicker ::ng-deep option:focus,
.datepicker ::ng-deep option:active {
  background-color: red !important;
}
.datepicker ::ng-deep .ngb-dp-header {
  display: none !important;
}
.datepicker ::ng-deep ngb-datepicker {
  border: none !important;
  padding: 1rem;
}
.datepicker ::ng-deep ngb-datepicker {
  top: 37px !important;
  left: 0px !important;
}
.datepicker ::ng-deep ngb-datepicker-month-view {
  width: 310px;
  min-height: 315px;
  padding: 9px 11px 14px 11px;
  background-color: #ffffff;
}
.datepicker ::ng-deep ngb-datepicker-month-view .ngb-dp-arrow {
  font-weight: 400;
  color: #b39841;
  border-radius: 0;
}
.datepicker ::ng-deep ngb-datepicker ::ng-deep .ngb-dp-week ::ng-deep .ngb-dp-day {
  height: 38px;
  width: 38px;
  background-color: #f4f2ea;
  margin: 0 3px 2px 0;
}
.datepicker ::ng-deep ngb-datepicker ::ng-deep .ngb-dp-week ::ng-deep .ngb-dp-day ::ng-deep .btn-light {
  height: 38px;
  width: 38px;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
.datepicker ::ng-deep ngb-datepicker ::ng-deep .ngb-dp-week ::ng-deep .ngb-dp-day ::ng-deep .btn-light:hover,
.datepicker .btn-light.range {
  background-color: #7F6C2E;
  color: #ffffff;
}
.datepicker ::ng-deep ngb-datepicker ::ng-deep .ngb-dp-week ::ng-deep .ngb-dp-day ::ng-deep .btn-light:focus {
  background-color: #b39841;
  color: #ffffff;
}
.datepicker ::ng-deep ngb-datepicker ::ng-deep .ngb-dp-week ::ng-deep .ngb-dp-day ::ng-deep .btn-light.faded {
  background-color: #b39841;
  color: #ffffff;
}
.datepicker ::ng-deep ngb-datepicker ::ng-deep .ngb-dp-week ::ng-deep .ngb-dp-day ::ng-deep .btn-light.text-muted {
  color: #999;
}
.datepicker ::ng-deep ngb-datepicker ::ng-deep .ngb-dp-week ::ng-deep .ngb-dp-weekday {
  height: 30px;
  width: 40px;
  font-family: Montserrat-Light;
  color: #444444;
  font-size: 15px;
  font-style: normal;
}
.datepicker ::ng-deep ngb-datepicker-navigation {
  height: 40px;
  margin-bottom: 10px;
}
.datepicker ::ng-deep ngb-datepicker-month-view ::ng-deep .ngb-dp-weekdays {
  border-bottom: none !important;
  border-top: solid 1.5px #d4cfbe !important;
  padding-top: 10px !important;
}
.datepicker ::ng-deep ngb-datepicker ::ng-deep ngb-datepicker-navigation ::ng-deep :focus {
  box-shadow: none !important;
  outline: none !important;
}
.datepicker ::ng-deep ngb-datepicker ::ng-deep .ngb-dp-months ::ng-deep :focus {
  outline-color: #b39841;
}
.datepicker ::ng-deep ngb-datepicker ::ng-deep select {
  border: 1px solid #d4cfbc;
  background-color: #ffffff;
  min-height: 40px;
  padding: 0 30px 0 9px;
  display: flex;
  position: relative;
  cursor: pointer;
  font-family: Montserrat-Light;
  font-size: 15px !important;
  color: #444444;
  width: 100%;
}
.datepicker ::ng-deep ngb-datepicker ::ng-deep ngb-datepicker-navigation {
  margin-top: 20px;
}
.datepicker ::ng-deep ngb-datepicker ::ng-deep ngb-datep icker-navigation-select ::ng-deep select:nth-child(1) {
  margin-right: 10px !important;
}
.datepicker ::ng-deep ngb-datepicker ::ng-deep ngb-datepicker-navigation-select ::ng-deep select:focus {
  border: 1px solid #b39841 !important;
}
.datepicker ::ng-deep ngb-datepicker ::ng-deep .bg-primary {
  color: white;
  background-color: #b39841;
}
.ngb-dp-arrow {
  font-weight: 400;
  color: #b39841;
  border-radius: 0;
}
.ngb-dp-arrow {
  display: inline-block;
  width: 30px !important;
  height: 30px !important;
  background-color: #b39841;
  color: #ffffff;
  border-radius: 15px;
  position: relative;
  flex: unset !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
  outline: none !important;
  box-shadow: none !important;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
  .ngb-dp-arrow {
    flex-grow: 0 !important;
  }
}
.ngb-dp-arrow button {
  height: 30px !important;
  width: 30px !important;
  border-radius: 15px !important;
  margin: 0px !important;
}
.ngb-dp-arrow button {
  color: #ffffff;
}
.ngb-dp-arrow button:focus {
  color: #b39841;
  background-color: #f4f2ea;
}
.ngb-dp-arrow button:hover {
  color: #b39841;
  background-color: #f4f2ea;
}
.datepicker ::ng-deep select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}
.datepicker ::ng-deep select:focus::-ms-value {
  color: #444444;
  background-color: transparent;
}
.datepicker ::ng-deep select {
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  display: inline-block;
  height: 40px;
  background-color: #ffffff;
  border: 1px solid #d4cfbe;
  padding-left: 15px;
  padding-right: 40px;
  background-image: url('closed-chev-big.847c1b52d4b4a306.svg');
  background-position: right 9px top 7px;
  background-repeat: no-repeat;
  font-family: "Montserrat-Light", sans-serif;
  font-size: 15px;
}
.datepicker ::ng-deep select.disabled,
.datepicker ::ng-deep select[disabled] {
  background-color: #bfbbaa;
  border-color: #bfbbaa;
  color: #777777;
  background-image: url('closed-chev-big-disabled.1ee371182a4805cb.svg');
}
.datepicker ::ng-deep select ::ng-deep option {
  outline: none;
  padding: 5px;
  border: 1px solid #d4cfbe;
  background-color: #ffffff;
  font-size: 15px;
  font-family: "Montserrat-Light", sans-serif;
}
::ng-deep .ngb-dp-weekdays {
  border-bottom: none !important;
}
.calendar-head {
  background-color: white;
  width: 312px;
  border: 1px solid #dfdfdf;
  border-bottom: none !important;
}
.calender-navigate {
  height: 35px;
  margin-top: 10px;
}
.calender-navigate.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.calendar-month {
  margin: 1rem 1rem 0;
  height: 25px;
  border-bottom: 1px solid #dfdfdf;
  padding-bottom: 1rem;
  width: auto;
  height: 36px;
  line-height: 2rem;
  display: flex;
}
.inline-div {
  display: inline-block;
}
.calender-navigate ::ng-deep .show {
  display: inline-block !important;
}
.calender-navigate .dropdown-menu {
  display: inline-block !important;
  min-width: 100px;
  left: 10px;
  top: 34px;
}
.d-inline-block ::ng-deep .dropdown {
  display: inline-block;
}
.d-inline-block ::ng-deep .dropdown ::ng-deep button {
  border-radius: 0px;
  background-color: white;
  border: 1px solid #d4cfbc;
  width: 90px;
  margin-right: 5px;
  margin-left: 10px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 15px;
}
.d-inline-block ::ng-deep .dropdown-menu ::ng-deep button {
  width: 100%;
  margin: 0px;
  height: 34px;
  border: none;
  text-align: left;
  padding-left: 12px;
}
.d-inline-block ::ng-deep .dropdown-menu ::ng-deep button:hover,
.d-inline-block ::ng-deep .dropdown-menu ::ng-deep button:active,
.d-inline-block ::ng-deep .dropdown-menu ::ng-deep button:focus,
.d-inline-block ::ng-deep .dropdown-menu ::ng-deep button.selected {
  background-color: #7F6C2E;
  color: white;
}
::ng-deep .dropdown-toggle {
  outline: none !important;
  box-shadow: none !important;
}
.d-inline-block ::ng-deep ::ng-deep .dropdown-toggle:hover,
.d-inline-block ::ng-deep ::ng-deep .dropdown-toggle:active,
.d-inline-block ::ng-deep ::ng-deep .dropdown-toggle:focus {
  border: 1px solid #7F6C2E !important;
}
.calender-navigate .circle:hover,
.calender-navigate .circle:focus,
.calender-navigate .circle:active {
  outline: none !important;
  box-shadow: none !important;
}
.dropdown-menu {
  opacity: 0;
  z-index: -20;
}
.inline-div .showDropDown {
  opacity: 1;
  z-index: 20;
}
.collapse {
  display: block;
  width: 23px;
  height: 23px;
  float: right;
  background-image: url('closed-chev-big.847c1b52d4b4a306.svg');
}
.collapse.active {
  background-image: url('open-chev-big.f05d398ec6e57c90.svg');
}
.collapse:hover {
  cursor: pointer;
}
`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
