import {
  Component,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  OnInit
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AnnouncementOfProposedArrangementsDays } from 'src/app/shared/models/order-of-business.model';
import { ToggleableAccordionComponent } from 'src/app/shared/components/toggleable-accordion/toggleable-accordion.component';

@Component({
  selector: 'oir-announcement-proposed-arrangement',
  templateUrl: './announcement-proposed-arrangement.component.html',
  styleUrls: ['./announcement-proposed-arrangement.component.less']
})
export class AnnouncementProposedArrangementComponent implements OnInit {
  public canToggle = true;
  public dayTitle: string;

  @Input()
  announcementOfProposedArrangementsDays: AnnouncementOfProposedArrangementsDays;
  @Output() toggleAccordion: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('accordionComponent')
  accordionComponent: ToggleableAccordionComponent;

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    const dayOfTheWeek =
      this.announcementOfProposedArrangementsDays.dayName.split(' ')[0];
    this.dayTitle = this.translateService.instant(
      'ORDER_OF_BUSINESS_ANNOUNCEMENT_OFPROPOSED_ARRANGEMENTS.TITLE',
      [dayOfTheWeek]
    );
  }

  /**
   * Method to close accordion
   */
  public closeDayAccordionProposedArrangment(): void {
    this.canToggle = true;
    setTimeout(() => {
      this.accordionComponent.click();
    }, 1);
  }

  /**
   * Event emmiter after toggle
   * @param isOpen boolean
   */
  public afterToggle(isOpen: boolean) {
    this.toggleAccordion.emit(isOpen);
  }
}
