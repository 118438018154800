import {
  Component,
  Input,
  ViewChild,
  OnInit,
  OnChanges
} from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import {
  ToastNotificationsService,
  TypeaheadValue
} from 'proceduralsystem-clientcomponents';
import { ReportService } from 'src/app/services/report.service';
import { take } from 'rxjs/operators';
import { SharedService } from 'src/app/services/shared.service';
import { CustomModalComponent } from 'src/app/shared/components/custom-modal/custom-modal.component';
import { CancelConfirmModalComponent } from 'src/app/create-report/cancel-confirm-modal/cancel-confirm-modal.component';
import { isEmpty } from 'lodash-es';
import { ReportStatuses } from 'src/app/shared/report.enum';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'oir-action-bar-email-modal',
  templateUrl: './action-bar-email-modal.component.html',
  styleUrls: ['./action-bar-email-modal.component.less']
})
export class ActionBarEmailModalComponent implements OnInit, OnChanges {
  @Input() startBcrTime: string;
  @Input() endBcrTime: string;
  @Input() report: any;

  @ViewChild('actionBarEmailModal') actionBarEmailModal: CustomModalComponent;
  @ViewChild('cancelConfirmModalComponent')
  cancelConfirmModalComponent: CancelConfirmModalComponent;

  public form: UntypedFormGroup;
  public recipientsEmails: TypeaheadValue<any>[] = [];
  public emailSubjectDefaultValue: string;
  public emailBodyDefaultValue: string;
  public revisedBcrNumber: string;
  public updateBcrNumber: string;
  public sendEmailAction = false;

  private defaultEmail = 'journal.office@oireachtas.ie';

  constructor(
    private formBuilder: UntypedFormBuilder,
    private translateService: TranslateService,
    private toastService: ToastNotificationsService,
    private reportService: ReportService,
    private sharedService: SharedService
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      toEmail: [[], Validators.required],
      ccEmail: [],
      bccEmail: [],
      emailSubject: ['', Validators.maxLength(250)],
      emailBody: ['', Validators.maxLength(1000)]
    });
    this.reportService
      .getMailsList()
      .pipe(take(1))
      .subscribe(res => {
        res.map(emails => {
          if (emails.recipientEmail !== null) {
            this.recipientsEmails.push({
              value: emails.recipientId,
              title: emails.recipientEmail
            });
          }
        });
      });
    // Only temporary for development team.
    this.recipientsEmails = this.sharedService.addDevEmails(
      this.recipientsEmails
    );
  }

  ngOnChanges() {
    const startDate = this.sharedService.convertDate(this.startBcrTime);
    const endtDate = this.sharedService.convertDate(this.endBcrTime);
    if (!isEmpty(this.report)) {
      this.revisedBcrNumber = this.report.reportVerison
        .toString()
        .split('.')[0];
      this.updateBcrNumber = this.report.reportVerison.toString().split('.')[1];
    }
    this.emailSubjectDefaultValue =
      startDate === endtDate
        ? this.translateService.instant(
            'VIEW_REPORT_SECTION.CIRCULATE_EMAIL.SUBJECT.DEFAULT_SINGLE_DAY_VALUE',
            [this.startBcrTime, this.revisedBcrNumber, this.updateBcrNumber]
          )
        : this.translateService.instant(
            'VIEW_REPORT_SECTION.CIRCULATE_EMAIL.SUBJECT.DEFAULT_VALUE',
            [
              this.startBcrTime,
              this.endBcrTime,
              this.revisedBcrNumber,
              this.updateBcrNumber
            ]
          );
    this.emailBodyDefaultValue =
      startDate === endtDate
        ? this.translateService.instant(
            'VIEW_REPORT_SECTION.CIRCULATE_EMAIL.BODY.DEFAULT_SINGLE_DAY_VALUE',
            [this.startBcrTime]
          )
        : this.translateService.instant(
            'VIEW_REPORT_SECTION.CIRCULATE_EMAIL.BODY.DEFAULT_VALUE',
            [this.startBcrTime, this.endBcrTime]
          );
  }

  /**
   * Method to close circulate bcr modal without cirucalting
   */
  public onModalClose(): void {
    if (!this.sendEmailAction) {
      this.cancelConfirmModalComponent.toggle();
    }
  }

  /**
   * Method to send mail
   */
  public sendEmailFile(): void {
    this.sendEmailAction = true;
    // Convert dates to BE readable
    this.report.businessScheduleDetail.bsDetailFirstDay =
      this.sharedService.convertDate(
        this.report.businessScheduleDetail.bsDetailFirstDay
      );
    this.report.businessScheduleDetail.bsDetailLastDay =
      this.sharedService.convertDate(
        this.report.businessScheduleDetail.bsDetailLastDay
      );
    this.reportService
      .actionBarSendEmail(
        this.form.controls.toEmail.value
          ? this.sharedService.convertRecipientsToString(
              this.form.controls.toEmail.value
            )
          : '',
        this.form.controls.ccEmail.value
          ? this.sharedService.convertRecipientsToString(
              this.form.controls.ccEmail.value
            )
          : '',
        this.form.controls.bccEmail.value
          ? this.sharedService.convertRecipientsToString(
              this.form.controls.bccEmail.value
            )
          : '',
        this.form.controls.emailSubject.value
          ? this.form.controls.emailSubject.value
          : '',
        this.form.controls.emailBody.value
          ? this.form.controls.emailBody.value
          : '',
        this.report
      )
      .pipe(take(1))
      .subscribe(() => {
        this.toastService.addNotification({
          title:
            this.report.reportStatusId === ReportStatuses.Draft
              ? this.translateService.instant(
                  'TOAST.BUSINESS_COMMITTEE_REPORT_SEND_EMAIL_DRAFT.TITLE'
                )
              : this.translateService.instant(
                  'TOAST.BUSINESS_COMMITTEE_REPORT_SEND_EMAIL.TITLE'
                ),
          description: this.translateService.instant(
            'TOAST.BUSINESS_COMMITTEE_REPORT_SEND_EMAIL.BODY',
            [this.startBcrTime, this.endBcrTime]
          )
        });
        this.form.reset();
        this.actionBarEmailModal.close();
        this.sendEmailAction = false;
      });
  }

  /**
   * Open the email modal
   */
  public open(): void {
    this.form.patchValue({
      toEmail: [{ value: 0, title: this.defaultEmail }],
      emailSubject: this.emailSubjectDefaultValue,
      emailBody: this.emailBodyDefaultValue
    });
    this.actionBarEmailModal.toggle();
  }

  /**
   * Method to close circulate bcr modal without cirucalting
   */
  cancelEmailModal(event): void {
    if (event) {
      this.form.reset();
      this.actionBarEmailModal.close();
    }
  }

  /**
   * Cancel the email modal
   */
  public cancel(): void {
    this.cancelConfirmModalComponent.toggle();
  }
}
