import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  Output,
  EventEmitter
} from '@angular/core';
import { Subject } from 'rxjs';
import { DashboardService } from '../dashboard-services/dashboard-service.service';
import { OutstandingItems } from 'src/app/shared/models/dashboard.model';
import { takeUntil, take } from 'rxjs/operators';
import { PaginationOptions } from 'proceduralsystem-clientcomponents';
import { WorkItems } from 'src/app/shared/report.enum';
import { Router } from '@angular/router';
import { AppConfigService } from '../../services/app-config.service';

@Component({
  selector: 'oir-outstanding-items',
  templateUrl: './outstanding-items.component.html',
  styleUrls: ['./outstanding-items.component.less']
})
export class OutstandingItemsComponent implements OnInit, OnDestroy {
  public totalOutstandingItems = {};
  public paginationList: OutstandingItems[];
  public currentPage = 1;
  public get workItems(): any {
    return WorkItems;
  }

  public outstandingQueryType: number;
  @Input() itemStatus: string;

  @Output() outstandingItemPageChange: EventEmitter<any> =
    new EventEmitter<any>();

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private dashboardService: DashboardService,
    private config: AppConfigService,
    private router: Router
  ) {}

  public ngOnInit(): void {
    this.dashboardService.outstandingItemsStore
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(res => {
        this.updateList(
          res.outstandingItems[res.queryType]
        );
        this.totalOutstandingItems[res.queryType] =
          res.outstandingCount[res.queryType];
        this.outstandingQueryType = res.queryType;
      });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  /**
   * Function to open extrenal link
   * @param url Link
   */
  public goToLink(workItemId: number): void {
    this.config.get('MotionEndpointUrl')
      .pipe(
        takeUntil(this.ngUnsubscribe),
        take(1)
      ).subscribe((motionUrl: string) => {
        if (motionUrl) {
          this.router.navigate([]).then(() => {
            window.open(`${motionUrl}?workItemId=${workItemId}`, '_blank');
          });
        }
      });
  }

  /**
   * Function to set pagination options
   * @param id Pagination id
   */
  public getPaginationOptions(id: string): PaginationOptions {
    return {
      id,
      itemsPerPage: 10,
      currentPage: this.currentPage,
      totalItems: this.totalOutstandingItems[this.outstandingQueryType]
    };
  }

  /**
   * Function whitch triggers on page change and emits data
   * @param pageNumber Page number
   */
  public onPageChange(pageNumber: number, queryType: number): void {
    const changeData = {
      pageNumber: pageNumber - 1,
      queryType,
      itemsPerPage: 10
    };
    this.outstandingItemPageChange.emit(changeData);
    this.currentPage = pageNumber;
  }

  /**
   * Function to update pagination list for each page
   * @param outstandingItemsData Data whitch should be applyed to pagination list
   */
  private updateList(outstandingItemsData: any): void {
    this.paginationList = [] as OutstandingItems[];
    if (outstandingItemsData && outstandingItemsData.length > 0) {
      for (const items of outstandingItemsData) {
        this.paginationList.push(items);
      }
    }
  }
}
