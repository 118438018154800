import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ConfigurationService,
  IAppEndpoint,
  IAppSettings
} from 'proceduralsystem-clientcomponents';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, map, pluck, switchMap, takeUntil, tap } from 'rxjs/operators';

export interface AppConfig extends IAppSettings {
  RoleId: string;
  UserId: string;
  UserRoleMemberId: string;
  ReturnUrl: string;
  AADGroupBillsAdmin: string;
  CustomHeader: string;
  defaultLang: string;

  ApiEndpoint: IAppEndpoint;
  AppId,
  BillsAdminWebUrl: string;
  UserGrade: string;
  AccessToken: string;
  Role: string;
  DelegateRoleMemberId: string;

  RoleRank: string;

  MotionEndpointUrl: string;

  CKEditor5LicenseKey: string;
}

@Injectable({ providedIn: 'root' })
export class AppConfigService {
  private http: HttpClient;
  private localSettings: AppConfig;
  private localSettings$: Observable<AppConfig> | null = null;
  private config$: Observable<AppConfig> | null = null;
  private values$ = new BehaviorSubject<AppConfig>(null);

  constructor(
    private httpHandler: HttpBackend,
    private config: ConfigurationService<AppConfig>,
  ) {
    this.http = new HttpClient(httpHandler);
  }

  init(): Observable<AppConfig> {
    this.localSettings$ = this.http.get<AppConfig>("./app.config.json").pipe(
      tap((value) => {
        this.localSettings = value;
        this.values$.next(value);
      }),
      switchMap(() => this.fetchConfig()),
      takeUntil(this.values$.pipe(filter(v => !!v))) 
    );

    return this.localSettings$;
  }

  get<T extends keyof AppConfig>(prop: T): Observable<AppConfig[T]> {
    if (!this.config$) {
      this.config$ = this.fetchConfig();
    }

    return this.config$.pipe(pluck(prop));
  }

  getValue<T extends keyof AppConfig>(prop: T): AppConfig[T] {
    const config = this.values$.getValue();

    return config ? config[prop] : null;
  }

  set<T extends keyof AppConfig>(prop: T, value: string): void {
    let config = this.values$.getValue();
    if (!config) {
      config = {} as AppConfig;
    }
    if (prop) {
      // eslint-disable-next-line
      // @ts-ignore
      config[prop] = value;

      this.values$.next(config);
      if (this.config$) {
        this.config$ = of(config);
      }
    }
  }

  setCookie(property: string, value: string, days = 30): void {
    this.config.setCookie(property, value, days);
  }

  private fetchConfig(): Observable<AppConfig> {
    return of(this.localSettings).pipe(
      switchMap((v) => this.config.getAll(v)),
      map(v => Object.assign(v, this.localSettings)),
      tap(v => this.values$.next(v))
    );
  }
}
