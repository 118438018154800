import { Component, OnInit, ViewChild } from "@angular/core";
import { DatePickerCustomComponent } from "./../shared/components/datepicker-custom/datepicker-custom.component";
import { DatePickerOptions } from "proceduralsystem-clientcomponents";
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import { CancelConfirmModalComponent } from "./cancel-confirm-modal/cancel-confirm-modal.component";
import * as moment from "moment";
import { SharedService } from "../services/shared.service";
import { GLOBALS } from "../shared/globals";
import { ReportService } from "../services/report.service";
import { take } from "rxjs/operators";
import { Router } from "@angular/router";
import { find } from "lodash-es";
import { CreateReport } from "../shared/models/report.model";
import { ReportTypes, ReportTypesTitles } from "../shared/report.enum";

@Component({
  selector: "oir-create-report",
  templateUrl: "./create-report.component.html",
  styleUrls: ["./create-report.component.less"],
})
export class CreateReportComponent implements OnInit {
  public createReportForm: UntypedFormGroup;
  public datePickerOptions: DatePickerOptions = {
    minDate: moment.utc().format(GLOBALS.dateFormat),
  };
  public dateRange = true;
  public selectedRange: string =
    moment.utc().add(1, "weeks").isoWeekday(2).format(GLOBALS.dateFormat) +
    " - " +
    moment.utc().add(1, "weeks").isoWeekday(4).format(GLOBALS.dateFormat);
  public preselectDates = true;
  public reportTypes = ReportTypes;
  public reportTypesTitles = ReportTypesTitles;

  public reportTypeSelectData = [
    {
      value: this.reportTypes.BCR,
      title: this.reportTypesTitles.BCR,
      heading: this.reportTypesTitles.BCR,
    },
    {
      value: this.reportTypes.ODR,
      title: this.reportTypesTitles.ODR,
      heading: this.reportTypesTitles.ODR,
      selected: false,
      hidden: false,
      animate: false,
      disabled: false,
    },
    {
      value: this.reportTypes.COSR,
      title: this.reportTypesTitles.COSR,
      heading: this.reportTypesTitles.COSR,
      selected: false,
      hidden: false,
      animate: false,
      disabled: true,
    },
  ];

  public invalidInputDate = false;
  public disableCreate = false;

  private currentWeek: number;

  @ViewChild("reportDatesRange") reportDatesRange: DatePickerCustomComponent;
  @ViewChild("cancelConfirmModalComponent")
  cancelConfirmModalComponent: CancelConfirmModalComponent;

  constructor(
    private sharedService: SharedService,
    private formBuilder: UntypedFormBuilder,
    private reportService: ReportService,
    private router: Router
  ) {}

  ngOnInit() {
    this.createReportForm = this.formBuilder.group({
      reportType: ["", Validators.required],
      reportDatesRange: ["", Validators.required],
    });
  }

  /**
   * Return report type title by type select value
   */
  public getReportTypeName(): string {
    return find(
      this.reportTypeSelectData,
      (val) => val.value === this.createReportForm.get("reportType").value
    ).title;
  }

  /**
   * Force blur event to preselect days (need client component fixes)
   */
  public preselectSuggestedDays(): void {
    this.reportDatesRange.onBlur(
      document.querySelector(`.reportDatesRange .datepicker`)
    );
  }

  /**
   * Disable create draft button
   */
  public disableCreateButton(event: any): void {
    this.disableCreate = event.selectingRange;
  }

  /**
   * Change `preselectDates` boolean to true to trigger date range preselect
   */
  public allowPreselect(event: number): void {
    this.dateRange = event !== this.reportTypes.ODR;
    if (!this.dateRange) {
      this.createReportForm.get("reportDatesRange").patchValue("");
      this.createReportForm.get("reportDatesRange").updateValueAndValidity();
      this.disableCreate = false;
    }
    this.preselectDates = event !== this.reportTypes.ODR;
    setTimeout(() => {
      if (this.preselectDates) {
        this.preselectSuggestedDays();
        this.preselectDates = !this.preselectDates;
      }
    }, 50);
  }

  /**
   * Disable dates not in selection week
   */
  public disableOtherWeeks(event: any): void {
    this.invalidInputDate = event.selectingRange;
    document.querySelector(".calender-navigate").classList.add("disabled");
    if (!event.selectingRange && event.date === "") {
      event.date = moment.utc().format(GLOBALS.dateFormat);
    }
    // Delay day iteration to ensure DOM element exists. Required due to client component limitations.
    setTimeout(() => {
      document
        .querySelectorAll(".ta_cr_reportDatesRange .ngb-dp-day")
        .forEach(function (v) {
          let dateAttr = v.getAttribute("aria-label");
          let eventDate = moment.utc(event.date, GLOBALS.dateFormat);
          let currentDate = moment.utc();
          let date = moment.utc(dateAttr);

          if (
            date.isoWeek() !== eventDate.isoWeek() ||
            date.isBefore(currentDate.subtract(1, "days"))
          ) {
            v.classList.add("disable-picker");
          } else {
            v.classList.remove("disable-picker");
          }
        });
    }, 50);
  }

  /**
   * Method to allow date range only present and future dates
   */
  public disableDateRangeDays(): void {
    if (this.preselectDates) {
      this.preselectSuggestedDays();
      this.preselectDates = !this.preselectDates;
    }
    // Delay day iteration to ensure DOM element exists. Required due to client component limitations.
    setTimeout(() => {
      document.querySelectorAll(".ngb-dp-arrow-btn").forEach((btn) => {
        btn.addEventListener("click", () => {
          this.disableDateRangeDays();
        });
      });

      document
        .querySelectorAll(".dropdownMonth .dropdown-item")
        .forEach((item) => {
          item.addEventListener("click", () => {
            this.disableDateRangeDays();
          });
        });

      document
        .querySelectorAll(".dropdownYear .dropdown-item")
        .forEach((item) => {
          item.addEventListener("click", () => {
            this.disableDateRangeDays();
          });
        });

      if (document.querySelector(".ta_cr_reportDatesRange .datepicker")) {
        const today = moment().startOf("day");
        document
          .querySelectorAll(".ta_cr_reportDatesRange .ngb-dp-day")
          .forEach(function (v) {
            const dates = moment.utc(new Date(v.getAttribute("aria-label")));
            if (dates.isBefore(today)) {
              v.classList.add("disable-picker");
            } else {
              v.classList.remove("disable-picker");
            }
          });
      }
    }, 50);
  }

  /**
   * Validate dates based on user input
   */
  public validateDates(enteredDates: any): void {
    let dates = this.selectedRange;
    if (enteredDates) {
      dates = enteredDates.target
        ? enteredDates.target.value.split("-")
        : enteredDates.split("-");
    }

    this.currentWeek = moment.utc(dates[0], GLOBALS.dateFormat).isoWeek();
    this.invalidInputDate = this.sharedService.validateDates(
      dates,
      this.currentWeek,
      this.dateRange
    );
  }

  /**
   * Opens cancel confirmation modal
   */
  public openCancelModal(): void {
    this.cancelConfirmModalComponent.toggle();
  }

  /**
   * Closes cancel confirmation modal and clears form if confirmed
   */
  public cancelModalDecision(event: boolean): void {
    this.cancelConfirmModalComponent.toggle();
    if (event) {
      this.createReportForm.reset();
    }
  }

  /**
   * Send call to API to create draft report
   */
  public createDraftReport() {
    const dates = this.createReportForm
      .get("reportDatesRange")
      .value.split("-");
    const body: CreateReport = {
      startDate: this.sharedService.convertDateStringToBEAcceptableValue(
        dates[0]
      ),
      endDate:
        this.createReportForm.get("reportType").value === this.reportTypes.ODR
          ? this.sharedService.convertDateStringToBEAcceptableValue(
              this.createReportForm.get("reportDatesRange").value
            )
          : this.sharedService.convertDateStringToBEAcceptableValue(dates[1]),
    };
    this.reportService
      .createReportDraft(body)
      .pipe(take(1))
      .subscribe((res) => {
        this.router.navigate(["/bcr-report/" + res.joReportId]);
      });
  }
}
