import {
  Component,
  OnInit,
  ViewChild,
  OnDestroy,
  Input,
  AfterViewInit
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators, UntypedFormArray } from '@angular/forms';
import { TypeaheadValue } from 'proceduralsystem-clientcomponents';
import { SharedService } from 'src/app/services/shared.service';
import {
  debounceTime,
  filter,
  takeUntil,
  map,
  distinctUntilChanged
} from 'rxjs/operators';
import { ToggleableAccordionComponent } from 'src/app/shared/components/toggleable-accordion/toggleable-accordion.component';
import { CancelConfirmModalComponent } from 'src/app/create-report/cancel-confirm-modal/cancel-confirm-modal.component';
import { Subscription, Subject, Observable } from 'rxjs';
import { ReportService } from 'src/app/services/report.service';
import { ReportStoreService } from 'src/app/services/report-store.service';
import { isEmpty, isArray, findIndex, findLastIndex, last } from 'lodash-es';
import * as moment from 'moment';
import { FormTemplate, WorkItemTypes } from 'src/app/shared/report.enum';
import {
  BusinessScheduleDay,
  WorkItem
} from 'src/app/shared/models/report.model';
import { GLOBALS } from 'src/app/shared/globals';
import { WorkItemFormComponent } from 'src/app/shared/components/work-item-form/work-item-form.component';
import OirCkEditor from 'proceduralsystem-ckeditor';
import { OirCkEditorConfig } from 'proceduralsystem-ckeditor';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular/ckeditor.component';
import { EditorConfig } from 'src/app/shared/models/editor-config.model';
import { AppConfigService } from 'src/app/services/app-config.service';

@Component({
  selector: 'oir-add-new-section',
  templateUrl: './add-new-section.component.html',
  styleUrls: ['./add-new-section.component.less']
})
export class AddNewSectionComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() businessScheduleDay: BusinessScheduleDay;
  @Input() disableSection: boolean;

  @ViewChild('toggleableAccordion')
  toggleableAccordion: ToggleableAccordionComponent;
  @ViewChild('cancelConfirmModalComponent')
  cancelConfirmModalComponent: CancelConfirmModalComponent;

  @ViewChild('workItemsForm') workItemsForm: WorkItemFormComponent;

  public canToggle = true;
  public addNewSectionForm: UntypedFormGroup;
  public partiesData$: Observable<any>;
  public arrangementData: TypeaheadValue<any>[] = [];
  public membersData$: Observable<any>;
  public departmentData$: Observable<any>;
  public sectionTypesData$: Observable<any>;
  public sectionTypesData: Array<any> = [];
  public keyUp = new Subject<{ id: number; value: string }>();
  
  Editor = OirCkEditor;
  ckEditorConfig: OirCkEditorConfig;

  public get arrangementsFormArray(): UntypedFormArray {
    return this.addNewSectionForm.get(
      'sectionArrangementFormGroup'
    ) as UntypedFormArray;
  }
  public FormTemplate = FormTemplate;
  public customSectionData;
  public showWorkItems = false;
  public hasWorkItems = false;
  public minimumCharacter = false;

  private subscription = new Subscription();
  private ngUnsubscribe = new Subject<void>();
  private departmentMultiselectLookup = [];

  constructor(
    private fb: UntypedFormBuilder,
    private sharedService: SharedService,
    private reportService: ReportService,
    private reportStoreService: ReportStoreService,
    private configurationService: AppConfigService
  ) {
    this.ckEditorConfig = {
      ...EditorConfig,
      licenseKey: this.configurationService.getValue('CKEditor5LicenseKey')
    }
  }

  public ngOnInit(): void {
    this.addNewSectionForm = this.fb.group({
      newSectionType: [[], Validators.required],
      newSectionTitle: ['', [Validators.required, Validators.maxLength(250)]],
      newSectionTitleGa: ['', [Validators.maxLength(250)]],
      parties: [{ value: [], disabled: true }, Validators.required],
      department: [{ value: [], disabled: true }, Validators.required],
      isStartTimeFixed: false,
      sectionStartTime: [{ value: '', disabled: true }],
      sectionEndTime: [{ value: '', disabled: true }],
      sectionDuration: [
        '',
        [
          Validators.required,
          Validators.pattern('^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$')
        ]
      ],
      sectionDetails: [{ value: false, disabled: true }],
      sectionDetailsFreeTextBox: [
        '',
        [Validators.required, Validators.maxLength(500)]
      ],
      nextWeeksBusiness: [{ value: '', disabled: true }],
      nextWeeksBusinessFreeTextBox: [
        { value: '', disabled: true },
        [Validators.required, Validators.maxLength(500)]
      ],
      sectionArrangements: [{ value: false, disabled: true }],
      sectionArrangementFormGroup: this.fb.array([]),
      arrangementsProposedTitle: [
        { value: '', disabled: true },
        [Validators.required, Validators.maxLength(1000)]
      ],
      isHiddenOnDailBusiness: false,
    });

    // Get parties data
    this.partiesData$ = this.reportService.party.pipe(takeUntil(this.ngUnsubscribe));

    // Get department data
    this.departmentData$ = this.reportService.departments.pipe(
      takeUntil(this.ngUnsubscribe),
      map(departments =>
        departments.map(department => {
          const departmentObj = {
            departmentId: department.departmentId,
            departmentName: department.departmentName
          };
          this.departmentMultiselectLookup.push(departmentObj);
          return {
            value: department.departmentId,
            title: department.departmentName
          };
        })
      )
    );

    // Get arrangement data
    this.reportService.sectionArrangement
      .pipe(
        takeUntil(this.ngUnsubscribe),
        map(arrangements =>
          arrangements.map((arrangement, i) => ({
            value: i,
            title: arrangement
          }))
        )
      )
      .subscribe(res => (this.arrangementData = res));

    // Get members data
    this.membersData$ = this.reportService.members.pipe(
      takeUntil(this.ngUnsubscribe),
      map(members =>
        members.map(member => ({ value: member.id, title: member.name }))
      )
    );

    // Get section types data
    this.sectionTypesData$ = this.reportService.sectionTypes.pipe(
      takeUntil(this.ngUnsubscribe),
      map(sectionType =>
        sectionType.map(type => {
          const types = {
            bdSectionTypeId: type.bdSectionTypeId,
            bdSectionTypeName: type.bdSectionTypeName
          };
          this.sectionTypesData.push(types);
          return { value: type.bdSectionTypeId, title: type.bdSectionTypeName };
        })
      )
    );

    this.selectSectionTemplate();
    this.subscribeToFormValueChange();
    this.onArrangementToggle();
  }

  public ngAfterViewInit(): void {
    this.onDetailsToggleChange();
    this.onNextWeeksBusinessChange();
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  /**
   * Function to check is value empty
   * @param value Value
   */
  public hasValue(value): boolean {
    return !isEmpty(value);
  }

  /**
   * Method to check if first arrangement input has value
   */
  public firstArrangementInputHasValue(): boolean {
    const firstArrangement = this.arrangementsFormArray
      .controls[0] as UntypedFormGroup;

    return (
      !isEmpty(firstArrangement) &&
      !isEmpty(firstArrangement.controls.arrangements.value)
    );
  }

  /**
   * Function to get form controls
   */
  public getForm(): Array<any> {
    return this.arrangementsFormArray.controls;
  }

  /**
   * Method to get data for Arrangement input
   */
  public getArrangementData(): TypeaheadValue<any>[] {
    return this.sharedService.filterTypeAheadArrayForNoPillsInputTypeAheadBasedOnTitle(
      this.arrangementsFormArray,
      'arrangements',
      this.arrangementData
    );
  }

  /**
   * Function witch triggers after accordion toggled
   * @param isOpen Param
   */
  public afterAccordionToggle(isOpen: boolean): void {
    this.canToggle = !isOpen;
  }

  /**
   * Function to call modal on close or cancel
   */
  public onAccordionClose(): void {
    this.cancelConfirmModalComponent.toggle();
  }

  /**
   * Function to close accordion on cancel
   * @param event Event
   */
  public cancelModalDecision(event: any): void {
    if (event) {
      this.closeAccordion();
    }
  }

  /**
   * Function to close accordion
   */
  public closeAccordion(): void {
    this.canToggle = true;
    this.addNewSectionForm.reset();
    // Need to timeout to allow change detection to work
    setTimeout(() => {
      this.toggleableAccordion.click();
    }, 1);
  }

  /**
   * Function to calculate end time
   */
  public durationChange(startTime: string): void {
    let minutesToAdd;
    const timeFormat = /^(0\d|1\d|2[0-3]):[0-5]\d$/;
    const duration = this.addNewSectionForm.controls.sectionDuration.value;
    if (timeFormat.test(duration)) {
      const hoursToMinutes = moment.duration(duration).asMinutes();
      minutesToAdd = moment(startTime, 'HH:mm')
        .add(hoursToMinutes, 'minutes')
        .format('HH:mm');
    }
    return minutesToAdd;
  }

  /**
   * Function to add new section
   */
  public addNewSection(): void {
    const sectionTimeAndPositionData = this.getNewSectionTimeAndPositionData();

    const randomSectionId = this.sharedService.generateId();

    // New section data model
    this.customSectionData = {
      ...this.customSectionData,
      departments: this.sharedService.getMultiselectValues(
        this.departmentMultiselectLookup,
        this.addNewSectionForm,
        'department',
        'departmentId'
      ),
      partiesToAskQuestions: this.addNewSectionForm.controls.parties.value || [],
      bsSectionStartTime: this.addNewSectionForm.controls.sectionDuration.value
        ? this.sharedService.convertDate(sectionTimeAndPositionData.startDate)
        : '',
      bsSectionEndTime: this.addNewSectionForm.controls.sectionDuration.value
        ? this.sharedService.convertDate(sectionTimeAndPositionData.endDate)
        : '',
      businessDaySectionPositionNo: sectionTimeAndPositionData.position,
      businessDaySectionId: randomSectionId,
      businessDaySectionDuration: this.addNewSectionForm.controls
        .sectionDuration.value
        ? this.addNewSectionForm.controls.sectionDuration.value
        : null,
      businessDaySectionNote:
        this.addNewSectionForm.controls.sectionDetailsFreeTextBox.value,
      businessDaySectionPositionTemplateId:
        this.addNewSectionForm.controls.newSectionType.value,
      businessDaySectionTemplateId:
        this.addNewSectionForm.controls.newSectionType.value,
      businessDaySectionTitle:
        this.addNewSectionForm.controls.newSectionTitle.value,
      businessDaySectionTitleGle:
        this.addNewSectionForm.controls.newSectionTitleGa.value,
      businessScheduleArrangementProposal:
        this.addNewSectionForm.controls.arrangementsProposedTitle.value,
      isSectionArrangementUsed:
        this.addNewSectionForm.controls.sectionArrangements.value,
      isSectionNoteUsed: this.addNewSectionForm.controls.sectionDetails.value,
      isStartTimeFixed: this.addNewSectionForm.controls.isStartTimeFixed.value,
      businessScheduleDayId: 0,
      businessDaySectionTypeId:
        this.addNewSectionForm.controls.newSectionType.value,
      isSectionNextWeekBusinessUsed: this.addNewSectionForm.controls
        .nextWeeksBusiness.value
        ? true
        : false,
      sectionDetailNextWeekBusiness:
        this.addNewSectionForm.controls.nextWeeksBusinessFreeTextBox.value,
      arrangementOfSections: this.collectArrangementData(),
      isVisibleOnDailBusiness: ! this.addNewSectionForm.controls.isHiddenOnDailBusiness.value,
    };

    // Function to save new header
    this.reportStoreService.setNewSectionHeader(
      this.businessScheduleDay.bsDayDate,
      this.newSectionHeaders(
        sectionTimeAndPositionData.position,
        randomSectionId,
        this.addNewSectionForm.controls.sectionDuration.value
          ? sectionTimeAndPositionData.startDate
          : null,
        this.addNewSectionForm.controls.sectionDuration.value
          ? sectionTimeAndPositionData.endDate
          : null
      )
    );

    // Function to store section data
    this.reportStoreService.storeSectionData(
      sectionTimeAndPositionData.position,
      this.sharedService.cleanObjectNullValue(this.customSectionData),
      this.addNewSectionForm.controls.newSectionType.value,
      this.businessScheduleDay.bsDayDate
    );
  }

  /**
   * Method to handle adding new work item
   * @param data
   */
  public onAddWorkItem(data: any): void {
    if (!this.customSectionData) {
      this.customSectionData = {};
    }
    if (!this.customSectionData.workItems) {
      this.customSectionData.workItems = [];
    }

    const workItem = this.getWorkItemValueFromWorkItemForm(data);
    this.customSectionData.workItems.push(workItem);
  }

  /**
   * Method to handle updating work item
   * @param data
   */
  public onUpdateWorkItem(data: any): void {
    const workItem = this.getWorkItemValueFromWorkItemForm(data.formValue);
    if (+data.index > -1) {
      this.customSectionData.workItems[data.index] = workItem;
    }
  }

  public onArrangementChange(id: number, value: TypeaheadValue<any>) {
    if (value) {
      this.keyUp.next({ id, value: value.title });
    }
  }

  public onNoArrangementResults(id: number, value: string): void {
    const arrangementFormGroup = this.arrangementsFormArray.controls[
      id
    ] as UntypedFormGroup;
    const arrangementFormControl = arrangementFormGroup.get('arrangement');
    if (arrangementFormControl) {
      arrangementFormControl.setValue(value);
    }
  }

  /**
   * Max character in arrangments
   */
  public maxMinArrangementChar(event): any {
    const length =  event.target.value !== undefined
      ? event.target.value.length
      : event.target.innerText.length;
    if (
      length !== 0 &&
      length >= 5000 &&
      event.keyCode !== 46 &&
      event.keyCode !== 8 &&
      event.keyCode !== 37 &&
      event.keyCode !== 39
    ) {
      event.preventDefault();
    }
    this.minimumCharacter = length < 5;
  }

  /**
   * Function to handle work item remove
   * @param index Index
   */
  public onRemoveWorkItem(index: number): void {
    if (+index > -1) {
      this.customSectionData.workItems.splice(index, 1);
    }
  }

  /**
   * Method to extract work item values from work item form value
   * @param data
   */
  private getWorkItemValueFromWorkItemForm(data: any): WorkItem {
    const isWorkItemOutstanding =
      data.form.workItemBillOrMotion[0].value === GLOBALS.workItemOtherValue;
    let billSponsors = [];
    const isWorkItemTypeBillWithSponsors = data.form.billSponsors &&
      data.form.workItemType === WorkItemTypes.bills;

    if (isWorkItemTypeBillWithSponsors) {
      billSponsors = this.addNewSectionForm.controls.newSectionType.value ===
        FormTemplate.governmentBusiness
        ? data.form.billSponsors
        : data.form.billSponsors.split(', ').map(x => +x);
    }

    const index = findIndex(data.workItemDataWithTags, [
      'value',
      data.form.workItemBillOrMotion[0].value
    ]);
    return {
      workItemId: isWorkItemOutstanding
        ? null
        : data.form.workItemBillOrMotion[0].value,
      workItemTitles:
        index !== -1 ? [data.workItemDataWithTags[index].title] : [],
      businessDaySectionWorkItemPlaceholder: isWorkItemOutstanding
        ? data.form.businessDaySectionWorkItemPlaceholder
        : null,
      isWorkItemOutstanding,
      motionSponsors:
        data.form.motionSponsors &&
        data.form.workItemType === WorkItemTypes.motions &&
        data.form.motionSponsors
          ? JSON.parse(data.form.motionSponsors)
          : [],
      billSponsors,
      billStagesToConsider:
        data.form.workItemType === WorkItemTypes.bills && data.form.billStages
          ? data.form.billStages
          : null,
      workItemTypeInfo:
        this.sharedService.getWorkItemTypeInfoValueFromWorkItemForm(
          data.form.workItemType
        ),
      arrangementOfWorkItems:
        this.sharedService.getArrangementDataFromWorkItemForm(data.form),
      arrangementOfWorkItemProposal: data.form.proposalTitle || null,
      businessDaySectionWorkItemPositionNo: 0,
      ...(this.isDeferredDivisions
          && data.form.amendmentType
          && {amendmentType: data.form.amendmentType})
    };
  }

  /**
   * Function to get date and position for new section
   */
  private getNewSectionTimeAndPositionData(): any {
    const newSectionData = {
      lastIndex: null,
      lastElement: null,
      startDate: null,
      endDate: null,
      position: null
    };

    // Checking if Day have sections
    if (!isEmpty(this.businessScheduleDay.businessDaySectionHeaders)) {
      // If day Have sections geting last index with end time
      newSectionData.lastIndex = findLastIndex(
        this.businessScheduleDay.businessDaySectionHeaders,
        (item: any) => {
          return item.bsSectionEndTime !== null;
        }
      );

      // Geting last element of sections to get position of this element
      newSectionData.lastElement = last(
        this.businessScheduleDay.businessDaySectionHeaders
      );

      // Serching for position whitch is not null
      const positionIndex = findLastIndex(
        this.businessScheduleDay.businessDaySectionHeaders,
        (item: any) => {
          return item.businessDaySectionPositionNo !== null;
        }
      );

      // Assigning position for new section
      newSectionData.position =
        positionIndex > -1
          ? this.businessScheduleDay.businessDaySectionHeaders[positionIndex]
              .businessDaySectionPositionNo + 1
          : 1;

      if (newSectionData.lastIndex > -1) {
        // Generating start date from business day and previous section end time whitch will be new section start time
        newSectionData.startDate =
          moment
            .utc(
              this.sharedService.convertDate(this.businessScheduleDay.bsDayDate)
            )
            .format(GLOBALS.dateFormat) +
          ' ' +
          this.businessScheduleDay.businessDaySectionHeaders[
            newSectionData.lastIndex
          ].bsSectionEndTime;

        // On duration change and plus business day date creating end time of new section
        newSectionData.endDate =
          moment
            .utc(
              this.sharedService.convertDate(this.businessScheduleDay.bsDayDate)
            )
            .format(GLOBALS.dateFormat) +
          ' ' +
          this.durationChange(
            this.businessScheduleDay.businessDaySectionHeaders[
              newSectionData.lastIndex
            ].bsSectionEndTime
          );
      } else {
        // Generating start date from business day date and day start time
        newSectionData.startDate =
          moment
            .utc(
              this.sharedService.convertDate(this.businessScheduleDay.bsDayDate)
            )
            .format(GLOBALS.dateFormat) +
          ' ' +
          this.sharedService.getTimeFromDate(
            this.businessScheduleDay.bsDayStartTime
          );

        // Generating end date from business day date and duration
        newSectionData.endDate =
          moment
            .utc(
              this.sharedService.convertDate(this.businessScheduleDay.bsDayDate)
            )
            .format(GLOBALS.dateFormat) +
          ' ' +
          this.durationChange(
            this.sharedService.getTimeFromDate(
              this.businessScheduleDay.bsDayStartTime
            )
          );
      }
    } else {
      // If no section are in business day then position will be 1
      newSectionData.position = 1;

      // Generating start date from business day date and day start time
      newSectionData.startDate =
        moment
          .utc(
            this.sharedService.convertDate(this.businessScheduleDay.bsDayDate)
          )
          .format(GLOBALS.dateFormat) +
        ' ' +
        this.sharedService.getTimeFromDate(
          this.businessScheduleDay.bsDayStartTime
        );

      // Generating end date from business day date and duration
      newSectionData.endDate =
        moment
          .utc(
            this.sharedService.convertDate(this.businessScheduleDay.bsDayDate)
          )
          .format(GLOBALS.dateFormat) +
        ' ' +
        this.durationChange(
          this.sharedService.getTimeFromDate(
            this.businessScheduleDay.bsDayStartTime
          )
        );
    }

    return newSectionData;
  }

  /**
   * Function to return new Section object
   * @param id Random section id
   * @param startTime Start Time
   * @param endTime End Time
   */
  private newSectionHeaders(
    position: number,
    id: string,
    startTime: string,
    endTime: string
  ): object {
    return {
      bdSectionId: id,
      bsSectionStartTime: startTime,
      bsSectionEndTime: endTime,
      bsDaySectionTitle: this.addNewSectionForm.controls.newSectionTitle.value,
      businessDaySectionType: {
        bdSectionTypeId: this.addNewSectionForm.controls.newSectionType.value,
        bdSectionTypeName: this.addNewSectionForm.controls.newSectionTitle.value
      },
      businessDaySectionTypeId:
        this.addNewSectionForm.controls.newSectionType.value,
      businessDaySectionPositionNo: position,
      isDataValid: true,
      isNewSection: true
    };
  }

  /**
   * Function to collect arrangements
   */
  private collectArrangementData(): Array<any> {
    const arrangementOfSections = [];
    this.arrangementsFormArray.controls.forEach(control => {
      if (control.get('arrangements').value.length > 0) {
        const obj = {
          arrangementOfSectionId: 0,
          arrangementOfSectionDescription: control.get('arrangements').value,
          isDissentingMembersAssigned: control.get('dissentMembersBox').value,
          isArrangementAppearOnFront: true,
          dissentingMembers: control.get('dissentMembers').value.map(item => {
            return {
              id: item.value,
              name: item.title
            };
          })
        };
        arrangementOfSections.push(obj);
      }
    });
    return arrangementOfSections;
  }

  /**
   * Function to show section details on toggle
   */
  private onDetailsToggleChange(): void {
    this.subscription.add(
      this.addNewSectionForm.controls.sectionDetails.valueChanges.subscribe(
        val => {
          if (val) {
            this.addNewSectionForm.controls.sectionDetailsFreeTextBox.enable();
          } else {
            this.addNewSectionForm.controls.sectionDetailsFreeTextBox.disable();
          }
          this.addNewSectionForm.updateValueAndValidity();
        }
      )
    );
  }

  /**
   * Function to show next weeks business details on toggle
   */
  private onNextWeeksBusinessChange(): void {
    this.subscription.add(
      this.addNewSectionForm.controls.nextWeeksBusiness.valueChanges.subscribe(
        val => {
          if (val) {
            this.addNewSectionForm.controls.nextWeeksBusinessFreeTextBox.enable();
          } else {
            this.addNewSectionForm.controls.nextWeeksBusinessFreeTextBox.disable();
          }
          this.addNewSectionForm.updateValueAndValidity();
        }
      )
    );
  }

  /**
   * Function to select Section template
   */
  private selectSectionTemplate(): void {
    this.subscription.add(
      this.addNewSectionForm.controls.newSectionType.valueChanges
        .pipe(debounceTime(100))
        .subscribe(val => {
          // Clear custom section data on work item change
          this.customSectionData = !isEmpty(this.customSectionData)
            ? {}
            : this.customSectionData;

          // Disable field before create new template
          this.disableFields(this.addNewSectionForm, [
            'parties',
            'department',
            'sectionDetails',
            'sectionArrangements',
            'nextWeeksBusiness'
          ]);

          // Patch title value
          this.addNewSectionForm.patchValue(
            {
              newSectionTitle: this.populateNewSectionTitle(val)
            },
            { emitEvent: false }
          );

          // Call function to show hide fields
          this.sharedService.showOrHideFormFieldsBasedOnTemplate(
            this.addNewSectionForm,
            val
          );

          this.showWorkItems = false;

          // Re-generate work item types on section type change
          if (this.checkWorkItems()) {
            setTimeout(() => {
              this.showWorkItems = true;
            }, 50);
            setTimeout(() => {
              this.workItemsForm.generateWorkItemsTypeData();
              this.workItemsForm.onAddNewSectionTemplateChange();
            }, 100);
          }
          if (val === FormTemplate.motions) {
            this.addNewSectionForm.controls.sectionArrangements.patchValue(
              true
            );
          } else {
            this.addNewSectionForm.controls.sectionArrangements.patchValue(
              false
            );
          }
        })
    );
  }

  /**
   * Function to get Section title
   * @param selectedValue Selected value
   */
  private populateNewSectionTitle(selectedValue: any): string {
    const index = findIndex(this.sectionTypesData, [
      'bdSectionTypeId',
      selectedValue
    ]);
    return selectedValue === FormTemplate.custom || index === -1
      ? ''
      : this.sectionTypesData[index].bdSectionTypeName;
  }

  /**
   * Function to disable nessesery fields
   * @param form UntypedFormGroup
   * @param fieldsToDisable  Field to disable
   */
  private disableFields(form: UntypedFormGroup, fieldsToDisable: Array<string>): void {
    fieldsToDisable.forEach(control => {
      form.controls[control].disable();
    });
  }

  /**
   * Function to create arrangement form
   * If there params ar passed then patch them
   * @param arrangement Arrangements
   * @param dissentMembersBox Dissenting Memebers checkbox
   * @param dissentMembers Dissenting member list
   */
  private addArrangementFormControl(): void {
    // Creating arrangement form
    const arrangementForm: UntypedFormGroup = this.fb.group({
      arrangements: [[]],
      dissentMembersBox: false,
      dissentMembers: [[]]
    });
    this.arrangementsFormArray.push(arrangementForm);

    // Subscribing to value changes
    this.subscription.add(
      arrangementForm.controls.arrangements.valueChanges
        .pipe(filter(val => !isEmpty(val) && isArray(val) && !isEmpty(val[0])))
        .subscribe(res => {
          arrangementForm.controls.arrangements.patchValue(res[0].title);
        })
    );

    // Show hide fields
    this.sharedService.showFieldIfOtherFieldValueIs(
      this.subscription,
      arrangementForm,
      'dissentMembersBox',
      true,
      'dissentMembers'
    );
  }

  /**
   * On Arrangement Toggle trigger
   */
  private onArrangementToggle(): void {
    this.subscription.add(
      this.addNewSectionForm.controls.sectionArrangements.valueChanges.subscribe(
        res => {
          if (res) {
            this.addArrangementFormControl();
            this.addNewSectionForm.controls.arrangementsProposedTitle.enable();
            this.addNewSectionForm.patchValue(
              {
                arrangementsProposedTitle: !isEmpty(
                  this.addNewSectionForm.controls.arrangementsProposedTitle
                    .value
                )
                  ? this.addNewSectionForm.controls.arrangementsProposedTitle
                      .value
                  : this.addNewSectionForm.controls.newSectionTitle.value
              },
              { emitEvent: false }
            );
          } else {
            this.clearArrangementsForm();
            this.addNewSectionForm.controls.arrangementsProposedTitle.disable();
            this.addNewSectionForm.controls.arrangementsProposedTitle.setValue(
              null
            );
          }
        }
      )
    );
  }

  /**
   * Clear arrangement form
   */
  private clearArrangementsForm(): void {
    while (this.arrangementsFormArray.length) {
      this.arrangementsFormArray.removeAt(0);
    }
  }

  /**
   * Subscribe to form changes
   */
  private subscribeToFormValueChange(): void {
    this.keyUp
      .pipe(
        debounceTime(2000),
        distinctUntilChanged(),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(res => {
        const arrangement = this.arrangementsFormArray.controls[
          res.id
        ] as UntypedFormGroup;
        if (
          isEmpty(arrangement.controls.arrangements.value) &&
          res.value.length >= 5
        ) {
          arrangement.controls.arrangements.setValue(
            [{ value: 0, title: res.value }],
            { onlySelf: true, emitEvent: false }
          );
        }
      });
    this.subscription.add(
      this.addNewSectionForm.valueChanges.subscribe(formValue => {
        if (isArray(formValue.sectionArrangementFormGroup)) {
          // Loop through arrangementsFormArray and remove empty input form groups
          formValue.sectionArrangementFormGroup.forEach((element, i) => {
            // Skip last element as it can be empty
            if (
              i < formValue.sectionArrangementFormGroup.length - 1 &&
              isEmpty(element.arrangements)
            ) {
              this.arrangementsFormArray.removeAt(i);
            }
          });

          // If last form group has Arrangement value then add new form group
          if (
            !isEmpty(
              formValue.sectionArrangementFormGroup[
                formValue.sectionArrangementFormGroup.length - 1
              ]
            ) &&
            !isEmpty(
              formValue.sectionArrangementFormGroup[
                formValue.sectionArrangementFormGroup.length - 1
              ].arrangements
            )
          ) {
            this.addArrangementFormControl();
          }
        }
      })
    );
  }

  private checkWorkItems(): boolean {
    return (
      this.addNewSectionForm.controls.newSectionType.value ===
        FormTemplate.privateMembersBill ||
      this.addNewSectionForm.controls.newSectionType.value ===
        FormTemplate.privateMembersBusiness ||
      this.addNewSectionForm.controls.newSectionType.value ===
        FormTemplate.motions ||
      this.addNewSectionForm.controls.newSectionType.value ===
        FormTemplate.governmentBusiness ||
      this.addNewSectionForm.controls.newSectionType.value ===
        FormTemplate.billsForIntroduction ||
      this.addNewSectionForm.controls.newSectionType.value ===
        FormTemplate.referralToCommittee ||
      this.isDeferredDivisions
    );
  }

  public isFormValid(): boolean {
    const isWorkItemCheckRequired = this.showWorkItems && !this.isDeferredDivisions;
    
    if (this.addNewSectionForm.controls.sectionArrangements.value) {
      if (isWorkItemCheckRequired) {
        if (!isEmpty(this.customSectionData)) {
          return (
            !isEmpty(this.customSectionData.workItems) &&
            this.addNewSectionForm.valid &&
            this.firstArrangementInputHasValue()
          );
        }
        return false;
      }
      return (
        this.addNewSectionForm.valid && this.firstArrangementInputHasValue()
      );
    }
    if (isWorkItemCheckRequired) {
      if (!isEmpty(this.customSectionData)) {
        return (
          !isEmpty(this.customSectionData.workItems) &&
          this.addNewSectionForm.valid
        );
      }
      return false;
    }
    return this.addNewSectionForm.valid;
  }

  public onSectionTypeChange(type: number) {
    const gaTitleController = this.addNewSectionForm.get('newSectionTitleGa');

    if (type === FormTemplate.custom) {
      gaTitleController.enable({emitEvent: false});
    } else if (!gaTitleController.disabled) {
      gaTitleController.disable({emitEvent: false});
    }
  }

  validateArrangementLength(arrangement: UntypedFormControl, { editor }: ChangeEvent) {
    const value = editor.getData();

    return this.sharedService.validateArrangementLength(arrangement, value);
  }

  get isWorkItemLimitReached(): boolean {
    return (this.isBillsForIntroduction
        || this.isDeferredDivisions
        || this.isReferralToCommittee)
      && this.customSectionData
      && this.customSectionData.workItems
      && this.customSectionData.workItems.length >= (this.isDeferredDivisions ? 20 : 10);
  }

  get isBillsForIntroduction(): boolean {
    return this.addNewSectionForm.controls.newSectionType.value ===
      FormTemplate.billsForIntroduction;
  }

  get isDeferredDivisions(): boolean {
    return this.addNewSectionForm.controls.newSectionType.value ===
      FormTemplate.deferredDivisions;
  }

  get isReferralToCommittee(): boolean {
    return this.addNewSectionForm.controls.newSectionType.value ===
      FormTemplate.referralToCommittee;
  }
}
