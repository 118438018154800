// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.arrangements-top {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.arrangement-members {
  padding: 10px 0;
}
::ng-deep oir-work-item-form > div > div > oir-toggleable-accordion > div > div > div.body > div > div,
.section-work-item-type {
  display: block;
  margin: 1rem 0;
}
.referral-bills-toggle {
  margin-left: 10px;
}
`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
