import {
  Component,
  OnInit,
  Input,
  ViewChild,
  OnChanges,
  OnDestroy
} from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastNotificationsService, TypeaheadValue } from 'proceduralsystem-clientcomponents';
import { ReportService } from 'src/app/services/report.service';
import { takeUntil } from 'rxjs/operators';
import { SharedService } from 'src/app/services/shared.service';
import { OrderOfBusiness } from 'src/app/shared/models/order-of-business.model';
import { CancelConfirmModalComponent } from 'src/app/create-report/cancel-confirm-modal/cancel-confirm-modal.component';
import { CustomModalComponent } from 'src/app/shared/components/custom-modal/custom-modal.component';

@Component({
  selector: 'oir-circulate-modal',
  templateUrl: './circulate-modal.component.html',
  styleUrls: ['./circulate-modal.component.less']
})
export class CirculateModalComponent implements OnInit, OnChanges, OnDestroy {
  @Input() startObTime: string;
  @Input() endObTime: string;
  @Input() orderOfBusiness: OrderOfBusiness;
  @Input() recipientsEmails: TypeaheadValue<any>[];
  @Input() versionOb: string;
  @Input() isSingleDay: boolean;
  @Input() reportName: boolean;

  @ViewChild('circulateModal') circulateModal: CustomModalComponent;
  @ViewChild('cancelConfirmModalComponent')
  cancelConfirmModalComponent: CancelConfirmModalComponent;

  public form: UntypedFormGroup;
  public emailDefaultValue: string;
  public emailBodyDefaultValue: string;
  public orderOfBusinessInModal: OrderOfBusiness;
  public versionObInModal: string;
  public sendEmailAction = false;

  private ngUnsubscribe: Subject<void> = new Subject<void>();
  private reportData: any;
  private defaultEmail = 'journal.office@oireachtas.ie';

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private translateService: TranslateService,
    private toastService: ToastNotificationsService,
    private reportService: ReportService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      toEmail: [[], Validators.required],
      ccEmail: [],
      bccEmail: [],
      emailSubject: ['', Validators.maxLength(250)],
      emailBody: ['', Validators.maxLength(1000)]
    });
  }

  ngOnChanges() {
    this.versionObInModal = this.versionOb;
    this.emailDefaultValue = this.translateService.instant(
      'ORDER_OF_BUSINESS.CIRCULATE_EMAIL.SUBJECT.DEFAULT_VALUE',
      [this.reportName, this.versionObInModal]
    );
    this.emailBodyDefaultValue = this.isSingleDay
      ? this.translateService.instant(
          'ORDER_OF_BUSINESS.CIRCULATE_EMAIL.BODY.DEFAULT_SINGLE_VALUE',
          [this.reportName]
        )
      : this.translateService.instant(
          'ORDER_OF_BUSINESS.CIRCULATE_EMAIL.BODY.DEFAULT_VALUE',
          [this.reportName]
        );
    this.orderOfBusinessInModal = this.orderOfBusiness;
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  /**
   * Open the ciruculate modal
   */
  public open(reportData: any): void {
    this.reportData = reportData;
    this.form.patchValue({
      toEmail: [{ value: 1, title: this.defaultEmail }],
      emailSubject: this.emailDefaultValue,
      emailBody: this.emailBodyDefaultValue
    });
    this.circulateModal.toggle();
  }

  /**
   * Circulate the ciruculate modal
   */
  public circulate(): void {
    this.sendEmailAction = true;
    const emailHeader = {
      orderOfBusinessInput: this.reportData,
      commonMailInput: this.emailFormData(
        this.form.controls.toEmail.value,
        this.form.controls.ccEmail.value,
        this.form.controls.bccEmail.value,
        this.form.controls.emailSubject.value,
        this.form.controls.emailBody.value
      )
    };
    this.reportService
      .circulateOBReport(emailHeader)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.toastService.addNotification({
          title: this.translateService.instant(
            'TOAST.ORDER_OF_BUSINESS_CIRCULATED.TITLE'
          ),
          description: this.translateService.instant(
            'TOAST.ORDER_OF_BUSINESS_CIRCULATED.BODY',
            [this.reportName, this.versionObInModal]
          )
        });
        this.circulateModal.close();
        this.router.navigate(['/dashboard']);
        this.sendEmailAction = false;
      });
  }

  /**
   * Function to cancel email modal
   * @param event event
   */
  public cancelEmailModal(event) {
    if (event) {
      this.form.reset();
      this.circulateModal.close();
      let message: any;
      const title = this.translateService.instant(
        'TOAST.VIEW_ORDER_OF_BUSINESS_EMAIL.NOT_SEND'
      );
      this.toastService.addNotification({ title, description: '' });
    }
  }

  /**
   * Cancel the email modal
   */
  public cancel(): void {
    this.cancelConfirmModalComponent.toggle();
  }

  /**
   * Clear form on modal close
   */
  public onModalClose(): void {
    if (!this.sendEmailAction) {
      this.cancelConfirmModalComponent.toggle();
    }
  }

  /**
   * Email Form Data Model
   * @param receiver Email Reciver
   * @param ccReceiver Email ccReceiver
   * @param bccReceiver Email bccReceiver
   * @param subject Email subject
   * @param body Email body
   */
  private emailFormData(
    receiver: TypeaheadValue<any>[],
    ccReceiver: TypeaheadValue<any>[],
    bccReceiver: TypeaheadValue<any>[],
    subject: string,
    body: string
  ): object {
    return {
      receiver: receiver
        ? this.sharedService.convertRecipientsToString(receiver)
        : null,
      ccReceiver: ccReceiver
        ? this.sharedService.convertRecipientsToString(ccReceiver)
        : null,
      bccReceiver: bccReceiver
        ? this.sharedService.convertRecipientsToString(bccReceiver)
        : null,
      subject,
      body
    };
  }
}
