import { Component, ViewChild, EventEmitter, Output } from '@angular/core';
import { ModalComponent } from 'proceduralsystem-clientcomponents';

@Component({
  selector: 'oir-cancel-confirm-modal',
  templateUrl: './cancel-confirm-modal.component.html',
  styleUrls: ['./cancel-confirm-modal.component.less']
})
export class CancelConfirmModalComponent {

  @Output() decision: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('confirmCancelModal') confirmCancelModal: ModalComponent;

   /**
    * Method to confirm cancel modal and clear input fields
    */
  public confirm(): void {
    this.decision.emit(true);
    this.confirmCancelModal.close();
  }
  /**
   * Method to cancel modal
   */
  public cancel(): void {
    this.decision.emit(false);
    this.confirmCancelModal.close();
  }
   /**
    * Toggle the cancel modal
    */
  public toggle(): void {
    this.confirmCancelModal.toggle();
  }

}
