// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #000;
  transition: background-color 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  opacity: 0.75;
  z-index: 5;
}
#spinaroonie {
  position: absolute;
  top: calc(50% - 50px);
  left: calc(50% + 50px);
  z-index: 5;
  transform-origin: center;
  animation-name: spin;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media print {
  .globals {
    display: none !important;
  }
  .body {
    overflow: visible !important;
  }
  :host ::ng-deep .app-container > .body .content {
    padding: 0 !important;
    margin: 0 !important;
  }
}
`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
