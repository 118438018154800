// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vs-copy-btn,
.vs-print-btn,
.vs-notify-btn,
.vs-email-btn {
  display: inline-block;
  height: 40px;
  width: 40px;
  border-radius: 25px;
  border: 2px solid #5e5e5e;
  background: #fff;
  margin: 0 10px 5px 10px;
  padding: 7.5px;
  text-align: center;
}
.vs-copy-btn:hover,
.vs-print-btn:hover,
.vs-notify-btn:hover,
.vs-email-btn:hover {
  background-color: #b39841;
  border-color: #b39841;
  cursor: pointer;
}
.vs-copy-btn--ripple,
.vs-print-btn--ripple,
.vs-notify-btn--ripple,
.vs-email-btn--ripple {
  position: relative;
  overflow: hidden;
}
.vs-copy-btn img,
.vs-print-btn img,
.vs-notify-btn img,
.vs-email-btn img {
  position: relative;
  width: 20px;
  height: 20px;
}
.rapporteur-report .rapporteurNotes,
.rapporteur-report .rapporteur,
.rapporteur-report .rapporteur-announcement,
.rapporteur-report .days-arrangements,
.rapporteur-report .report-note {
  padding: 20px 0;
}
.rapporteur-report .days-arrangements .days-section {
  padding: 5px;
  border: 1px solid #d4cfbe;
}
`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
