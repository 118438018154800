import { Component, ViewChild, Input } from '@angular/core';
import {
  ModalComponent,
  ToastNotificationsService
} from 'proceduralsystem-clientcomponents';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ReportStatuses } from 'src/app/shared/report.enum';

@Component({
  selector: 'oir-cancel-report',
  templateUrl: './cancel-report.component.html',
  styleUrls: ['./cancel-report.component.less']
})
export class CancelReportComponent {
  @Input() reportStatus: number;

  @ViewChild('cancelReportModal') cancelReportModal: ModalComponent;

  constructor(
    private router: Router,
    private translateService: TranslateService,
    private toastService: ToastNotificationsService
  ) {}

  /**
   * Open cancel modal function
   */
  public openCancelDialog(): void {
    this.cancelReportModal.toggle();
  }

  /**
   * Close modal function
   */
  public cancelAction(): void {
    this.cancelReportModal.close();
  }

  /**
   * If report was canceled navigate to dashboard, close modal, show toast message
   */
  public cancelReport(): void {
    this.router.navigate(['/dashboard']);
    const title =
      this.reportStatus === ReportStatuses.Circulated
        ? this.translateService.instant(
            'TOAST.VIEW_RAPPORTEURS_REPORT_CANCEL_CIRCULATED.TITLE'
          )
        : this.translateService.instant(
            'TOAST.VIEW_RAPPORTEURS_REPORT_CANCEL_DRAFT.TITLE'
          );
    const description =
      this.reportStatus === ReportStatuses.Circulated
        ? this.translateService.instant(
            'TOAST.VIEW_RAPPORTEURS_REPORT_CANCEL_CIRCULATED.BODY'
          )
        : this.translateService.instant(
            'TOAST.VIEW_RAPPORTEURS_REPORT_CANCEL_DRAFT.BODY'
          );
    this.toastService.addNotification({ title, description });
    this.cancelReportModal.close();
  }
}
