// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vs-copy-btn,
.vs-print-btn,
.vs-notify-btn,
.vs-word-btn,
.vs-email-btn {
  display: inline-block;
  height: 40px;
  width: 40px;
  border-radius: 25px;
  border: 2px solid #5e5e5e;
  background: #fff;
  margin: 0 10px 5px 10px;
  padding: 7.5px;
  text-align: center;
}
.vs-copy-btn:hover,
.vs-print-btn:hover,
.vs-notify-btn:hover,
.vs-word-btn:hover,
.vs-email-btn:hover {
  background-color: #b39841;
  border-color: #b39841;
  cursor: pointer;
}
.vs-copy-btn--ripple,
.vs-print-btn--ripple,
.vs-notify-btn--ripple,
.vs-word-btn--ripple,
.vs-email-btn--ripple {
  position: relative;
  overflow: hidden;
}
.vs-copy-btn img,
.vs-print-btn img,
.vs-notify-btn img,
.vs-word-btn img,
.vs-email-btn img {
  position: relative;
  width: 20px;
  height: 20px;
}
.vs-word-btn {
  padding: 0;
}
.vs-word-btn img {
  position: relative;
  width: 30px;
  height: 30px;
}
.report-dates {
  background-color: #fff;
  padding: 20px;
  display: flex;
  justify-content: space-between;
}
.report-dates .date-picker {
  width: 300px;
}
.report-dates .edit-btn {
  cursor: pointer;
}
.report-dates img {
  position: relative;
}
.report-ckEditor {
  background-color: #fff;
  padding: 20px;
  margin-top: 15px;
  margin-bottom: 25px;
}
.report-ckEditor ::ng-deep .ck-editor__editable {
  padding-bottom: 10px;
}
.report-ckEditor ::ng-deep .ck-editor__editable p {
  margin-bottom: 0;
}
.hide-elm {
  display: none;
}
::ng-deep mat-dialog-container {
  box-shadow: none !important;
  background-color: transparent !important;
}
[aria-label="Rich Text Editor, editor-1"]:not(:nth-of-type(n+2)) {
  display: none;
}
`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
