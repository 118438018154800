// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.close-day-acccordion {
  float: right;
  margin-bottom: 12px;
}
.heading-item-type {
  margin-top: 12px;
}
.flex-sections {
  display: flex;
  flex-direction: column;
}
.flex-sections > .gb-items {
  order: 1;
}
.flex-sections > .mwd-items {
  order: 2;
}
.flex-sections.swap > .gb-items {
  order: 2;
}
.flex-sections.swap > .mwd-items {
  order: 1;
}
`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
