// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-toggle-accordion-wrapper {
  padding: 5px 0;
}
.custom-toggle-accordion {
  width: calc(100% - 20px);
  position: relative;
  margin-left: 20px;
  background-color: #f4f2ea;
}
.custom-toggle-accordion > .panel {
  margin: 5px 25px 0px 25px;
  border: none;
  width: auto;
  background-color: transparent;
}
.custom-toggle-accordion > .panel > .align-items {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.custom-toggle-accordion > .panel > .align-items > .header {
  height: 50px;
  line-height: 50px;
  vertical-align: middle;
  background-color: #f4f2ea;
}
.custom-toggle-accordion > .panel > .align-items > .header > .title {
  display: inline-block;
  margin-left: 20px;
  margin-top: 10px;
  vertical-align: middle;
  font-weight: bold;
}
.custom-toggle-accordion > .panel > .align-items > .header > .open-left {
  display: inline-block;
  vertical-align: middle;
  height: 24px;
  width: 24px;
  background-size: 24px;
}
.custom-toggle-accordion .section {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.custom-toggle-accordion .section .section-front {
  margin-top: -2px;
}
.custom-toggle-accordion .section .section-front img {
  position: relative;
  margin-top: 8px;
  vertical-align: -8px;
  margin-right: 10px;
}
.custom-toggle-accordion .section .section-front .section-title {
  font-weight: bold;
}
.custom-toggle-accordion .section .section-back {
  margin-top: -11px;
}
.custom-toggle-accordion .accordion-button {
  line-height: 50px;
  min-width: 102px;
}
.custom-toggle-accordion .align-items .header button {
  background-color: transparent;
  outline: none;
  border: none;
  transform: translate(0, 0);
}
.custom-toggle-accordion .align-items .header button:hover,
.custom-toggle-accordion .align-items .header button:active,
.custom-toggle-accordion .align-items .header button:focus {
  outline: 1px solid;
  outline: auto -webkit-focus-ring-color;
  outline-color: #b39841;
}
.custom-toggle-accordion .panel > .align-items > .header > .title {
  width: 95%;
}
.custom-toggle-accordion .panel > .align-items > .header > .buttons {
  width: 10%;
}
@media only screen and (max-width: 1450px) {
  .custom-toggle-accordion .panel > .align-items > .header > .title {
    width: 90%;
  }
}
`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
