import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'oir-accordion-header',
  templateUrl: './accordion-header.component.html',
  styleUrls: ['./accordion-header.component.less']
})
export class AccordionHeaderComponent implements OnInit {

  @Input() showButton = false;
  @Input() buttonTitle = '';
  @Input() iconName = 'filled-circle-x';
  @Input() showTime = false;
  @Input() time = '';
  @Input() title = '';
  @Input() disableAccordionHeader: boolean;

  @Output() onButtonClick: EventEmitter<boolean> = new EventEmitter();

  public imageUrl = '';

  public ngOnInit(): void {
    this.imageUrl = `url(./assets/${this.iconName}.svg)`;
  }

  /**
   * Method to emit event when button is clicked
   */
  public click(): void {
    this.onButtonClick.emit(true);
  }

}
