import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { OirHttpService, ToastNotificationsService } from 'proceduralsystem-clientcomponents';
import { FileExtensionType, SectionsApi } from '../shared/report.enum';
import { isEmpty } from 'lodash-es';
import { SharedService } from './shared.service';
import { AvailableSectionDate, Party, Report, TitleTranslationPayload, TitleTranslationWithdrawPayload } from '../shared/models/report.model';
import { OrderOfBusiness } from 'src/app/shared/models/order-of-business.model';
import { RapporteurReport } from '../shared/models/rapporteur-report.model';
import { catchError } from 'rxjs/operators';
import { GLOBALS } from '../shared/globals';
import { TranslateService } from '@ngx-translate/core';
import { OirHTTPErrorResponse } from 'proceduralsystem-clientcomponents/services/error/error.handler';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  private isDraft = new BehaviorSubject<any>([]);
  private reportData = new Subject<any>();
  private sittingToggle = new Subject<any>();
  private daysChanged = new Subject<any>();
  private isDateEditMode = new Subject<any>();
  private partyChanged = new BehaviorSubject<Party[]>([]);
  private departmentsChanged = new BehaviorSubject<any>([]);
  private sectionArrangementChanged = new BehaviorSubject<any>([]);
  private daysArrangementChanged = new BehaviorSubject<any>([]);
  private workItemsArrangementChanged = new BehaviorSubject<any>([]);
  private dissentingMembers = new BehaviorSubject<any>([]);
  private sectionTypesData = new BehaviorSubject<any>([]);
  private titleTranslationRequest = new Subject<any>();
  public savedAsDraft = this.isDraft.asObservable();
  public dateChanged = this.isDateEditMode.asObservable();
  public members = this.dissentingMembers.asObservable();
  public sectionArrangement = this.sectionArrangementChanged.asObservable();
  public dayArrangement = this.daysArrangementChanged.asObservable();
  public workItemArrangement = this.workItemsArrangementChanged.asObservable();
  public party = this.partyChanged.asObservable();
  public departments = this.departmentsChanged.asObservable();
  public report = this.reportData.asObservable();
  public toggled = this.sittingToggle.asObservable();
  public dayRangeChange = this.daysChanged.asObservable();
  public sectionTypes = this.sectionTypesData.asObservable();
  public titleTranslationRequested = this.titleTranslationRequest.asObservable();

  constructor(
    private oirHttp: OirHttpService,
    private sharedService: SharedService,
    private translateService: TranslateService,
    private toastService: ToastNotificationsService
  ) {}

  /**
   * Function to trigger which tab to open on save as draft
   * @param isDraftSaved Boolean
   * @param index Index of tab to open
   */
  public onSaveAsDraft(isDraftSaved: boolean, index: number): void {
    const data = {
      isDraftSaved,
      index
    };
    this.isDraft.next(data);
    setTimeout(() => {
      this.isDraft.next(null);
    }, 100);
  }
  /**
   * Send api call to save draft report
   * @param data
   */
  public createReportDraft(data): Observable<any> {
    return this.oirHttp.post<any>({
        path: '/bcr',
        body: data
      }).pipe(
     catchError(error => this.processError(error))
    );
  }
  public isEditMode(data: any): void {
    this.isDateEditMode.next(data);
  }

  public getSectionTypesData(data: any): void {
    this.sectionTypesData.next(data);
  }

  public sectionArrangementData(data: any): void {
    this.sectionArrangementChanged.next(data);
  }

  public dayArrangementData(data: any): void {
    this.daysArrangementChanged.next(data);
  }

  public workItemArrangementData(data: any): void {
    this.workItemsArrangementChanged.next(data);
  }

  public membersData(data: any): void {
    this.dissentingMembers.next(data);
  }

  public partiesData(data: Party[]): void {
    this.partyChanged.next(data);
  }

  public departmentsData(data: any): void {
    this.departmentsChanged.next(data);
  }

  public passData(data: any): void {
    this.reportData.next(data);
  }

  public toggleChanged(data: any): void {
    this.sittingToggle.next(data);
  }

  public dateChangeCheck(data: any): void {
    this.daysChanged.next(data);
  }

  public getReport(id: string): Observable<any> {
    return this.oirHttp.get<any>({
        path: `/bcr/${id}`
      }).pipe(
      catchError(error => this.processError(error))
    );
  }

  public getDayOfTheWeek(): Observable<any> {
    return this.oirHttp
      .get<any>({
        path: '/referencedata/customdaytemplates'
      }).pipe(
        catchError(error => this.processError(error))
      );
  }

  public postReport(id: string, model: any): Observable<any> {
    return this.oirHttp
      .post<any>({
        path: `/bcr/${id}`,
        body: model
      }).pipe(
        catchError(error => this.processError(error))
      );
  }
  public circulatedDraft(reportId: string, model: any): Observable<any> {
    return this.oirHttp
      .post<any>({
        path: `/bcr/circulatedDraft/${reportId}`,
        body: model
      }).pipe(
        catchError(error => this.processError(error))
      );
  }
  public updateReport(id: string, model: any): Observable<any> {
    return this.oirHttp
      .post<any>({
        path: `/bcr/update/${id}`,
        body: model
      }).pipe(
        catchError(error => this.processError(error))
      );
  }
  public updateMinorReport(id: string, model: any): Observable<any> {
    return this.oirHttp
      .post<any>({
        path: `/bcr/updateMinor/${id}`,
        body: model
      }).pipe(
        catchError(error => this.processError(error))
      );
  }

  public getParties(): Observable<Party[]> {
    return this.oirHttp.get<Party[]>({
      path: '/referencedata/parties'
    });
  }

  public getDepartments(): Observable<any> {
    return this.oirHttp.get<any>({
      path: '/referencedata/departments'
    });
  }

  public getArrangements(arrangementType: number): Observable<any> {
    return this.oirHttp.get<any>({
      path: `/referencedata/arrangements?arrangmentsGroup=${arrangementType}`
    });
  }

  public getMembers(): Observable<any> {
    return this.oirHttp.get<any>({
      path: '/referencedata/members'
    });
  }

  public getSectionsList(id: number, date: string): Observable<any> {
    return this.oirHttp.get<any>({
      path: `/referencedata/customdaystemplateDetails?dayOfWeek=${id}&refDate=${date}`
    });
  }
  public getSectionData(id: number | string, type: number): Observable<any> {
    if (!isEmpty(SectionsApi[type])) {
      return this.oirHttp.get<any>({
        path: `/section/${SectionsApi[type]}/${id}`
      });
    } else {
      // To avoid erros, will be removed after all API will be defined in enum
      return this.oirHttp.get<any>({
        path: `/section/${id}`
      });
    }
  }
  public getSectionTypes(): Observable<any> {
    return this.oirHttp.get<any>({
      path: '/referencedata/sectiontypes'
    });
  }
  public getMailsList(): Observable<any> {
    return this.oirHttp.get<any>({
      path: '/referencedata/recipients'
    });
  }

  /**
   * Get stages to be considered for Work item type "bills"
   */
  public getStagesToConsider(specificStage = ''): Observable<any> {
    return this.oirHttp.get<any>({
      path: `/referencedata/stagestoconsider${specificStage}`
    });
  }

  /**
   * Function to get day template for custom day
   * @param bsDayDate Date
   * @param templateId Template id
   */
  public getDefaultBusinessDayTemplate(
    bsDayDate: string,
    templateId: number
  ): Observable<any> {
    return this.getSectionsList(
      templateId,
      this.sharedService.convertDate(bsDayDate)
    );
  }

  /**
   * Gets work items array for select options in work-items.component.ts
   * @param type 'bills', 'motions' or 'statements'
   */
  public getWorkItems(type: string, billsEndpoint?: string): Observable<any> {
    return this.oirHttp.get<any>({
      path: `/referencedata/${billsEndpoint ? billsEndpoint : type}`
    });
  }

  /**
   * Get PDF preview of report
   * @param reportId - report id
   * @param fileExtensionType - generated file tpe
   */
  public generatePrintPreview(reportId: number, fileExtensionType: FileExtensionType = FileExtensionType.BcrPdf): Observable<any> {
    return this.oirHttp.get({
      path: `/api/DocumentGenerator/generateBCR/${reportId}`,
      responseType: fileExtensionType === FileExtensionType.BcrPdf ? 'arraybuffer' as 'json' :  'blob',
      observe: fileExtensionType === FileExtensionType.BcrPdf ? 'body' : 'response',
      query: {
        fileExtensionType
      }
    });
  }

  /**
   * Create emailHeaders for API and post them to endpoint
   */
  public actionBarSendEmail(
    to: string,
    cc: string,
    bcc: string,
    subject: string,
    body: string,
    report: Report
  ): Observable<any> {
    const url = `/bcr/send/${report.reportId}`;
    const emailHeadersToSend = {
      receiver: to,
      ccReceiver: cc,
      bccReceiver: bcc,
      subject,
      body
    };
    return this.oirHttp
      .post<any>({
        path: url,
        body: emailHeadersToSend
      }).pipe(
        catchError(error => this.processError(error))
      );
  }

  /**
   * Method to send Email via post request in order of business
   */
  public actionBarSendEmailOb(obId: number, emailHeader: any) {
    const url = `/api/OB/send/${obId}`;
    return this.oirHttp
      .post<any>({
        path: url,
        body: emailHeader
      }).pipe(
        catchError(error => this.processError(error))
      );
  }

  /**
   * Create emailHeaders for API and post them to endpoint
   */
  public circulateReport(
    to: string,
    cc: string,
    bcc: string,
    subject: string,
    body: string,
    report: Report,
    id: any
  ): Observable<any> {
    const url = `/circulateBcr/${id}`;
    const emailHeadersToSend = {
      bcrUpdateDto: report,
      mailInputDto: {
        receiver: to,
        ccReceiver: cc,
        bccReceiver: bcc,
        subject,
        body
      }
    };

    return this.oirHttp
      .post<any>({
        path: url,
        body: emailHeadersToSend
      }).pipe(
        catchError(error => this.processError(error))
      );
  }

  /**
   * Create emailHeaders for API and post them to endpoint
   */
  public reviseCirculateReport(
    to: string,
    cc: string,
    bcc: string,
    subject: string,
    body: string,
    report: Report,
    id: any
  ): Observable<any> {
    const url = `/bcr/revise/${id}`;
    const emailHeadersToSend = {
      bcrUpdateDto: report,
      mailInputDto: {
        receiver: to,
        ccReceiver: cc,
        bccReceiver: bcc,
        subject,
        body
      }
    };

    return this.oirHttp
      .post<any>({
        path: url,
        body: emailHeadersToSend
      }).pipe(
        catchError(error => this.processError(error))
      );
  }

  /**
   * Function to call API and preview Rapporteur Report
   * @param bcrId BCR report id
   */
  public previewRapporteurReport(
    bcrId: number | string
  ): Observable<RapporteurReport> {
    const url = `/api/RapporteurReport/preview/${bcrId}`;
    return this.oirHttp.get<any>({
      path: url
    });
  }

  /**
   * Function to call API and get exsisting Rapporteur Report
   * @param rrId Rapporteur Report id
   */
  public getRapporteurReport(
    rrId: number | string
  ): Observable<RapporteurReport> {
    const url = `/api/RapporteurReport/${rrId}`;
    return this.oirHttp.get<any>({
      path: url
    });
  }

  /**
   * Function to call API and get Recipients
   */
  public getRecipients(): Observable<any> {
    const url = `/referencedata/recipients`;
    return this.oirHttp.get<any>({
      path: url
    });
  }

  /**
   * Function to post Rapporteur Report
   * @param reportModel Rapporteur Report Data Model
   */
  public postRapporteurReport(reportModel: any): Observable<any> {
    const url = `/api/RapporteurReport/create`;
    return this.oirHttp
      .post<any>({
        path: url,
        body: reportModel
      }).pipe(
        catchError(error => this.processError(error))
      );
  }

  /**
   * Function to send email to Rapporteur
   * @param rrId Rapporteur Id
   * @param emailHeader Email Headers
   */
  public sendRapporteurReportEmail(
    rrId: string,
    emailHeader: any
  ): Observable<any> {
    const url = `/api/RapporteurReport/send/${rrId}`;
    return this.oirHttp
      .post<any>({
        path: url,
        body: emailHeader
      }).pipe(
        catchError(error => this.processError(error))
      );
  }

  /**
   * Get PDF preview of Rapporteur Report
   * @param reportId Report Id
   */
  public generateRapporteurReportPrintPreview(model: any): Observable<any> {
    return this.oirHttp
      .post({
        path: `/api/DocumentGenerator/generateRR`,
        body: model,
        responseType: 'arraybuffer' as 'json'
      }).pipe(
        catchError(error => this.processError(error))
      );
  }

  /**
   * Get PDF preview of Order of business
   * @param reportId Report Id
   */
  public generateOrderOfBusinessPrintPreview(
    reportId: number | string
  ): Observable<any> {
    return this.oirHttp.get({
      path: `/api/DocumentGenerator/generateOB/${reportId}`,
      responseType: 'arraybuffer' as 'json'
    });
  }

  /**
   * Function to send report to Rapporteur
   * @param emailHeader Email Headers
   */
  public sendToRapporteur(emailHeader: any): Observable<any> {
    const url = `/api/RapporteurReport/sendtorapporteur`;
    return this.oirHttp
      .post<any>({
        path: url,
        body: emailHeader
      }).pipe(
        catchError(error => this.processError(error))
      );
  }

  /**
   * Function to search Reports
   * @param model Search model
   */
  public searchReport(model: any): Observable<any> {
    const url = `/Search`;
    return this.oirHttp
      .post<any>({
        path: url,
        body: model
      }).pipe(
        catchError(error => this.processError(error))
      );
  }

  /**
   * Report titles reference data
   */
  public getReportTitles(): Observable<any> {
    const url = `/referencedata/reporttitles`;
    return this.oirHttp.get<any>({
      path: url
    });
  }

  /**
   * Method to get Order of business report
   * @param obId id of order of busienss
   */
  public getOrderOfBusinessReport(
    obId: number | string
  ): Observable<OrderOfBusiness> {
    const url = `/api/OB/${obId}`;
    return this.oirHttp.get<any>({
      path: url
    });
  }

  /**
   * Function to post Order of Business
   * @param orderOfBusinessModel Order of Business Data Model
   */
  public postOrderOfBusinessReport(
    reportModel: any
  ): Observable<OrderOfBusiness> {
    const url = `/api/OB/create`;
    return this.oirHttp
      .post<any>({
        path: url,
        body: reportModel
      }).pipe(
        catchError(error => this.processError(error))
      );
  }

  /**
   * Function to call API and preview Order of Business
   * @param bcrId BCR report id
   */
  public previewOrderOfBusiness(
    bcrId: number | string
  ): Observable<OrderOfBusiness> {
    const url = `/api/OB/preview/${bcrId}`;
    return this.oirHttp.get<OrderOfBusiness>({
      path: url
    });
  }

  /**
   * Save Ciruclate Ob as Draft
   */
  public saveCirculatedOBAsDraft(reportModel: any): Observable<any> {
    const url = `/saveCirculatedOBAsDraft`;
    return this.oirHttp
      .post<any>({
        path: url,
        body: reportModel
      }).pipe(
        catchError(error => this.processError(error))
      );
  }

  /**
   * Function to send report to Rapporteur
   * @param emailHeader Email Headers
   */
  public circulateOBReport(emailHeader: any): Observable<RapporteurReport> {
    const url = `/api/OB/circulateOb`;
    return this.oirHttp
      .post<RapporteurReport>({
        path: url,
        body: emailHeader
      }).pipe(
        catchError(error => this.processError(error))
      );
  }

  /**
   * Get Available Section Dates for move or duplicate
   */
  public getAvailableSectionDates(): Observable<AvailableSectionDate[]> {
    const path = `/bcr/getavailabledates`;
    return this.oirHttp
      .get<AvailableSectionDate[]>({ path }).pipe(
        catchError(error => this.processError(error))
      );
  }

  /**
   * Function to send business section duplication to specific date
   * @param sectionToBeCopiedId Section ID
   * @param destinationReportId Report ID
   * @param destinationDayId Day ID
   */
  public duplicateBusinessSection(
    sectionToBeCopiedId: number,
    destinationReportId: number,
    destinationDayId: number
  ): Observable<any> {
    const path = `/bcr/duplicatesection/${sectionToBeCopiedId}/${destinationReportId}/${destinationDayId}`;

    return this.oirHttp
      .post<any>({ path }).pipe(
        catchError(error => this.processError(error))
      );
  }

  public triggerTitleTranslation(data: TitleTranslationPayload | TitleTranslationWithdrawPayload, isSaveRequiredBeforeTranslation: boolean): void {
    this.titleTranslationRequest.next({data, isSaveRequiredBeforeTranslation});
  }

  public requestTitleTranslation(body: TitleTranslationPayload): Observable<boolean> {
    const path = `/api/Translation`;
    return this.oirHttp
      .post<boolean>({ path, body }).pipe(
        catchError(error => this.processError(error))
      );
  }

  public requestTitleTranslationWithdraw(body: TitleTranslationWithdrawPayload): Observable<boolean> {
    const path = `/api/Translation`;
    return this.oirHttp
      .put<boolean>({ path, body }).pipe(
        catchError(error => this.processError(error))
      );
  }

  private processError(error: OirHTTPErrorResponse): Observable<any> {
    if (error && error.cause) {
      this.toastService.addNotification({
        title: 'ERROR_TOAST.TITLE',
        error: this.translateService.instant(
          !error.cause.Error || error.cause.Error === '101' ? GLOBALS.errorToastTranslationStart + error.cause.Error : error.cause.Error
        )
      });
    }

    return of(null);
  }
}
