import { Component, OnInit, ViewChild, Input, OnDestroy } from '@angular/core';
import {
  ToastNotificationsService,
  TypeaheadValue
} from 'proceduralsystem-clientcomponents';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { isEmpty } from 'lodash-es';
import { ReportService } from 'src/app/services/report.service';
import { RREmailType } from 'src/app/shared/report.enum';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from 'src/app/services/shared.service';
import { CustomModalComponent } from 'src/app/shared/components/custom-modal/custom-modal.component';
import { CancelConfirmModalComponent } from 'src/app/create-report/cancel-confirm-modal/cancel-confirm-modal.component';

@Component({
  selector: 'oir-send-to-rapporteur',
  templateUrl: './send-to-rapporteur.component.html',
  styleUrls: ['./send-to-rapporteur.component.less']
})
export class SendToRapporteurComponent implements OnInit, OnDestroy {
  @Input() recipientsData: TypeaheadValue<any>[];
  @Input() reportName: string;
  @Input() rrId: string;
  @Input() reportVersion: string;
  @Input() isSingleDay: boolean;

  @ViewChild('sendRapporteurReportModal')
  sendRapporteurReportModal: CustomModalComponent;
  @ViewChild('cancelConfirmModalComponent')
  cancelConfirmModalComponent: CancelConfirmModalComponent;

  public reportEmailForm: UntypedFormGroup;
  public emailType = RREmailType;
  public emailSendType: number;
  public reportData: any;
  public sendEmailAction = false;

  private ngUnsubscribe: Subject<void> = new Subject<void>();
  private defaultEmail = 'journal.office@oireachtas.ie';

  constructor(
    private fb: UntypedFormBuilder,
    private reportService: ReportService,
    private translateService: TranslateService,
    private toastService: ToastNotificationsService,
    private sharedService: SharedService,
    private router: Router
  ) {}

  /**
   * Create form on page init
   */
  public ngOnInit(): void {
    this.reportEmailForm = this.fb.group({
      toInput: [[], Validators.required],
      ccInput: [[], Validators.required],
      bccInput: [[], Validators.required],
      subject: ['', Validators.maxLength(250)],
      body: ['', Validators.maxLength(1000)]
    });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  /**
   * Function to check is form valid
   */
  public isFormValid(): boolean {
    return (
      this.reportEmailForm.controls.toInput.valid ||
      this.reportEmailForm.controls.ccInput.valid ||
      this.reportEmailForm.controls.bccInput.valid
    );
  }

  /**
   * Open report modal
   */
  public openReportModal(emailSendType: number, reportInputData: any): void {
    this.sendRapporteurReportModal.toggle();
    this.emailSendType = emailSendType;
    this.reportData = reportInputData;
    this.patchFormDefaultValues(this.reportName);

    // Only temporary for development team.
    this.recipientsData = this.sharedService.addDevEmails(this.recipientsData);
  }

  /**
   * Reset form and close report modal
   */
  public cancelSendReport(): void {
    this.cancelConfirmModalComponent.toggle();
  }

  /**
   * Function to cancel email modal
   * @param event event
   */
  public cancelEmailModal(event) {
    if (event) {
      this.reportEmailForm.reset();
      this.sendRapporteurReportModal.close();
      const title = this.translateService.instant(
        'TOAST.VIEW_RAPPORTEURS_REPORT_EMAIL.NOT_SEND'
      );
      this.toastService.addNotification({ title, description: '' });
    }
  }

  /**
   * Clear form on modal close
   */
  public onModalClose(): void {
    if (!this.sendEmailAction) {
      this.cancelConfirmModalComponent.toggle();
    }
  }

  /**
   * Send report function, after safe navigate to dashboard, shoew toast message and close modal
   */
  public sendReport(): void {
    this.sendEmailAction = true;
    // If Action bar Email modal called
    if (this.emailSendType === RREmailType.ActionBarEmail) {
      // Model to send
      const emailHeader = this.emailFormData(
        this.reportEmailForm.controls.toInput.value,
        this.reportEmailForm.controls.ccInput.value,
        this.reportEmailForm.controls.bccInput.value,
        this.reportEmailForm.controls.subject.value,
        this.reportEmailForm.controls.body.value
      );
      // API to send Email
      this.reportService
        .sendRapporteurReportEmail(this.rrId, emailHeader)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(() => {
          this.emailToastNotification(
            'TOAST.VIEW_RAPPORTEURS_REPORT_EMAIL.TITLE',
            'TOAST.VIEW_RAPPORTEURS_REPORT_EMAIL.BODY'
          );
          this.sendRapporteurReportModal.close();
          this.sendEmailAction = false;
        });
    } else {
      // Model to send
      const emailHeader = {
        rapporteurReportInput: this.reportData,
        commonMailInput: this.emailFormData(
          this.reportEmailForm.controls.toInput.value,
          this.reportEmailForm.controls.ccInput.value,
          this.reportEmailForm.controls.bccInput.value,
          this.reportEmailForm.controls.subject.value,
          this.reportEmailForm.controls.body.value
        )
      };
      // API to send Email
      this.reportService
        .sendToRapporteur(emailHeader)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(() => {
          this.emailToastNotification(
            'TOAST.VIEW_RAPPORTEURS_REPORT_SENT_TO_RAPPORTEUR.TITLE',
            'TOAST.VIEW_RAPPORTEURS_REPORT_SENT_TO_RAPPORTEUR.BODY'
          );
          this.router.navigate(['dashboard/']);
          this.sendRapporteurReportModal.close();
          this.sendEmailAction = false;
        });
    }
  }

  /**
   * Email Form Data Model
   * @param receiver Email Reciver
   * @param ccReceiver Email ccReceiver
   * @param bccReceiver Email bccReceiver
   * @param subject Email subject
   * @param body Email body
   */
  private emailFormData(
    receiver: TypeaheadValue<any>[],
    ccReceiver: TypeaheadValue<any>[],
    bccReceiver: TypeaheadValue<any>[],
    subject: string,
    body: string
  ): object {
    return {
      receiver: !isEmpty(receiver)
        ? this.sharedService.convertRecipientsToString(receiver)
        : null,
      ccReceiver: !isEmpty(ccReceiver)
        ? this.sharedService.convertRecipientsToString(ccReceiver)
        : null,
      bccReceiver: !isEmpty(bccReceiver)
        ? this.sharedService.convertRecipientsToString(bccReceiver)
        : null,
      subject,
      body
    };
  }

  /**
   * Function to show toast notificatin
   * @param titleString Notification title translation string
   * @param bodyString Notification body translation string
   */
  private emailToastNotification(
    titleString: string,
    bodyString: string
  ): void {
    let message: any;
    const title = this.translateService.instant(titleString);
    const description = this.translateService.instant(bodyString, [
      this.reportName
    ]);
    this.toastService.addNotification({ title, description });
  }

  /**
   * Function to patch default form values
   * @param reportName Report name
   */
  private patchFormDefaultValues(reportName: string): void {
    this.reportEmailForm.patchValue(
      {
        toInput: [{ value: 1, title: this.defaultEmail }],
        subject: this.translateService.instant(
          'VIEW_RAPPORTEURS_REPORT_SEND_TO_RAPPORTEUR_MODAL.SUBJECT',
          [reportName, this.reportVersion]
        ),
        body: this.isSingleDay
          ? this.translateService.instant(
              'VIEW_RAPPORTEURS_REPORT_SEND_TO_RAPPORTEUR_MODAL_SINGLE_DAY.BODY',
              [reportName]
            )
          : this.translateService.instant(
              'VIEW_RAPPORTEURS_REPORT_SEND_TO_RAPPORTEUR_MODAL.BODY',
              [reportName]
            )
      },
      { emitEvent: false }
    );
  }
}
