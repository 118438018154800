import { Component, AfterViewChecked, Input, ViewChild, ElementRef, ContentChildren, Output, EventEmitter } from '@angular/core';
import { TabIndexPipe } from 'proceduralsystem-clientcomponents';

@Component({
  selector: 'oir-emitter-collapsible-panel',
  templateUrl: './emitter-collapsible-panel.component.html',
  styleUrls: ['./emitter-collapsible-panel.component.less']
})
export class EmitterCollapsiblePanelComponent implements AfterViewChecked {

  private dom: Document;

  @Input() ariaPanelTitle: string;
  @Input() scrollTop: boolean;
  @Input() public collapsed = true;
  @Input() public canToggle = true;
  @ViewChild('emitterCollapsablePanel') emitterCollapsablePanel: ElementRef;
  @ContentChildren(Input) topLevelPanes;
  @Output() toggled: EventEmitter<any> = new EventEmitter<any>();

  // Depending on whether the panel is collapsed make fields tabbable or untabbable.
  setTabIndex(): void {
    new TabIndexPipe().transform(!this.collapsed, this.emitterCollapsablePanel);
  }

  constructor() {
    this.dom = document;
  }

  public ngAfterViewChecked(): void {
    this.setTabIndex();
  }

  public toggle(): void {
    this.collapsed = !this.collapsed;
    this.setTabIndex();
    this.toggled.emit(this.collapsed);
  }

  public scroll(): void {
    const body: any = this.dom.getElementsByClassName('body');
    if (body) { body[0].scrollTop = 0; }
  }

}
