import { Component, Output, EventEmitter, ViewChild } from '@angular/core';
import { CustomModalComponent } from '../../components/custom-modal/custom-modal.component';

@Component({
  selector: 'oir-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.less']
})
export class DeleteModalComponent {

  @Output() decision: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('confirmDeleteModal') confirmDeleteModal: CustomModalComponent;

   /**
    * Method to confirm cancel modal and clear input fields
    */
  public confirm(): void {
    this.decision.emit(true);
    this.confirmDeleteModal.close();
  }

  /**
   * Method to cancel modal
   */
  public cancel(): void {
    this.decision.emit(false);
    this.confirmDeleteModal.close();
  }

   /**
    * Toggle the cancel modal
    */
  public toggle(): void {
    this.confirmDeleteModal.toggle();
  }
}
