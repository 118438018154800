// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .instance {
  background-color: #ffffff;
}
::ng-deep .outstanding-work-item .instance {
  border-left: 5px solid #7F6C2E !important;
}
.amendment-label {
  color: #b39841;
}
.instance-button {
  display: flex;
  flex-direction: row;
}
`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
