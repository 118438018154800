import { Component, OnInit, ViewChild, OnDestroy, ChangeDetectorRef, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ToastNotificationsService,
  NavigationService,
  TypeaheadValue
} from 'proceduralsystem-clientcomponents';
import { ConfirmCancelModalComponent } from './modals/confirm-cancel-modal/confirm-cancel-modal.component';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { CirculateModalComponent } from './modals/circulate-modal/circulate-modal.component';
import { ActionBarEmailModalObComponent } from './modals/action-bar-email-modal-ob/action-bar-email-modal-ob.component';
import { ReportStoreService } from '../services/report-store.service';
import { take, takeUntil } from 'rxjs/operators';
import {
  AnnouncementOfBusinessDays,
  OrderOfBusiness,
  WorkItemsProposedArrangementsDays,
  AnnouncementOfProposedArrangementsDays
} from '../shared/models/order-of-business.model';
import { Subject, Observable } from 'rxjs';
import { cloneDeep, isEmpty } from 'lodash-es';
import { ReportService } from '../services/report.service';
import { ReportStatuses } from '../shared/report.enum';
import * as moment from 'moment';
import { SharedService } from '../services/shared.service';
import { GLOBALS } from '../shared/globals';
import {TranslateService} from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
import OirCkEditor from 'proceduralsystem-ckeditor';
import { OirCkEditorConfig } from 'proceduralsystem-ckeditor';
import { EditorConfig } from 'src/app/shared/models/editor-config.model';
import { AppConfigService } from 'src/app/services/app-config.service';

@Component({
  selector: 'oir-order-of-business',
  templateUrl: './order-of-business.component.html',
  styleUrls: ['./order-of-business.component.less']
})
export class OrderOfBusinessComponent implements OnInit, OnDestroy {

  @ViewChild('confirmCancelModalComponent') confirmCancelModalComponent: ConfirmCancelModalComponent;
  @ViewChild('circulateModalComponent') circulateModalComponent: CirculateModalComponent;
  @ViewChild('actionBarEmailModalObComponent') actionBarEmailModalObComponent: ActionBarEmailModalObComponent;

  public report: OrderOfBusiness;
  public defaultHeader: string;
  public orderOFBusinessHeader: string;
  public reportHeader: string;
  public headerTextStripped: string;
  public defaultHeaderText: string;
  public headerWithHTML: string;
  public announcementOfBusinessDays: Array<AnnouncementOfBusinessDays>;
  public announcementOfProposedArrangementsDays: Array<AnnouncementOfProposedArrangementsDays>;
  public workItemsProposedArrangementsDays: Array<WorkItemsProposedArrangementsDays>;
  public obDetailFirstDay: string;
  public obDetailLastDay: string;
  public obDetailFirstLastDays: string;
  public orderOfBusinessViewForm: UntypedFormGroup;
  public configToolbar = [
    { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Strike'] },
    { name: 'styles', items: ['Styles'] },
    { name: 'paragraph', items: ['BulletedList', 'NumberedList'] },
    { name: 'insert', items: ['Table'] },
    { name: 'tools', items: ['Maximize'] },
  ];
  public ReportStatuses = ReportStatuses;
  public obFileName: string;
  public obVersion: string;
  public orderOfBusiness: OrderOfBusiness;
  public recipients: TypeaheadValue<any>[] = [];
  public isHeaderTextEmpty: boolean;
  public reportTitle: string;
  public reportStatus: number;
  public isSingleDay: boolean;
  public isReportOutdated: boolean;

  private ngUnsubscribe = new Subject<void>();
  private documentId: string;
  private window: Window;

  Editor = OirCkEditor;
  ckEditorConfig: OirCkEditorConfig;

  public recursiveLimit = 0;

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private toastService: ToastNotificationsService,
    private reportStore: ReportStoreService,
    private reportService: ReportService,
    private sharedService: SharedService,
    private changeDetectorRef: ChangeDetectorRef,
    private navigationService: NavigationService,
    private configurationService: AppConfigService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.window = this.document.defaultView;
    this.ckEditorConfig = {
      ...EditorConfig,
      licenseKey: this.configurationService.getValue('CKEditor5LicenseKey')
    }
  }

  ngOnInit(): void {

    this.orderOfBusinessViewForm = this.fb.group({
      orderOFBusinessHeader: ['', [Validators.required, Validators.maxLength(1050)]],
    });
    this.documentId = this.route.snapshot.paramMap.get('documentId');
    this.route.data.pipe(take(1)).subscribe(data => {
      if (data.createOB) {
        this.previewOrGetOrderOfBusiness(this.documentId, this.reportService.previewOrderOfBusiness(this.documentId));
      } else {
        this.previewOrGetOrderOfBusiness(this.documentId, this.reportService.getOrderOfBusinessReport(this.documentId));
      }
    });

    this.reportService.getMailsList().pipe(take(1)).subscribe(res => {
      res.map((emails) => {
        if (emails.recipientEmail !== null) {
          this.recipients.push({ value: emails.recipientId, title: emails.recipientEmail});
        }
      });
    });
    // Only temporary for development team.
    this.recipients = this.sharedService.addDevEmails(this.recipients);

    // Subscribe to report data change in report store service
    this.reportStore.reportObData$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(report => {
        this.report = cloneDeep(report);
        if (report) {
          this.changeDetectorRef.detectChanges();
        }
      });
  }

  onReady() {
    if (this.window && this.window[0] && this.defaultHeaderText && this.recursiveLimit <= GLOBALS.recursiveMaxLimit) {
      this.window[0].document.getElementsByTagName('body')[0].style.textAlign = "center";
      this.window[0].document.getElementsByTagName('body')[0].style.fontFamily = "'Montserrat-Regular', sans-serif";
      this.window[0].document.getElementsByTagName('body')[0].style.fontSize = "15px";
      this.window[0].document.getElementsByTagName('body')[0].style.lineHeight = "20px";
      this.window[0].document.getElementsByTagName('body')[0].style.color = "rgb(68, 68, 68)";
    }
    else {
      setTimeout(() => {
        this.recursiveLimit++;
        this.onReady();
      }, 1000);
    }
  }

  /**
   * Clearing navigation on destroy
   */
  public ngOnDestroy(): void {
    this.clearNavigation();
  }


  /**
   * Call service to get PDF preview of report
   */
  public generatePrintPreview(): void {
    this.reportService.generateOrderOfBusinessPrintPreview(this.documentId).pipe(take(1)).subscribe(res => {
      const file: Blob = new Blob([res], { type: 'application/pdf' });
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(file);
      } else {
        const fileURL: string = URL.createObjectURL(file);
        window.open(fileURL);
      }
    });
  }
  /**
   * Method to confirm cancel modal functionality
   * @param event
   */
  public cancelObModalDecision(event): void {
    if (event) {
      const title = this.reportStatus === ReportStatuses.Circulated ?
        this.translateService.instant('TOAST.CANCEL_ORDER_OF_BUSINESS_CIRCULATED.HEADING') :
        this.translateService.instant('TOAST.CANCEL_ORDER_OF_BUSINESS_DRAFT.HEADING');
      const description = this.reportStatus === ReportStatuses.Circulated ?
        this.translateService.instant('TOAST.CANCEL_ORDER_OF_BUSINESS_CIRCULATED.BODY') :
        this.translateService.instant('TOAST.CANCEL_ORDER_OF_BUSINESS_DRAFT.BODY');

      this.toastService.addNotification({ title, description });
      this.router.navigate(['/dashboard']);
    }
  }

  /**
   * Method for Save as draft button functionality
   * Calling api post request
   */
  public saveAsDraft(): void {
    const reportData = {
      reportId: this.documentId,
      reportHeader: !isEmpty(this.headerWithHTML) ? this.headerWithHTML : this.defaultHeaderText,
    };
    this.saveReport(this.reportService.saveCirculatedOBAsDraft(reportData));
  }

  /**
   * Method to open Cancel modal
   */
  public openCancelModal(): void {
    this.confirmCancelModalComponent.toggle();
  }

  /**
   * Method to open Circulate OB modal
   */
  public openCirculateOB(): void {
    const reportData = {
      reportId: this.documentId,
      reportHeader: !isEmpty(this.headerWithHTML) ? this.headerWithHTML : this.defaultHeaderText,
    };
    this.circulateModalComponent.open(reportData);
  }

  public isOutdated() {
    const reportLastDay = moment(this.orderOfBusiness.businessScheduleDetailLastDay, 'DD/MM/YYYY')
    const today = moment()
    this.isReportOutdated = today.isAfter(reportLastDay, 'day')
  }

  /**
   * Method to send file from action bar
   */
  public sendEmailFile(): void {
    this.actionBarEmailModalObComponent.open();
  }

  /**
   * Function to hide days
   * @param event event
   * @param index index
   */
  public hideDays(event, index: number): void {
    const days = document.querySelector('.proposed-week-announcement').children;
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < days.length; i++) {
      days[i].setAttribute(
        'style', 'display: ' + (i !== index && event ? 'none' : 'block')
      );
    }
  }

  /**
   * Function to hide days
   * @param event event
   * @param index index
   */
  public hideDayArrangement(event, index: number): void {
    const days = document.querySelector('.proposed-week-arrangement').children;
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < days.length; i++) {
      days[i].setAttribute(
        'style', 'display: ' + (i !== index && event ? 'none' : 'block')
      );
    }
  }

  /**
   * Check if Headers length of text is under or equal to 1000 characters
   */
  public checkHeaderMaxChars(event: any): void {
    const length = event.target.innerHTML
      .replace(/<\/?[^>]+(>|$)/g, '')
      .replace(/[Ââ†µ\n]/g, '')
      .length;
    if (length !== 0 && length >= 1000 && event.keyCode !== 46
      && event.keyCode !== 8 && event.keyCode !== 37 && event.keyCode !== 39) {
      event.preventDefault();
    } else {
      // need timeout to detect changes
      setTimeout(() => {
        this.headerWithHTML = event.target.innerHTML;
      }, 100);
    }
  }

  /**
   * Function to check is Announcement Text is empty
   * @param event Event
   */
  public onHeaderChange(event): void {
    this.isHeaderTextEmpty = !isEmpty(event) ? false : true;
  }

  /**
   * Function to check is form Invalid
   */
  public isFormInvalid(): boolean {
    return this.isHeaderTextEmpty;
  }

  /**
   * Function to get or preview Order of Busines
   * @param id Report id
   * @param getApi preview or get report API
   */
  public previewOrGetOrderOfBusiness(id: string, getApi: Observable<any>): void {
    getApi.pipe(take(1)).subscribe(res => {
      this.orderOfBusiness = res;
      this.isOutdated()
      this.defaultHeaderText = res.reportHeader;
      this.reportStatus = res.reportStatus;
      this.obDetailFirstDay = this.sharedService.convertDate(res.businessScheduleDetailFirstDay);
      this.obDetailLastDay = this.sharedService.convertDate(res.businessScheduleDetailLastDay);
      this.obDetailFirstLastDays = `(${this.obDetailFirstDay} - ${this.obDetailLastDay})`;
      this.isSingleDay = this.obDetailFirstDay === this.obDetailLastDay;
      this.obDetailFirstLastDays = this.obDetailFirstDay === this.obDetailLastDay ?
        `(${moment.utc(this.obDetailFirstDay).format(GLOBALS.dateFormat)})` :
        `(${moment.utc(this.obDetailFirstDay).format(GLOBALS.dateFormat)} - ${moment.utc(this.obDetailLastDay).format(GLOBALS.dateFormat)})`;
      this.obVersion = res.reportVersion;
      this.announcementOfBusinessDays = res.announcementOfBusinessDays.sort((a, b) => {
        return new Date(this.sharedService.convertDate(a.bsDayDate)).getTime() -
          new Date(this.sharedService.convertDate(b.bsDayDate)).getTime();
      });
      this.announcementOfProposedArrangementsDays = res.announcementOfProposedArrangementsDays.sort((a, b) => {
        return new Date(this.sharedService.convertDate(a.bsDayDate)).getTime() -
          new Date(this.sharedService.convertDate(b.bsDayDate)).getTime();
      });
      this.reportTitle = res.reportStatus === ReportStatuses.Draft ?
        this.translateService.instant('ORDER_OF_BUSINESS.PAGE_TITLE_DRAFT', [this.obDetailFirstLastDays]) :
        this.translateService.instant('ORDER_OF_BUSINESS.PAGE_TITLE_CIRCULATED',
          [this.obDetailFirstLastDays, res.reportVersion]);
      this.initNavigation(id, this.obDetailFirstLastDays, res.reportStatus, res.reportVersion);
      this.onReady();
    });
  }

  /**
   * Function to save Report
   * @param postAPI Post API
   * @param reportData Report data
   */
  private saveReport(postAPI: Observable<any>): void {
    postAPI.pipe(take(1)).subscribe(() => {
      this.toastService.addNotification({
        title: this.translateService.instant('TOAST.ORDER_OF_BUSINESS_DRAFT.TITLE'),
        description: this.translateService.instant('TOAST.ORDER_OF_BUSINESS_DRAF.BODY', [this.obDetailFirstLastDays])});
      this.router.navigate(['dashboard/']);
    });
  }

  /**
   * Function to create navigation node
   * @param params page id
   * @param date report name
   */
  private initNavigation(params: any, date: string, status: number, version: number): void {
    const title = status === ReportStatuses.Draft ?
      this.translateService.instant('DASHBOARD.NAVIGATION_ITEM_ORDER_OF_BUSINESS.DRAFT', [date]) :
      this.translateService.instant('DASHBOARD.NAVIGATION_ITEM_ORDER_OF_BUSINESS.CIRCULATED', [date, version]);
    const parentTitle = 'Dashboard';
    const taskManagerNode = this.navigationService.model.tree.find(
      x => x.title === parentTitle
    );
    this.navigationService.select(taskManagerNode);
    this.navigationService.addNode({
      title, path: `/order-of-business/${params}`
    });
  }

  /**
   * Function to clear navigation node
   */
  private clearNavigation(): void {
    this.navigationService.select(this.navigationService.currentNode);
    const taskManagerNode = this.navigationService.model.tree.find(
      x => x.title === 'Dashboard'
    );
    taskManagerNode.expanded = false;
  }


}
