// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dragable {
  display: flex;
  padding: 5px 0;
}
.dragable ::ng-deep oir-toggleable-accordion {
  width: 100%;
}
.dragable ::ng-deep oir-toggleable-accordion .toggle-accordion > .panel > .align-items > .header > .title {
  width: 95%;
}
.dragable ::ng-deep oir-toggleable-accordion .toggle-accordion > .panel > .align-items > .header > .buttons {
  width: 10%;
}
@media only screen and (max-width: 1450px) {
  .dragable ::ng-deep oir-toggleable-accordion .toggle-accordion > .panel > .align-items > .header > .title {
    width: 90%;
  }
}
.dragable .drag-element {
  min-width: 20px;
}
.dragable .drag-element img {
  position: relative;
  margin-top: 14px;
}
.dragable .section {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.dragable .section .section-front {
  margin-top: -2px;
}
.dragable .section .section-front img {
  position: relative;
  margin-top: 8px;
  vertical-align: -8px;
  margin-right: 10px;
}
.dragable .section .section-front .section-title {
  font-weight: bold;
}
.dragable .section .section-back {
  margin-top: -11px;
}
.dragable .accordion-button {
  line-height: 50px;
  min-width: 102px;
}
`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
