import { Component, ViewChild, EventEmitter, Output } from '@angular/core';
import { ModalComponent } from 'proceduralsystem-clientcomponents';

@Component({
  selector: 'oir-confirm-cancel-modal',
  templateUrl: './confirm-cancel-modal.component.html',
  styleUrls: ['./confirm-cancel-modal.component.less']
})
export class ConfirmCancelModalComponent {

  @Output() cancelObConfirmed: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('cancelModal') cancelModal: ModalComponent;

  /**
   * Method to confirm cancel model
   */
  public confirm(): void {
    this.cancelObConfirmed.emit(true);
    this.cancelModal.close();
  }

  /**
   * Method to cancel modal
   */
  public cancel(): void {
    this.cancelObConfirmed.emit(false);
    this.cancelModal.close();
  }

  /**
   * Method to open the cancel modal
   */
  public toggle(): void {
    this.cancelModal.toggle();
  }


}
