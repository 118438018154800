import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SearchComponent } from './search/search.component';
import { CreateReportComponent } from './create-report/create-report.component';
import { ViewReportComponent } from './bcr-report/view-report.component';
import { OrderOfBusinessComponent } from './order-of-business/order-of-business.component';
import { RapportuersReportComponent } from './rapportuers-report/rapportuers-report.component';
import { PageComponent } from './page.component';
import { AccessDeniedComponent } from 'proceduralsystem-clientcomponents/components/access-denied/access-denied.component';
import { NotFoundComponent } from 'proceduralsystem-clientcomponents/components/not-found/not-found.component';

const routes: Routes = [
  { path: '', redirectTo: 'create-report', pathMatch: 'full' },
  {
    path: '',
    component: PageComponent,
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent
      },
      {
        path: 'bcr-report/:documentId',
        component: ViewReportComponent
      },
      {
        path: 'create-rapporteurs-report/:documentId',
        data: { createRR: true },
        component: RapportuersReportComponent
      },
      {
        path: 'rapporteurs-report/:documentId',
        data: { createRR: false },
        component: RapportuersReportComponent
      },
      {
        path: 'create-order-of-business/:documentId',
        data: { createOB: true },
        component: OrderOfBusinessComponent
      },
      {
        path: 'order-of-business/:documentId',
        data: { createOB: false },
        component: OrderOfBusinessComponent
      },
      {
        path: 'search',
        component: SearchComponent
      },
      {
        path: 'create-report',
        component: CreateReportComponent
      },
      { path: 'access-denied', component: AccessDeniedComponent },
      { path: '**', component: NotFoundComponent }
    ]
  }
];

export const routing = RouterModule.forRoot(routes, {
  useHash: false
});
