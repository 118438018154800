// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.report-placholder {
  border: 1px solid #d4cfbc;
  border-left: 5px solid #b39841;
  padding: 25px;
  display: flex;
  justify-content: space-between;
}
.report-placholder .report-title {
  margin-left: 45px;
  vertical-align: -15px;
}
.previous-report__filter {
  display: flex;
  justify-content: space-between;
}
.previous-report__filter div {
  width: 47%;
}
.item-margin--basic {
  margin: 20px 0px 20px -20px;
}
.item-margin--advanced {
  margin: 20px 0px 20px 0px;
}
`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
