import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { OirHttpService } from 'proceduralsystem-clientcomponents';
import {
  Dashboard,
  OutstandingItems
} from 'src/app/shared/models/dashboard.model';
import { TypeOfReport } from 'src/app/shared/report.enum';
import { AppConfigService } from '../../services/app-config.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  public report = new Subject<any>();
  public reportDeleted = this.report.asObservable();
  public reportData = new Subject<any>();
  public outstandingItemsStore = new Subject<any>();
  public reportDataArrived = this.reportData.asObservable();
  public outstandingDataArrived = this.outstandingItemsStore.asObservable();
  public outstandingItem = new Subject<any>();
  public isOutstanding = this.outstandingItem.asObservable();
  public reportTypeUrl: string;

  constructor(
    private oirHttp: OirHttpService,
    private config: AppConfigService
  ) {}

  public viewOutstandingItems(data: any) {
    this.outstandingItem.next(data);
  }
  public reportWasDeleted(data: any) {
    this.report.next(data);
  }
  public reportsDataArr(data: any) {
    this.reportData.next(data);
  }
  public outstandingItemsData(data: any) {
    this.outstandingItemsStore.next(data);
  }
  public getReport(
    pageNumber: number,
    queryType: number
  ): Observable<Dashboard> {
    return this.oirHttp.get<any>({
      path: `/reports?Page=${pageNumber}&QueryType=${queryType}`
    });
  }
  public getOutstandingItems(
    pageNumber: number,
    queryType: number
  ): Observable<OutstandingItems> {
    return this.oirHttp.get<any>({
        path: `/reports/outstanding?Page=${pageNumber}&QueryType=${queryType}`
    });
  }
  public deleteReport(id: number, reportType: number): Observable<Dashboard> {
    switch (reportType) {
      case TypeOfReport.RR:
        this.reportTypeUrl = `/api/RapporteurReport?rrId=${id}`;
        break;
      case TypeOfReport.OB:
        this.reportTypeUrl = `/api/OB/?obId=${id}`;
        break;
      default:
        this.reportTypeUrl = `/bcr?reportId=${id}`;
        break;
    }

    return  this.oirHttp.delete<any>({
        path: this.reportTypeUrl
      });
  }

  public getPreviousReports(
    pageNumber: number,
    reportType: number,
    dateFrom: string,
    dateTill: string
  ): Observable<Dashboard> {
    return this.oirHttp.get<any>({
      path: `/previousreports?Page=${pageNumber}&ReportType=${reportType}&DateFrom=${dateFrom}&DateTill=${dateTill}`
    });
  }
}
