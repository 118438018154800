// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .toggle-accordion {
  position: relative;
  background-color: #f4f2ea;
}
::ng-deep .toggle-accordion > .panel {
  margin: 5px 25px 0px 25px;
  border: none;
  width: auto;
  background-color: transparent;
}
::ng-deep .toggle-accordion > .panel > .align-items {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
::ng-deep .toggle-accordion > .panel > .align-items > .header {
  height: 50px;
  line-height: 50px;
  vertical-align: middle;
  background-color: #f4f2ea;
}
::ng-deep .toggle-accordion > .panel > .align-items > .header > p {
  display: inline-block;
  margin-left: 20px;
  margin-top: 10px;
  vertical-align: middle;
  font-weight: bold;
}
::ng-deep .toggle-accordion > .panel > .align-items > .header > .open-left {
  display: inline-block;
  vertical-align: middle;
  height: 24px;
  width: 24px;
  background-image: url('unfilled-plus.12cf3c2da4d95201.svg');
  background-size: 24px;
}
::ng-deep .toggle-accordion > .panel > .align-items > .header > .close-left {
  display: inline-block;
  vertical-align: middle;
  height: 24px;
  width: 24px;
  background-image: url('unfilled-circle-x.8122d2f1408175c5.svg');
}
::ng-deep .toggle-accordion > .panel > .align-items > .header > .close-left p#italics {
  font-weight: 300;
  font-style: italic;
  margin-left: 15px;
}
::ng-deep .toggle-accordion > .panel > .align-items > .header.opened,
::ng-deep .toggle-accordion > .panel > .align-items .buttons.opened {
  border-bottom: 2px solid #d4cfbe;
}
::ng-deep .toggle-accordion > .panel > .align-items > .header.opened > p,
::ng-deep .toggle-accordion > .panel > .align-items .buttons.opened > p {
  display: inline-block;
  margin-left: 20px;
  margin-top: 10px;
  vertical-align: middle;
  font-weight: bold;
}
::ng-deep .toggle-accordion > .panel > .align-items > .header.opened > .open-left,
::ng-deep .toggle-accordion > .panel > .align-items .buttons.opened > .open-left {
  display: inline-block;
  vertical-align: middle;
  height: 24px;
  width: 24px;
  background-image: url('unfilled-plus.12cf3c2da4d95201.svg');
  background-size: 24px;
}
::ng-deep .toggle-accordion > .panel > .align-items > .header.opened > .close-left,
::ng-deep .toggle-accordion > .panel > .align-items .buttons.opened > .close-left {
  display: inline-block;
  vertical-align: middle;
  height: 24px;
  width: 24px;
  background-image: url('unfilled-circle-x.8122d2f1408175c5.svg');
}
::ng-deep .toggle-accordion.disabled,
::ng-deep .toggle-accordion[disabled] {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
  filter: alpha(opacity=40);
  opacity: 0.4;
}
::ng-deep .toggle-accordion.disabled:hover,
::ng-deep .toggle-accordion[disabled]:hover {
  cursor: no-drop;
}
::ng-deep .toggle-accordion.sub-accordion {
  background-color: #ece9dd;
  border-color: #d4cfbc;
}
::ng-deep .toggle-accordion.sub-accordion .panel {
  background-color: #ece9dd;
}
::ng-deep .toggle-accordion.sub-accordion .panel .header {
  background-color: #ece9dd;
}
::ng-deep .toggle-accordion.sub-accordion .panel .header .close-left {
  background-image: url('close-circle.ce4097ed45dc398c.svg') !important;
}
::ng-deep .toggle-accordion.sub-accordion .panel .header .open-left {
  background-image: url('filled-plus.aa94d5102baa64cc.svg') !important;
  background-size: 24px;
}
::ng-deep .toggle-accordion.sub-accordion .panel .body {
  background-color: #ece9dd !important;
  padding: 0px;
}
::ng-deep .toggle-accordion.sub-accordion .panel .body .accordion-body .witness-time,
::ng-deep .toggle-accordion.sub-accordion .panel .body .accordion-body .facilities-time {
  margin-bottom: 15px;
}
::ng-deep .toggle-accordion.sub-accordion .panel .body .accordion-body .witness-time .time .divider-text,
::ng-deep .toggle-accordion.sub-accordion .panel .body .accordion-body .facilities-time .time .divider-text {
  vertical-align: middle;
  margin-top: 0px;
}
::ng-deep .toggle-accordion.sub-accordion .panel .body .accordion-body .buttons-row {
  padding: 15px 0px 30px 0px;
}
::ng-deep .toggle-accordion.sub-accordion .panel .body .accordion-body .buttons-row .buttons {
  text-align: right;
}
::ng-deep .toggle-accordion.sub-accordion .panel .body .accordion-body .buttons-row .buttons button {
  text-align: center;
}
::ng-deep .toggle-accordion .panel .align-items .header:hover {
  cursor: pointer;
}
::ng-deep .toggle-accordion .panel .body {
  background-color: #f4f2ea;
  overflow: visible;
}
::ng-deep .toggle-accordion .align-items .header button {
  background-color: transparent;
  outline: none;
  border: none;
  transform: translate(0, 0);
}
::ng-deep .toggle-accordion .align-items .header button:hover,
::ng-deep .toggle-accordion .align-items .header button:active,
::ng-deep .toggle-accordion .align-items .header button:focus {
  outline: 1px solid;
  outline: auto -webkit-focus-ring-color;
  outline-color: #b39841;
}
`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
