// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.emailReportForm .emailReport-to,
.emailReportForm .emailReport-cc,
.emailReportForm .emailReport-bcc,
.emailReportForm .emailReport-subject,
.emailReportForm .emailReport-body {
  padding: 20px 0;
}
.emailReportForm .emailReport-subject .text-wrapper {
  width: 100%;
}
.emailReportForm .emailReport-body .text-wrapper {
  width: 100%;
}
.emailReportForm .emailReport-body .text-wrapper textarea {
  width: 100%;
}
`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
