import { Component, ViewChild, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModalComponent } from 'proceduralsystem-clientcomponents';
import { DashboardService } from '../dashboard-services/dashboard-service.service';
import { take } from 'rxjs/operators';
import { TypeOfReport } from 'src/app/shared/report.enum';

@Component({
  selector: 'oir-delete-confirmation-modal',
  templateUrl: './delete-confirmation-modal.component.html',
  styleUrls: ['./delete-confirmation-modal.component.less']
})
export class DeleteConfirmationModalComponent {
  @ViewChild('Delete') deleteModal: ModalComponent;
  @Output() reportDeleted: EventEmitter<any> = new EventEmitter<any>();
  public reportToDelete: number;
  public reportTypeToDelete: number;
  public reportType: number;
  public modalTitle: string;
  public modalText: string;

  constructor(
    private translateService: TranslateService,
    private dasboardService: DashboardService
  ) { }

  openDeleteModal(id: number, type: string, queryType: number, reportTypeToDelete: number) {
    this.reportToDelete = id;
    this.reportTypeToDelete = reportTypeToDelete;
    this.reportType = queryType;
    this.modalTitle = this.translateService.instant('DASHBOARD.DELETE_MODAL.TITLE', [type, TypeOfReport[reportTypeToDelete]]);
    this.modalText = this.translateService.instant('DASHBOARD.DELETE_MODAL.TEXT', [type.toLowerCase(), TypeOfReport[reportTypeToDelete]]);
    this.deleteModal.toggle();
  }

  cancelDelete() {
    this.deleteModal.close();
  }

  confirmDelete() {
    this.dasboardService.deleteReport(this.reportToDelete, this.reportTypeToDelete).pipe(take(1)).subscribe(() => {
      this.deleteModal.close();
      this.dasboardService.reportWasDeleted(this.reportType);
    });
  }

}
