import { toArray } from 'lodash-es';

export class ReportUtils {

  public static sortList(prop: string, list: any): any {
    if (!list) {
      return ;
    }

    const sorted = list.sort((a, b) => {
      if (a[prop] > b[prop]) {
        return 1;
      }
      return a[prop] === b[prop] ? 0 : -1;
    });
    // Reverse order if - is supplied.
    if (prop.charAt(0) === '-') {
      sorted.reverse();
    }

    return sorted;
  }

  public static getRteInputTrueLength(value: string): number {
    // Using toArray to cover emojis.
    return value && value.length
      ? toArray(value.replace(/<\/?[^>]+>/g, '')
        .replace(/^(\s+|\s+)$/g, '')
        .replace(/&(amp|lt|gt|quot|#39|nbsp);/g, match => {
          switch (match) {
            case '&': return '&';
            case '<': return '<';
            case '>': return '>';
            case '"': return '"';
            case '&#39;': return "'";
            case ' ': return ' ';
            default: return match;
          }
        })).length
      : 0;
  }
}
