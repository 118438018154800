import {
  Component, OnInit, Input, ElementRef, OnChanges, SimpleChanges, Output, EventEmitter, Renderer2,
  forwardRef, ViewChild, QueryList, ViewChildren
  } from '@angular/core';
import * as Moment from 'moment';
import { MyFormatter, padNumber, isNumber, toInteger } from './datepicker-custom.service';
const moment: any = (Moment as any).default || Moment;

import {
  DatePickerModel,
  ViewDate,
  DatePickerOptions
  } from './datepicker-custom.model';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbDateParserFormatter, NgbInputDatepicker, NgbDatepicker, NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';

enum KeyPress {
  Tab = 9,
  Enter = 13,
  Esc = 27,
  Space = 32,
  Up = 38,
  Down = 40
}

@Component({
  selector: 'datepicker-custom',
  templateUrl: './datepicker-custom.component.html',
  styleUrls: ['./datepicker-custom.component.less'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DatePickerCustomComponent), multi: true },
    { provide: NgbDateParserFormatter, useClass: MyFormatter }
  ]
})
export class DatePickerCustomComponent
implements OnInit, OnChanges, ControlValueAccessor {

  constructor(private ngbCalendar: NgbCalendar, private el: ElementRef, private renderer: Renderer2) {
    this.numbers.fill(0);
    this.numbers = this.numbers.map((_x, i) => i);
  }
  formats = ['D/MM/YYYY', 'DD/MM/YYYY', 'D/M/YYYY', 'DD/M/YYYY'];
  rangeFormats = [
    'D/MM/YYYY - D/MM/YYYY',
    'D/MM/YYYY - DD/MM/YYYY',
    'DD/MM/YYYY - DD/MM/YYYY',
    'DD/MM/YYYY - D/MM/YYYY',
    'D/M/YYYY - D/M/YYYY',
    'D/M/YYYY - DD/M/YYYY',
    'DD/M/YYYY - D/M/YYYY',
    'DD/M/YYYY - DD/M/YYYY'
  ];
  isSelectingRange = false;
  @ViewChild(NgbInputDatepicker) datepickerInput: NgbInputDatepicker;

  @ViewChild(NgbDatepicker) datepicker: NgbDatepicker;
  @ViewChild('dpInput') input: ElementRef;
  @ViewChild('monthInput') monthInput: ElementRef;
  @ViewChildren('monthInputs') monthInputs: QueryList<ElementRef>;
  @ViewChild('yearInput') yearInput: ElementRef;
  @ViewChildren('yearInputs') yearInputs: QueryList<ElementRef>;

  @Input() model: any;
  @Input() rangeModel: any;
  @Input() disabled: boolean;
  @Input() invalid: boolean;
  @Input() placeholder: string;
  @Input() daterangeplaceholder: string;
  @Input() options: DatePickerOptions = new DatePickerOptions();
  @Input() showNoOfMonths = 1;
  @Input() showWeekNumbers = false;
  @Input() startDate;
  // 3 options: 'visible' | 'collapsed' | 'hidden'.
  @Input() outsideDays = 'visible';
  @Input() dateRange = false;
  @Output() modelChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() openedCalendar: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() selectingRange: EventEmitter<any> = new EventEmitter<any>();
  @Output() closedCalendar: EventEmitter<any> = new EventEmitter<any>();

  viewDate: ViewDate;
  currentDateView: { current: NgbDate | null; next: NgbDate | null } = {
    current: this.ngbCalendar.getToday(),
    next: this.ngbCalendar.getToday(), // Initialize with the current date
  };
  previousDateValue: any;
  calendarModel: DatePickerModel = new DatePickerModel();
  active = false;
  showDatePicker = false;
  numbers = new Array(30);
  showDropDownYear: boolean;
  showDropDownMonth: boolean;
  hoveredDate: NgbDate;
  fromDate: NgbDate;
  toDate: NgbDate;
  monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
  currentMonth = new Date().getMonth()+1;
  previousDateRangeValue: any;
  private listener: Function;
  private debounce: Observable<any>;
  propagateChange = (_: string) => { };
  propagateTouch = (_?: any) => { };

  ConvertStringToNumber(value) {
    return Number(value);
  }

  ngOnInit() {
    if (!this.placeholder || !this.daterangeplaceholder) {
      this.placeholder = 'DD/MM/YYYY';
      this.daterangeplaceholder = 'DD/MM/YYYY - DD/MM/YYYY';
    }
    if (this.model || this.rangeModel) {
      this.initModel();
    } else {
      this.model = null;
    }

    this.previousDateValue = this.model ? this.model : this.rangeModel;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.model || this.rangeModel) {
      if (changes.model && !changes.model.firstChange) {
        this.initModel(changes.model);
      }
    }
  }

  private initModel(modelChanges = null): void {
    if (typeof this.model !== 'string') {
      this.model = this.toDateString(this.model);
    }

    const modelFormat = [
      'D/MM/YYYY',
      'DD/MM/YYYY',
      'D/M/YYYY',
      'DD/MM/YYYY',
    ];

    const modelRangeFormat = [
      'D/MM/YYYY - D/MM/YYYY',
      'D/MM/YYYY - DD/MM/YYYY',
      'DD/MM/YYYY - DD/MM/YYYY',
      'DD/MM/YYYY - D/MM/YYYY',
      'D/M/YYYY - D/M/YYYY',
      'D/M/YYYY - DD/M/YYYY',
      'DD/M/YYYY - D/M/YYYY',
      'DD/M/YYYY - DD/M/YYYY'
    ];
    const model = moment(this.model, modelFormat);
    const rangeModel = moment(this.rangeModel, modelRangeFormat);

    if (this.dateRange && this.rangeModel.includes('-')) {
      this.getDateRange(rangeModel, modelChanges, modelRangeFormat);
    } else {
      if (modelChanges && modelChanges.previousValue) {
        this.model = model.isValid()
          ? this.toDateObj(model.format('DD/MM/YYYY'))
          : this.toDateObj(modelChanges.previousValue);
      } else {
        this.model = model.isValid()
          ? this.toDateObj(model.format('DD/MM/YYYY'))
          : this.toDateObj(moment().format('DD/MM/YYYY'));
      }
    }
  }

  // set rangeModel on init if input is a date range
  getDateRange(rangeModel, modelChanges, modelFormat) {
    const date =  this.rangeModel.split('-');
    const fromDateModel = moment(date[0], modelFormat);
    const toDateModel = moment(date[1], modelFormat);

    if (modelChanges) {
      this.rangeModel = rangeModel.isValid() ?
      this.toDateString([this.toDateObj(fromDateModel.format('DD/MM/YYYY')), this.toDateObj(toDateModel.format('DD/MM/YYYY'))]) :
      moment().format('DD/MM/YYYY');
    }
}

  toggle() {
    this.showDatePicker = !this.showDatePicker;
    if (this.showDatePicker) {
      this.registerListener();
      this.openedCalendar.emit();
      setTimeout(() => {
        this.datepicker.navigateTo(this.model ? this.model : this.startDate );
      });
    }
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn) {
    this.propagateTouch = fn;
  }

  writeValue(obj: any): void {
    // Convert string to NgbDateStruct object.
    if (typeof obj === 'undefined' || obj === null) {
      this.model = null;
      this.previousDateValue = null;
    } else if (obj != null && obj.hasOwnProperty('value')) {
      this.model = this.toDateObj(obj.value);
      this.previousDateValue = this.toDateObj(obj.value);
    } else if (obj != null) {
      this.model = this.toDateObj(obj);
      this.previousDateValue = this.toDateObj(obj);
    }
  }

  setDisabledState(value: any): void {
    this.disabled = value;
  }

  // Allow select date from outside component.
  select(data): void {
    let datePassed = '';
    if (data != null && data.hasOwnProperty('value')) {
      datePassed = data.value;
    } else {
      datePassed = data;
    }
    if (!this.dateIsNotInRange(datePassed)) {
      this.model = this.toDateObj(datePassed);
      this.startDate = null;
      this.previousDateValue = this.toDateObj(datePassed);
      this.propagateChange(datePassed);
      this.modelChange.emit(datePassed);
    } else {
      this.input.nativeElement.value = '';
    }
  }

  keyUpCircle(event): void {
    // If escaped pressed while focused on the navigation arrows close the datepicker.
    if (event.which === KeyPress.Esc) {
      this.showDatePicker = false;
    }
  }

  resetInput() {
    this.model = null;
    this.rangeModel = null;
    this.startDate = null;
    this.fromDate = null;
    this.toDate = null;
    this.previousDateValue = null;
    this.propagateChange(this.model);
    this.modelChange.emit(this.model);
    this.propagateChange(this.rangeModel);
    this.modelChange.emit(this.rangeModel);
  }

  keyupEventHandler(keyCode): void {
    if (this.input.nativeElement.value === '') {
      this.resetInput();
    }
    // If escaped pressed while focused on input textbox close the datepicker and reset the value.
    if (keyCode === KeyPress.Esc) {
      this.model = this.previousDateValue;
      this.startDate = null;
      this.fromDate = null;
      this.toDate = null;
      this.showDatePicker = false;
      this.input.nativeElement.value = '';
    }
  }

  // Check if date selected is within range of min date and max date.
  private dateIsNotInRange(date: string): boolean {
    let result = false;

    const currentDate = moment(date, ['DD/MM/YYYY']);

    if (this.options.minDate) {
      const minDate = moment(this.options.minDate, ['DD/MM/YYYY']);

      if (currentDate.isValid() && minDate.isValid()) {
        result = currentDate.isBefore(minDate, 'day');
      }
    }

    if (result === false && this.options.maxDate) {
      const maxDate = moment(this.options.maxDate, ['DD/MM/YYYY']);

      if (currentDate.isValid() && maxDate.isValid()) {
        result = currentDate.isAfter(maxDate);
      }
    }

    return result;
  }

  isDateValid(date) {
    return moment(date, ['DD/MM/YYYY'], true).isValid();
  }

  clearInput(event: any) {
    this.model = null;
    this.rangeModel = null;
    this.startDate = null;
    this.previousDateValue = null;
    this.fromDate = null;
    this.toDate = null;
    this.propagateChange(this.model);
    this.modelChange.emit(this.model);
    this.propagateChange(this.rangeModel);
    this.modelChange.emit(this.rangeModel);
    event.stopPropagation();
    this.closeCalendar();
  }

  closeCalendar() {
    this.showDatePicker = false;
    this.closedCalendar.emit();
  }

  // On blur if date invalid reset to null.
  onBlur($event): void {
    const inputValue = this.input.nativeElement.value;
    if (this.dateRange) {
      this.updateDateRangeValue(inputValue);
    } else {
      this.updateDateValue(inputValue);
    }
    this.propagateTouch($event);
  }

  // Checks input value and sets model if valid.
  updateDateValue(dateValue): any {
    if ((dateValue !== '' && !moment(dateValue, this.formats).isValid()) ||
      (dateValue !== '' && this.dateIsNotInRange(dateValue))) {
      this.input.nativeElement.value = '';
      this.model = null;
      this.startDate = null;
      this.propagateChange(this.model);
      this.modelChange.emit(this.model);
      this.closedCalendar.emit();
      return null;
    } else if (dateValue !== '' && (this.toDateString(this.previousDateValue) !== this.input.nativeElement.value)) {
      this.model = this.toDateObj(this.input.nativeElement.value);
      this.propagateChange(this.toDateString(this.model));
      this.modelChange.emit(this.toDateString(this.model));
      this.closedCalendar.emit();
      return this.model;
    }
  }

  // update daterange only if input is a range
  updateDateRangeValue(dateValue): any {
    let fromDate = '';
    let toDate = '';
    let fromDateNgbDate;
    let toDateNgbDate;
    const dates = dateValue.split('-');
    if (dates.length === 2) {
      fromDate = dates[0];
      toDate = dates[1];
      if (!moment(fromDate, this.formats).isValid() ||  !moment(toDate, this.formats).isValid()) {
        this.clearValues();
        return null;
      } else {
        fromDateNgbDate = this.toNgbdate(this.toDateObj(fromDate));
        toDateNgbDate = this.toNgbdate(this.toDateObj(toDate));
      }
      // check of toDate is after than fromDate
      if (!toDateNgbDate.after(fromDateNgbDate)) {
        this.clearValues();
        return null;
      }
    } else {
      this.clearValues();
      return null;
    }

    if ((dateValue !== '' && moment(fromDate, this.formats).isValid() &&
      moment(toDate, this.formats).isValid() && this.toDateString(this.previousDateRangeValue) !== this.input.nativeElement.value)) {
      this.toDate = toDateNgbDate;
      this.fromDate = fromDateNgbDate;
      this.rangeModel = this.toDateString([this.fromDate, this.toDate]);
      this.propagateChange(this.rangeModel);
      this.modelChange.emit(this.rangeModel);
      this.renderer.setProperty(this.input.nativeElement, 'value', this.rangeModel);
      return this.rangeModel;
    } else {
      this.clearValues();
      return null;
  }
}

  clearValues() {
    this.input.nativeElement.value = '';
    this.rangeModel = null || this.previousDateRangeValue;
    this.fromDate = null;
    this.toDate = null;
    this.propagateChange(this.rangeModel);
    this.modelChange.emit(this.rangeModel);
  }
  // If closed and focused on input, when enter pressed show calendar.
  keypress(event, value) {
    if (event.which === KeyPress.Enter && !this.showDatePicker && !this.disabled) {
      this.focusDatePicker(value);
    } else if (event.which === KeyPress.Enter && this.showDatePicker && value !== '') {
      this.handleDatePickerEnterKey(value);
    }
  }

  // When date is selected propogate it back up.
  onDateSelect(eventObj) {
    this.model = eventObj;
    this.propagateChange(this.toDateString(this.model));
    this.modelChange.emit(this.toDateString(this.model));
    this.showDatePicker = false;
  }

  navigatePrevious() {
    if (this.currentDateView.next) {
      const prevMonthDate = this.ngbCalendar.getPrev(
        new NgbDate(this.currentDateView.next.year!, this.currentDateView.next.month!, 1),
        'm',
        1
      );

      this.navigateTo(prevMonthDate);
    }
    this.currentDateView = {
      current: this.currentDateView.next,
      next: this.currentDateView.next
    }
  }

  navigateNext() {
    if (this.currentDateView.next) {
      const nextMonthDate = this.ngbCalendar.getNext(
        new NgbDate(this.currentDateView.next.year!, this.currentDateView.next.month!, 1),
        'm',
        1
      );

      this.navigateTo(nextMonthDate);
    }
    this.currentDateView = {
      current: this.currentDateView.next,
      next: this.currentDateView.next
    }
  }

  /**
   * Navigate to selected date month
   */
  public navigateTo(date: any): void {
    this.datepicker.navigateTo(date);
  }

  // When month selected from dropdown.
  selectMonthView(monthNo) {
    monthNo = monthNo + 1;
    const navObj = {
      month: monthNo,
      year: this.currentDateView.next.year
    };
    this.datepicker.navigateTo(navObj);
    this.showDropDownYear = false;
    this.showDropDownMonth = false;
    this.monthInput.nativeElement.focus();
  }

  // When year selected from dropdown.
  selectYearView(event) {
    const navObj = {
      month: this.currentDateView.next.month,
      year: Number(event.target.value)
    };
    this.datepicker.navigateTo(navObj);
    this.showDropDownYear = false;
    this.showDropDownMonth = false;
    this.yearInput.nativeElement.focus();
  }

  calendarNavigated(newDateView) {
    this.currentDateView = newDateView;
  }

  // Converts to match NgbDateStruct object.
  toDateObj(value) {
    if (typeof value !== 'undefined' && value !== null) {
      let dateInput = value.trim().replace('-', '/');
      dateInput = moment(dateInput, this.formats).format('DD/MM/YYYY');
      if (dateInput !== 'Invalid date') {
        const year = dateInput.match(/\d{4}/g).map(Number);
        dateInput = dateInput.replace(String(year), '');
        const dayAndMonth = dateInput.match(/\d{2}/g).map(Number);
        return { day: toInteger(dayAndMonth[0]), month: toInteger(dayAndMonth[1]), year: toInteger(year) };
      }
    }
    return null;
  }

  // Handle key press when focused on dropdown of months.
  monthKeyPress(event) {
    if ((event.which === KeyPress.Up || event.which === KeyPress.Down) || (event.which === KeyPress.Tab && this.showDropDownMonth)) {
      event.preventDefault();
    }
    if (event.which === KeyPress.Up || (event.which === KeyPress.Tab && event.shiftKey && this.showDropDownMonth)) {
      if (this.currentDateView.next.month > 1) {
        this.datepicker.navigateTo(this.ngbCalendar.getPrev(this.currentDateView.next, 'm', 1));
      }
    } else if (event.which === KeyPress.Down || (event.which === KeyPress.Tab && !event.shiftKey && this.showDropDownMonth)) {
      if (this.currentDateView.next.month < 12) {
        this.datepicker.navigateTo(this.ngbCalendar.getNext(this.currentDateView.next, 'm', 1));
      }
    } else if (event.which === KeyPress.Esc) {
      this.showDropDownMonth = false;
    }
  }

  // Handle key press when focused on dropdown of years.
  yearKeyPress(event) {
    if ((event.which === KeyPress.Up || event.which === KeyPress.Down) || (event.which === KeyPress.Tab && this.showDropDownMonth)) {
      event.preventDefault();
    }
    if (event.which === KeyPress.Up || (event.which === KeyPress.Tab && event.shiftKey && this.showDropDownYear)) {
      this.datepicker.navigateTo(this.ngbCalendar.getPrev(this.currentDateView.next, 'y', 1));
    } else if (event.which === KeyPress.Down || (event.which === KeyPress.Tab && !event.shiftKey && this.showDropDownYear)) {
      this.datepicker.navigateTo(this.ngbCalendar.getNext(this.currentDateView.next, 'y', 1));
    } else if (event.which === KeyPress.Esc) {
      this.showDropDownYear = false;
    }
  }

  // This is used when opening a header dropdown. It scrolls to the selected item.
  scrollToDDItem(dropdown) {
    if (document.activeElement.tagName.toLowerCase() !== 'input') {
      let scrollVal = 0;
      if (dropdown === 'dropdownMonth') {
        this.showDropDownMonth = !this.showDropDownMonth;
        this.showDropDownYear = false;
        scrollVal = (this.currentDateView.next.month - 1) * 34;
        this.monthInputs.forEach(element => {
          if (element.nativeElement.className.indexOf('selected') > -1) {
            element.nativeElement.focus();
          }
        });
      } else {
        this.showDropDownYear = !this.showDropDownYear;
        this.showDropDownMonth = false;
        scrollVal = 6 * 34;
        this.yearInputs.forEach(element => {
          if (element.nativeElement.className.indexOf('selected') > -1) {
            element.nativeElement.focus();
          }
        });
      }
      // Main scrollbar belongs to element with class name body.
      if (typeof document.getElementsByClassName(dropdown)[0].scroll !== 'undefined') {
        document.getElementsByClassName(dropdown)[0].scroll({
          top: scrollVal,
          behavior: 'auto'
        });
      } else if (typeof document.getElementsByClassName(dropdown)[0].scrollTop !== 'undefined') {
        document.getElementsByClassName(dropdown)[0].scrollTop = scrollVal;
      }
    }
  }

  // Convert object back to string.
  toDateString(value): string {
    // for instances with date ranges, the this.rangemodel is an array
    if (Array.isArray(value)) {
      return value ?
      `${this.getPadNumber(value[0].day)}/${this.getPadNumber(value[0].month)}/${value[0].year} - ${this.getPadNumber(value[1].day)}/${this.getPadNumber(value[1].month)}/${value[1].year}`
      : '';
    }
    return value ?
      `${this.getPadNumber(value.day)}/${this.getPadNumber(value.month)}/${value.year}` :
      '';
  }

  // Handles focusing away from datepicker.
  private registerListener() {
    this.listener = this.renderer.listen('document', 'click', (event: any) => {
      if ((this.model === null && this.rangeModel === null) || this.dateIsNotInRange(this.model)) {
        this.input.nativeElement.value = '';
      }
      if (!this.el.nativeElement.contains(event.target)) {
        this.showDatePicker = false;
        this.listener();
      }
    });
  }

  // convert Ngbstruct to Ngbdate
  toNgbdate(struct: any) {
    return new NgbDate(struct.year, struct.month, struct.day);
  }

  // Handles date ranges
  onDateRangeSelection(date: any) {
    let parsed = '';

    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
      this.selectingRange.emit({ selectingRange: true, date: this.toDateString(this.fromDate) });
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
      this.showDatePicker = false;
      this.selectingRange.emit({ selectingRange: false, date: this.toDateString(this.toDate) });
    } else {
      this.toDate = null;
      this.fromDate = date;
      this.selectingRange.emit({ selectingRange: true, date: this.toDateString(this.fromDate) });
    }
    this.startDate = this.fromDate;

    // replace model and nativeelement values with parsed value;
    if (this.fromDate) {
      parsed += this.toDateString(this.fromDate);
      this.rangeModel = ''; // set to empty string to prevent display of incomplete range
    }
    if (this.toDate) {
      parsed += ' - ' + this.toDateString(this.toDate);
      this.rangeModel = parsed;
      this.propagateChange(this.rangeModel);
      this.modelChange.emit(this.rangeModel);
      this.renderer.setProperty(this.input.nativeElement, 'value', this.rangeModel);
    }
    this.closedCalendar.emit();
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date);
  }

  private focusDatePicker(value: string): void {
    this.showDatePicker = true;
      this.input.nativeElement.focus();
      this.registerListener();
      if (value !== '') {
        // Bootstrap datepicker directive has not been loaded as child uses ngif. So set default startdate.
        if (!this.dateRange && this.updateDateValue(value) !== null) {
          this.startDate = this.model;
        }
        if (this.dateRange && this.updateDateRangeValue(value) !== null) {
          this.startDate = this.fromDate;
        }
      }
  }

  private handleDatePickerEnterKey(value: string): void {
    // If date is not null navigate to date on calendar.
    if (value !== this.toDateString(this.model) && this.updateDateValue(value) !== null) {
      this.datepicker.navigateTo(this.model);
      if (this.dateRange && this.updateDateRangeValue(value) !== null) {
        this.startDate = this.fromDate;
      }
    } else {
      // No change in date so hide datepicker.
      this.showDatePicker = false;
    }
  }

  private getPadNumber(value): string {
    return isNumber(value) ? padNumber(value) : '';
  }

}
