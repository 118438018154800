import { Component, OnInit, ViewChild } from '@angular/core';
import {
  SelectValue,
  PaginationOptions,
  DatePickerComponent
} from 'proceduralsystem-clientcomponents';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { TypeOfReport } from '../shared/report.enum';
import { ReportService } from '../services/report.service';
import { ScrollTagModel } from 'proceduralsystem-clientcomponents/components/tag-scroll/tag-scroll.model';
import { isEmpty, findIndex } from 'lodash-es';
import { SharedService } from '../services/shared.service';
import { Observable, Subject } from 'rxjs';
import { take, map, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'oir-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.less']
})
export class SearchComponent implements OnInit {
  @ViewChild('dateRangePicker') dateRangePicker: DatePickerComponent;

  public searchForm: UntypedFormGroup;
  public reportTypes: SelectValue<any>[];
  public reportTitles$: Observable<any>;
  public paginationList: any;
  public typeOfReport = TypeOfReport;
  public tagsModel: ScrollTagModel;
  public panelClosed = true;
  public totalReports = 0;
  public currentPage = 1;
  public itemsPerPage = 10;
  public result = false;

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private reportService: ReportService,
    private sharedService: SharedService,
    private fb: UntypedFormBuilder,
    private translateService: TranslateService
  ) {
    this.tagsModel = this.createTagsModel();
  }

  public ngOnInit(): void {
    this.searchForm = this.fb.group({
      title: [],
      dateRange: '',
      reportType: [],
      arrangement: ''
    });

    // Stream to get reportTitles
    this.reportTitles$ = this.reportService.getReportTitles().pipe(
      take(1),
      map(titles =>
        titles.map((title, i) => ({ value: i, title: title.titleName }))
      )
    );

    // Report Type select value
    this.reportTypes = [
      {
        value: 1,
        title: this.translateService.instant(
          'DASHBOARD.PREVIOUS_REPORT_TYPE_BCR_SELECT.VALUE'
        )
      },
      {
        value: 3,
        title: this.translateService.instant(
          'DASHBOARD.PREVIOUS_REPORT_TYPE_OB_SELECT.VALUE'
        )
      },
      {
        value: 2,
        title: this.translateService.instant(
          'DASHBOARD.PREVIOUS_REPORT_TYPE_RR_SELECT.VALUE'
        )
      }
    ];

    this.onSearchByTitleChange();
  }

  /**
   * Function to check panel is opened or closed
   * @param event Event
   */
  public panelToggled(event: any): void {
    this.panelClosed = event;
  }

  /**
   * Function to remove filter tags
   * @param item Item to remove
   */
  public removeTag(item: any): void {
    // If range tag removed
    if (!isEmpty(this.searchForm.controls.dateRange.value)) {
      if (item.key === 'range') {
        const event = new MouseEvent('click');
        this.searchForm.controls.dateRange.setValue(null);
        this.dateRangePicker.clearInput(event);
        this.searchForm.controls.reportType.updateValueAndValidity();
      }
    }

    // If arrangement tag removed
    if (!isEmpty(this.searchForm.controls.arrangement.value)) {
      if (item.key === 'arrangement') {
        this.searchForm.controls.arrangement.setValue(null);
        this.searchForm.controls.arrangement.updateValueAndValidity();
      }
    }

    // If report type tag removed
    if (!isEmpty(this.searchForm.controls.reportType.value)) {
      this.searchForm.controls.reportType.value.forEach((element, i) => {
        if (item.key === element) {
          if (this.searchForm.controls.reportType.value[i] === element) {
            this.searchForm.controls.reportType.value.splice(i, 1);
            this.searchForm.controls.reportType.setValue(
              this.searchForm.controls.reportType.value
            );
            this.searchForm.controls.reportType.updateValueAndValidity();
          }
        }
      });
    }

    // Search reports after tags removed
    this.searchReport();
  }

  /**
   * Function to applly filter tags
   */
  public applyFilter(): void {
    this.panelClosed = true;
    this.tagsModel.tags = [];
    this.getRangePickerTag();
    this.getArrangementTag();
    this.getReportTypeTag();
    this.currentPage = 1;
    this.searchReport();
  }

  /**
   * Function to clear and reset advanced filter
   * @param event Event
   */
  public clearAndReset(event: any): void {
    this.tagsModel = this.createTagsModel();
    this.dateRangePicker.clearInput(event);
    this.searchForm.controls.dateRange.setValue(null);
    this.searchForm.controls.arrangement.setValue([]);
    this.searchForm.controls.reportType.setValue([]);
    this.searchForm.updateValueAndValidity();
    this.searchReport();
  }

  /**
   * Function to define pagination options
   * @param id Pagination id
   */
  public getPaginationOptions(): PaginationOptions {
    return {
      id: 'search-report',
      itemsPerPage: this.itemsPerPage,
      currentPage: this.currentPage,
      totalItems: this.totalReports
    };
  }

  /**
   * Page change event
   * @param pageNumber Page number
   */
  public onPageChange(pageNumber: number): void {
    this.currentPage = pageNumber;
    this.searchReport();
  }

  /**
   * @description Function to calculate and display items count per page
   * @param currentPage Current page number
   * @param itemsPerPage Option to how mutch items to show per page
   * @param totalPages Total items count
   */
  public perPageCounter(): string {
    const start =
      this.currentPage * this.itemsPerPage - (this.itemsPerPage - 1);
    const end = Math.min(start + this.itemsPerPage - 1, this.totalReports);
    return this.translateService.instant('SEARCH_PAGE_COUNTER.TEXT', [
      start,
      end,
      this.totalReports
    ]);
  }

  /**
   * Function to search reports
   */
  public searchReport(): void {
    // Search model
    const searchModel = {
      reportTypeCriteria: !isEmpty(this.searchForm.controls.reportType.value)
        ? this.searchForm.controls.reportType.value
        : null,
      title: !isEmpty(this.searchForm.controls.title.value)
        ? this.searchForm.controls.title.value[0].title
        : null,
      dateFrom: !isEmpty(this.searchForm.controls.dateRange.value)
        ? this.sharedService.convertDateStringToBEAcceptableValue(
            this.searchForm.controls.dateRange.value.split('-')[0]
          )
        : null,
      dateTill: !isEmpty(this.searchForm.controls.dateRange.value)
        ? this.sharedService.convertDateStringToBEAcceptableValue(
            this.searchForm.controls.dateRange.value.split('-')[1]
          )
        : null,
      arrangementText: !isEmpty(this.searchForm.controls.arrangement.value)
        ? this.searchForm.controls.arrangement.value
        : null,
      pageNo: this.currentPage - 1,
      pageSize: this.itemsPerPage
    };

    // Call search report API
    if (!this.isSearchModelEmpty()) {
      this.reportService
        .searchReport(searchModel)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(res => {
          this.updateList(res.reports);
          this.totalReports = res.count;
          this.result = true;
        });
    } else {
      this.paginationList = [];
      this.totalReports = 0;
      this.result = false;
    }
  }

  /**
   * Function to check is search form is valid
   */
  public isFormValid(): boolean {
    return !isEmpty(this.searchForm.controls.reportType.value) ||
      !isEmpty(this.searchForm.controls.title.value) ||
      !isEmpty(this.searchForm.controls.dateRange.value) ||
      !isEmpty(this.searchForm.controls.arrangement.value)
      ? true
      : false;
  }

  /**
   * Checking is search model is not empty
   */
  private isSearchModelEmpty(): boolean {
    if (
      isEmpty(this.searchForm.controls.reportType.value) &&
      isEmpty(this.searchForm.controls.title.value) &&
      isEmpty(this.searchForm.controls.dateRange.value) &&
      isEmpty(this.searchForm.controls.arrangement.value)
    ) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Function to clear search result if title is empty
   */
  private onSearchByTitleChange(): void {
    this.searchForm.controls.title.valueChanges.subscribe(val => {
      if (isEmpty(val) && this.isSearchModelEmpty()) {
        this.searchReport();
      }
      if (isEmpty(val) && !this.isSearchModelEmpty()) {
        this.searchReport();
      }
    });
  }

  /**
   * Function to create tag model
   */
  private createTagsModel(): ScrollTagModel {
    return { tags: [] };
  }

  /**
   * Function to get range picker values and add them as tags
   */
  private getRangePickerTag(): void {
    if (!isEmpty(this.searchForm.controls.dateRange.value)) {
      const dateRangeIndex = findIndex(this.tagsModel.tags, ['key', 'range']);
      if (dateRangeIndex > -1) {
        this.tagsModel.tags[dateRangeIndex].title =
          this.searchForm.controls.dateRange.value;
      } else {
        this.tagsModel.tags.push({
          title: this.searchForm.controls.dateRange.value,
          key: 'range',
          readonly: false
        });
      }
    }
  }

  /**
   * Function to get arrangments and apply them as tags
   */
  private getArrangementTag(): void {
    if (!isEmpty(this.searchForm.controls.arrangement.value)) {
      const arrangrementIndex = findIndex(this.tagsModel.tags, [
        'key',
        'arrangement'
      ]);
      if (arrangrementIndex > -1) {
        this.tagsModel.tags[arrangrementIndex].title =
          this.searchForm.controls.arrangement.value;
      } else {
        this.tagsModel.tags.push({
          title: this.searchForm.controls.arrangement.value,
          key: 'arrangement',
          readonly: false
        });
      }
    }
  }

  /**
   * Function to get report type and apply them as tags
   */
  private getReportTypeTag(): void {
    if (!isEmpty(this.searchForm.controls.reportType.value)) {
      this.searchForm.controls.reportType.value.forEach(element => {
        const typeIndex = findIndex(this.tagsModel.tags, ['key', element]);
        if (typeIndex > -1) {
          this.tagsModel.tags[typeIndex].title = this.typeOfReport[element];
        } else {
          this.tagsModel.tags.push({
            title: this.typeOfReport[element],
            key: element,
            readonly: false
          });
        }
      });
    }
  }

  /**
   * Function to update pagination list
   * @param reportData Pagination data
   */
  private updateList(reportData: any): void {
    this.paginationList = [];
    if (reportData && reportData.length > 0) {
      for (const recievedReports of reportData) {
        this.paginationList.push(recievedReports);
      }
    }
  }
}
