import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppConfigService } from './services/app-config.service';
import { MsalService } from '@azure/msal-angular';
import { take } from 'rxjs';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent implements OnInit {
  constructor(
    private readonly config: AppConfigService,
    private authService: MsalService,
    private readonly translate: TranslateService
  ) {
    this.authService.instance.initialize().then(() => {
      this.authService.instance.handleRedirectPromise().then((tokenResponse) => {
        if (tokenResponse) {
          this.authService.instance.setActiveAccount(this.authService.instance.getAllAccounts()[0]);
        } else {
          this.authService.acquireTokenSilent({
            scopes: this.config.getValue('ApiEndpoint').scopes
          }).pipe(take(1)).subscribe(() => {
            this.authService.instance.setActiveAccount(this.authService.instance.getAllAccounts()[0]);
          });
        }
      });
    });
  }

  ngOnInit(): void {
    this.showMessage('BCR application started');
    this.showMessage(`v. ${this.config.getValue('VersionNumber')}`);

    this.translate.addLangs(['en', 'ga']);
    this.translate.use('en');
  }

  private showMessage(message: string): void {
    console.log(
      `%c${message}`,
      'color: #b39841; font-family: Arial; font-size: 1.5em; font-weight: bold;'
    );
  }
}
