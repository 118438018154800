import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  MonitoringService,
  NavigationService,
  OirAuthService
} from 'proceduralsystem-clientcomponents';
import { forkJoin } from 'rxjs';
import { filter, map, mergeMap, take, tap } from 'rxjs/operators';
import { AppConfigService } from './services/app-config.service';
import { UserRole } from './shared/globals';
import { MsalService } from '@azure/msal-angular';
import { ReportStoreService } from './services/report-store.service';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.less']
})
export class PageComponent implements OnInit {
  safPanelExists = false;

  constructor(
    private titleService: Title,
    private configuration: AppConfigService,
    private navigationService: NavigationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private monitoringService: MonitoringService,
    private oirAuthService: OirAuthService,
    private authService: MsalService,
    private reportStore: ReportStoreService
  ) {

    this.reportStore.reportData$.pipe(
      tap(report => {
        if (!!report !== this.safPanelExists) {
          this.safPanelExists = !this.safPanelExists;
        }
      })
    ).subscribe();
  }

  ngOnInit(): void {

    forkJoin([
      this.configuration.get('UserName'),
      this.configuration.get('UserRoleMemberId'),
      this.configuration.get('ModuleName'),
      this.configuration.get('AppInsightsKey')
    ])
      .pipe(take(1))
      .subscribe(([userName, _roleId, moduleName, appInsightsKey]) => {
        this.monitoringService.init(appInsightsKey, userName);

        if (this.hasRole) {
          this.navigationService.init({
            module: { title: moduleName, path: '' },
            homeUrl: '/create-report',
            tree: [
              { title: 'Dashboard', path: '/dashboard' },
              { title: 'Search', path: '/search' },
              { title: 'Create report', path: '/create-report' }
            ]
          });

          this.listenRouterEvents();
        }
      });
  }

  get hasRole(): boolean {
    return this.oirAuthService.hasRole(UserRole.journalOffice);
  }

  get hasAccess(): boolean {
    return this.authService.instance.getActiveAccount() !== null;
  }

  changeOfRoutes() {
    setTimeout(() => {
      this.safPanelExists =
        document.querySelector('.saf') !== null;
    }, 100);
  }

  private listenRouterEvents(): void {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }

          return route;
        }),
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data)
      )
      .subscribe(event => {
        this.titleService.setTitle(event.title);
      });
  }
}
