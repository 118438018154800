import { CKEditorToolbarItems, OirCkEditorConfig } from "proceduralsystem-ckeditor";

interface localCKEditorConfig extends OirCkEditorConfig {
  toolbar: {
    items: Array<CKEditorToolbarItems | string>
  };
  fontSize: {
    options: Array<number | string>
  } | undefined;
  title: string | undefined;
}

export const EditorConfig: localCKEditorConfig = {
    toolbar: {
      items: [
        CKEditorToolbarItems.Bold,
        CKEditorToolbarItems.Italic,
        CKEditorToolbarItems.Underline,
        CKEditorToolbarItems.Strikethrough,
        CKEditorToolbarItems.SEPARATOR,
        CKEditorToolbarItems.NumberedList,
        CKEditorToolbarItems.BulletedList,
        CKEditorToolbarItems.SEPARATOR,
        CKEditorToolbarItems.Outdent,
        CKEditorToolbarItems.Indent,
        CKEditorToolbarItems.SEPARATOR,
        CKEditorToolbarItems.Alignment,
        CKEditorToolbarItems.SEPARATOR,
        CKEditorToolbarItems.Heading,
        CKEditorToolbarItems.SEPARATOR,
        CKEditorToolbarItems.FontFamily,
        CKEditorToolbarItems.FontSize,
        CKEditorToolbarItems.SEPARATOR,
        CKEditorToolbarItems.SpecialCharacters,
        CKEditorToolbarItems.SEPARATOR,
        CKEditorToolbarItems.FontColor,
        CKEditorToolbarItems.SEPARATOR,
        CKEditorToolbarItems.FullScreen,
        CKEditorToolbarItems.SEPARATOR,
        CKEditorToolbarItems.Table,
        CKEditorToolbarItems.RemoveFormat
      ]
    },
    fontSize: {
      options: ['default', 8, 9, 10, 111, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36, 48, 72]
    },
    removePlugins: ['Title', 'Markdown', 'Image', 'ImageToolbar', 'ImageCaption', 'ImageStyle', 'ImageResize', 'LinkImage', 'Heading'],
    placeholder: '',
    title: '',
    licenseKey: ''
}

export interface OirCkEditorInstance {
  elementRef: {
      nativeElement: HTMLElement
  }
}