import {
  Component,
  OnInit,
  AfterViewInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  ViewChild,
  ViewChildren,
  QueryList
} from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { TypeaheadValue } from 'proceduralsystem-clientcomponents';
import { Subscription, Observable, Subject } from 'rxjs';
import {
  map,
  filter,
  takeUntil,
  debounceTime,
  distinctUntilChanged
} from 'rxjs/operators';
import { ReportService } from 'src/app/services/report.service';
import { ReportStoreService } from 'src/app/services/report-store.service';
import { SharedService } from 'src/app/services/shared.service';
import { isEmpty, isArray, has, findIndex, cloneDeep, findLast } from 'lodash-es';
import * as moment from 'moment';
import { GLOBALS } from 'src/app/shared/globals';
import {
  BusinessDaySectionHeader,
  Party,
  TranslationModalDetails,
  TranslationWithdrawModalDetails,
  WorkItem
} from 'src/app/shared/models/report.model';
import { FormTemplate, ReportStatuses, TitleTranslationStatuses, WorkItemTypes } from 'src/app/shared/report.enum';
import { WorkItemFormComponent } from 'src/app/shared/components/work-item-form/work-item-form.component';
import { WorkItemInstanceComponent } from 'src/app/shared/components/work-item-instance/work-item-instance.component';
import { ActivatedRoute, Router } from '@angular/router';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MoveSectionModalComponent } from '../modals/move-section-modal/move-section-modal.component';
import { RequestTitleTranslationModalComponent } from '../modals/request-title-translation-modal/request-title-translation-modal.component';
import OirCkEditor from 'proceduralsystem-ckeditor';
import { OirCkEditorConfig } from 'proceduralsystem-ckeditor';
import { EditorConfig } from 'src/app/shared/models/editor-config.model';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular/ckeditor.component';
import { AppConfigService } from 'src/app/services/app-config.service';

@Component({
  selector: 'oir-sections-form',
  templateUrl: './sections-form.component.html',
  styleUrls: ['./sections-form.component.less']
})
export class SectionsFormComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() businessDaySectionHeader: BusinessDaySectionHeader;
  @Input() index: number;
  @Input() bsDayDate: any;
  @Input() disableSection: boolean;

  @Output() public sectionTimeChanged: EventEmitter<any> =
    new EventEmitter<any>();
  @Output() public toggleAccordion: EventEmitter<any> = new EventEmitter<any>();
  @Output() public workItemsUpdated: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('moveSectionModalComponent') moveSectionModalComponent: MoveSectionModalComponent;
  @ViewChild('titleTranslationModal') titleTranslationModal: RequestTitleTranslationModalComponent;
  @ViewChild('workItemsForm') workItemsForm: WorkItemFormComponent;
  @ViewChildren('workItemsInstance')
  workItemsInstance: QueryList<WorkItemInstanceComponent>;

  public partiesData$: Observable<Party[]>;
  public arrangementData: TypeaheadValue<any>[] = [];
  public membersData$: Observable<any>;
  public departmentData$: Observable<any>;
  public sectionForm: UntypedFormGroup;
  public sectionData: any;
  public hasWorkItems = false;
  public workItemsDirty = false
  public get arrangementsFormArray(): UntypedFormArray {
    return this.sectionForm.get('sectionArrangementFormGroup') as UntypedFormArray;
  }
  public FormTemplate = FormTemplate;
  public addNewItems = true;
  public keyUp = new Subject<{ id: number; value: string }>();
 
  Editor = OirCkEditor;
  ckEditorConfig: OirCkEditorConfig;

  public canMoveSections = this.reportStatusId
    && [ReportStatuses.Draft, ReportStatuses.Circulated, ReportStatuses.Update].includes(this.reportStatusId);
  public isUnsavedDay = false;
  public canRequestTitleTranslation = false;
  public isSaveRequiredBeforeTranslation = false;
  public TranslationStatuses = TitleTranslationStatuses;

  private subscription = new Subscription();
  private ngUnsubscribe = new Subject<void>();
  private departmentMultiselectLookup = [];

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private reportService: ReportService,
    private reportStore: ReportStoreService,
    private sharedService: SharedService,
    private router: Router,
    private configurationService: AppConfigService
  ) {
    this.ckEditorConfig = {
      ...EditorConfig,
      licenseKey: this.configurationService.getValue('CKEditor5LicenseKey')
    }
  }

  ngOnInit(): void {
    // Section form init
    this.sectionForm = this.fb.group({
      parties: [{ value: [], disabled: true }, Validators.required],
      department: [{ value: [], disabled: true }, Validators.required],
      isStartTimeFixed: false,
      isHiddenOnDailBusiness: false,
      sectionStartTime: [{ value: '', disabled: true }],
      sectionEndTime: [
        '',
        [
          Validators.required,
          Validators.pattern('^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$')
        ]
      ],
      sectionDuration: [
        '',
        [
          Validators.required,
          Validators.pattern('^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$')
        ]
      ],
      sectionDetails: '',
      sectionDetailsFreeTextBox: [{ value: '', disabled: true }],
      nextWeeksBusiness: [{ value: '', disabled: true }],
      nextWeeksBusinessFreeTextBox: [{ value: '', disabled: true }],
      sectionArrangements: '',
      sectionArrangementFormGroup: this.fb.array([]),
      arrangementsProposedTitle: [
        '',
        [Validators.required, Validators.maxLength(1000)]
      ]
    });

    if (this.businessDaySectionHeader.businessDaySectionType.bdSectionTypeId === FormTemplate.custom) {
      // Custom item title translation
      this.sectionForm.addControl('customSectionTitles', this.fb.group({
        sectionTitleEn: new UntypedFormControl({value: '', disabled: true }, Validators.maxLength(250)),
        sectionTitleGa: new UntypedFormControl({value: '', disabled: true }, Validators.maxLength(250)),
      }));
      this.titleChange();
    }

    // Init form based on template
    this.sharedService.showOrHideFormFieldsBasedOnTemplate(
      this.sectionForm,
      this.businessDaySectionHeader.businessDaySectionType.bdSectionTypeId
    );

    // Get parties data
    this.partiesData$ = this.reportService.party.pipe(takeUntil(this.ngUnsubscribe));

    // Get department data
    this.departmentData$ = this.reportService.departments.pipe(
      takeUntil(this.ngUnsubscribe),
      map(departments =>
        departments.map(department => {
          const departmentObj = {
            departmentId: department.departmentId,
            departmentName: department.departmentName
          };
          this.departmentMultiselectLookup.push(departmentObj);
          return {
            value: department.departmentId,
            title: department.departmentName
          };
        })
      )
    );

    // Get arrangement data
    this.reportService.sectionArrangement
      .pipe(
        takeUntil(this.ngUnsubscribe),
        map(arrangements =>
          arrangements.map((arrangement, i) => ({
            value: i,
            title: arrangement
          }))
        )
      )
      .subscribe(res => (this.arrangementData = res));

    // Get members data
    this.membersData$ = this.reportService.members.pipe(
      takeUntil(this.ngUnsubscribe),
      map(members =>
        members.map(member => ({ value: member.id, title: member.name }))
      )
    );

    const startDuration = this.sectionForm.get('sectionStartTime').value;
    const endDuration = this.sectionForm.get('sectionEndTime').value;

    if (startDuration && endDuration) {
      this.durationTime(startDuration, endDuration);
    }
    this.showSectionDetails();
    this.showNextWeeksBusiness();
    this.subscribeToFormValueChange();
    this.durationTimeChange();
    this.durationChange();
  }

  public ngAfterViewInit(): void {
    const documentId = this.route.snapshot.paramMap.get('documentId');
    this.onArrangementToggle();
    const itemExsist = setInterval(() => {
      if (this.workItemsInstance) {
        this.route.queryParams
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(params => {
            // If params exsist
            if (!isEmpty(params)) {
              this.workItemsInstance.toArray().forEach(item => {
                if (item.workItemId === parseInt(params.workItemId, 10)) {
                  item.onEdit();
                  this.router.navigate(
                    [`/bcr-report/${documentId}`],
                    { queryParams: {} }
                  );
                  clearInterval(itemExsist);
                }
              });
              this.toggleAddWorkItem(true);
            }
          });
      }
    }, 100);
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  /**
   * Method to get data for Arrangement input
   */
  public getArrangementData(): TypeaheadValue<any>[] {
    return this.sharedService.filterTypeAheadArrayForNoPillsInputTypeAheadBasedOnTitle(
      this.arrangementsFormArray,
      'arrangements',
      this.arrangementData
    );
  }

  /**
   * Function to populate section form
   * @param data Section form data
   */
  public populateForm(data: any): void {
    if (!isEmpty(data)) {
      this.clearArrangementsForm();
      this.sectionData = data;
      this.enableWorkItemSection();
      this.patchFormValues(data);
      this.setDurationOnChange(data);
      this.populateArrangementForm();
      this.reportStore.setSectionFormValidity(
        this.workItemsAreValid(),
        this.bsDayDate,
        this.businessDaySectionHeader.bdSectionId,
        false
      );
      this.sectionForm.markAsPristine();
      this.workItemsDirty = false;
      this.isUnsavedDay = !data.businessScheduleDayId;
    }
  }

  /**
   * Function to get form controls
   */
  public getForm(): Array<any> {
    return this.arrangementsFormArray.controls;
  }

  /**
   * Function to save section data
   */
  public saveSection(skipToggleAccordion = false): void {
    // Function to store section data
    this.reportStore.storeSectionData(
      this.businessDaySectionHeader.businessDaySectionPositionNo,
      this.sharedService.cleanObjectNullValue(this.sectionToSave),
      this.businessDaySectionHeader.businessDaySectionType.bdSectionTypeId,
      this.bsDayDate
    );

    if (!skipToggleAccordion) {
      this.toggleAccordion.emit(true);
    }

    this.reportStore.setSectionFormValidity(
      this.workItemsAreValid(),
      this.bsDayDate,
      this.businessDaySectionHeader.bdSectionId,
      true
    );
  }

  public moveSection(toDuplicate = false): void {
    this.moveSectionModalComponent.toDuplicate = toDuplicate;
    this.moveSectionModalComponent.toggle();
  }

  public onArrangementChange(id: number, value: TypeaheadValue<any>) {
    if (value) {
      this.keyUp.next({ id, value: value.title });
    }
  }

  public onNoArrangementResults(id: number, value: string): void {
    const arrangementFormGroup = this.arrangementsFormArray.controls[
      id
    ] as UntypedFormGroup;
    const arrangementFormControl = arrangementFormGroup.get('arrangements');
    if (arrangementFormControl) {
      arrangementFormControl.setValue(value);
    }
  }

  /**
   * Close section accordion on cancel
   */
  public sectionCancel(): void {
    // Need to timeout to allow change detection to work for canToggle
    setTimeout(() => {
      this.toggleAccordion.emit(true);
    }, 1);
  }

  /**
   * Function to check is value empty
   * @param value Value
   */
  public hasValue(value): boolean {
    return !isEmpty(value);
  }

  /**
   * Method to handle adding new work item
   * @param data
   */
  public onAddWorkItem(data: any): void {
    if (!this.sectionData.workItems) {
      this.sectionData.workItems = [];
    }

    const workItem = this.getWorkItemValueFromWorkItemForm(data);
    this.sectionData.workItems.push(workItem);
    this.workItemsDirty = true;
  }

  /**
   * Method to handle updating work item
   * @param data
   */
  public onUpdateWorkItem(data: any): void {
    const workItem = this.getWorkItemValueFromWorkItemForm(data.formValue);
    if (+data.index > -1) {
      this.sectionData.workItems[data.index] = workItem;
      this.workItemsDirty = true;
    }
  }

  /**
   * Method to handle removing work item
   * @param index
   */
  public onRemoveWorkItem(index: number): void {
    if (+index > -1) {
      this.sectionData.workItems.splice(index, 1);
      this.workItemsDirty = true;
    }
  }

  /**
   * Method to extract work item values from work item form value
   * @param data
   */
  private getWorkItemValueFromWorkItemForm(data: any): WorkItem {
    const isWorkItemOutstanding =
      data.form.workItemBillOrMotion[0].value === GLOBALS.workItemOtherValue;
      let billSponsors = [];
      const isWorkItemTypeBillWithSponsors = data.form.billSponsors &&
        data.form.workItemType === WorkItemTypes.bills;
  
      if (isWorkItemTypeBillWithSponsors) {
        billSponsors = this.businessDaySectionHeader.businessDaySectionType.bdSectionTypeId ===
          FormTemplate.governmentBusiness
          ? data.form.billSponsors
          : data.form.billSponsors.split(', ').map(x => +x);
      }

    const index = findIndex(data.workItemDataWithTags, [
      'value',
      data.form.workItemBillOrMotion[0].value
    ]);
    return {
      workItemId: isWorkItemOutstanding
        ? null
        : data.form.workItemBillOrMotion[0].value,
      workItemTitles:
        index !== -1 ? [data.workItemDataWithTags[index].title] : [],
      businessDaySectionWorkItemPlaceholder: isWorkItemOutstanding
        ? data.form.businessDaySectionWorkItemPlaceholder
        : null,
      isWorkItemOutstanding,
      motionSponsors:
        data.form.motionSponsors &&
        data.form.workItemType === WorkItemTypes.motions
          ? data.form.motionSponsors
          : [],
      billSponsors,
      billStagesToConsider:
        data.form.workItemType === WorkItemTypes.bills && data.form.billStages
          ? data.form.billStages
          : null,
      workItemTypeInfo:
        this.sharedService.getWorkItemTypeInfoValueFromWorkItemForm(
          data.form.workItemType
        ),
      arrangementOfWorkItems:
        this.sharedService.getArrangementDataFromWorkItemForm(data.form),
      arrangementOfWorkItemProposal: data.form.proposalTitle || null,
      businessDaySectionWorkItemPositionNo: 0,
      ...(this.isDeferredDivisions
        && data.form.amendmentType
        && {amendmentType: data.form.amendmentType})
    };
  }

  /**
   * Function to collect section arrangements
   */
  private collectArrangementData(): any[] {
    this.sectionData.arrangementOfSections = [];
    this.arrangementsFormArray.controls.forEach(control => {
      if (control.get('arrangements').value.length > 0) {
        const obj = {
          arrangementOfSectionId:
            control.get('arrangementOfSectionId').value || 0,
          arrangementOfSectionDescription: control.get('arrangements').value,
          isDissentingMembersAssigned: control.get('dissentMembersBox').value,
          isArrangementAppearOnFront: true,
          dissentingMembers: control.get('dissentMembers').value.map(item => {
            return {
              id: item.value,
              name: item.title
            };
          })
        };
        this.sectionData.arrangementOfSections.push(obj);
      }
    });
    return this.sectionData.arrangementOfSections;
  }

  getDepartmentId(sectionData) {
    return sectionData.departments
      ? sectionData.departments.map(item => item.departmentId)
      : null
  }

  getParties(sectionData) {
    return sectionData.partiesToAskQuestions || null
  }

  getSectionStartTime(sectionData) {
    return sectionData.bsSectionStartTime
      ? moment(
        this.sharedService.convertDate(sectionData.bsSectionStartTime)
      )
        .utc()
        .format('HH:mm')
      : ''
  }

  getSectionEndTime(sectionData) {
    return sectionData.bsSectionEndTime
      ? moment(this.sharedService.convertDate(sectionData.bsSectionEndTime))
        .utc()
        .format('HH:mm')
      : ''
  }

  getIsHiddenOnDailBusiness(sectionData) {
    return sectionData.isVisibleOnDailBusiness !== undefined && !sectionData.isVisibleOnDailBusiness
  }

  getSectionDetailsFreeTextBox(sectionData) {
    return sectionData.businessDaySectionNote
      ? sectionData.businessDaySectionNote
      : null
  }

  getSectionArrangements(sectionData) {
    return !has(sectionData, 'isSectionArrangementUsed') &&
      sectionData.businessDaySectionTypeId === FormTemplate.motions
      ? true
      : this.sharedService.convertNumberToBoolean(
        sectionData.isSectionArrangementUsed
      )
  }

  getArrangementsProposedTitle(sectionData) {
    return sectionData.businessScheduleArrangementProposal ||
      sectionData.businessDaySectionTitle ||
      sectionData.businessDaySectionTitleGle
  }

  getDisableTitleInputs(sectionData) {
    return sectionData.translation
      && sectionData.translation.statusId === this.TranslationStatuses.InTranslation;
  }

  /**
   * Function to populate form with recived values
   * @param sectionData Recieved section data
   */
  private patchFormValues(sectionData: any): void {
    this.sectionForm.patchValue(
      {
        department: this.getDepartmentId(sectionData),
        parties: this.getParties(sectionData),
        sectionStartTime: this.getSectionStartTime(sectionData),
        sectionEndTime: this.getSectionEndTime(sectionData),
        isStartTimeFixed: sectionData.isStartTimeFixed,
        isHiddenOnDailBusiness: this.getIsHiddenOnDailBusiness(sectionData),
        sectionDetails: this.sharedService.convertNumberToBoolean(
          sectionData.isSectionNoteUsed
        ),
        sectionDetailsFreeTextBox: this.getSectionDetailsFreeTextBox(sectionData),
        nextWeeksBusiness: sectionData.isSectionNextWeekBusinessUsed,
        nextWeeksBusinessFreeTextBox: sectionData.sectionDetailNextWeekBusiness,
        sectionArrangements: this.getSectionArrangements(sectionData),
        arrangementsProposedTitle: this.getArrangementsProposedTitle(sectionData)

      },
      { emitEvent: false }
    );

    // Custom item title translation
    const sectionTitlesFormGroup = this.sectionForm.get('customSectionTitles');
    
    if (sectionTitlesFormGroup) {
      const disableTitleInputs = this.getDisableTitleInputs(sectionData);

      if (typeof sectionData.businessDaySectionTitle === 'string') {
        sectionTitlesFormGroup.get('sectionTitleEn').patchValue(
          sectionData.businessDaySectionTitle,
          { emitEvent: false }
        );

        if (disableTitleInputs) {
          sectionTitlesFormGroup.get('sectionTitleEn').disable();
        } else {
          sectionTitlesFormGroup.get('sectionTitleEn').enable();
        }
      }

      if (typeof sectionData.businessDaySectionTitleGle === 'string') {
        sectionTitlesFormGroup.get('sectionTitleGa').patchValue(
          sectionData.businessDaySectionTitleGle,
          { emitEvent: false }
        );

        if (disableTitleInputs) {
          sectionTitlesFormGroup.get('sectionTitleGa').disable();
        } else {
          sectionTitlesFormGroup.get('sectionTitleGa').enable();
        }
      }
    }
  }

  /**
   * Function to set duration time on form load
   * @param sectionData Recived section data
   */
  private setDurationOnChange(sectionData: any): void {
    if (sectionData.bsSectionStartTime && sectionData.bsSectionEndTime) {
      const startTime = moment(
        this.sharedService.convertDate(sectionData.bsSectionStartTime)
      )
        .utc()
        .format('HH:mm');
      const endTime = moment(
        this.sharedService.convertDate(sectionData.bsSectionEndTime)
      )
        .utc()
        .format('HH:mm');
      this.durationTime(startTime, endTime);
    }
  }

  /**
   * Function to show section detail on toggle
   */
  private showSectionDetails(): void {
    this.subscription.add(
      this.sectionForm.get('sectionDetails').valueChanges.subscribe(val => {
        if (val) {
          this.sectionForm.get('sectionDetailsFreeTextBox').enable();
          this.sectionForm
            .get('sectionDetailsFreeTextBox')
            .setValidators([Validators.required, Validators.maxLength(500)]);
        } else {
          this.sectionForm.get('sectionDetailsFreeTextBox').disable();
          this.sectionForm.get('sectionDetailsFreeTextBox').setValue(null);
          this.sectionForm.get('sectionDetailsFreeTextBox').clearValidators();
        }
        this.sectionForm
          .get('sectionDetailsFreeTextBox')
          .updateValueAndValidity();
      })
    );
  }

  /**
   * Function to show section next weeks business on toggle
   */
  private showNextWeeksBusiness(): void {
    this.subscription.add(
      this.sectionForm.get('nextWeeksBusiness').valueChanges.subscribe(val => {
        if (val) {
          this.sectionForm.get('nextWeeksBusinessFreeTextBox').enable();
          this.sectionForm
            .get('nextWeeksBusinessFreeTextBox')
            .setValidators([Validators.required, Validators.maxLength(1000)]);
        } else {
          this.sectionForm.get('nextWeeksBusinessFreeTextBox').disable();
          this.sectionForm.get('nextWeeksBusinessFreeTextBox').setValue(null);
          this.sectionForm
            .get('nextWeeksBusinessFreeTextBox')
            .clearValidators();
        }
        this.sectionForm
          .get('nextWeeksBusinessFreeTextBox')
          .updateValueAndValidity();
      })
    );
  }

  /**
   * Function to disable end time field for specified sections
   * @param sectionTypeId section type id
   */
  private disableEndTime(sectionTypeId: number | string): void {
    switch (sectionTypeId) {
      case FormTemplate.privateNoticeQuestion:
        this.sectionForm.get('sectionEndTime').disable();
        break;
      case FormTemplate.motions:
        this.sectionForm.get('sectionEndTime').disable();
        break;
      default:
        this.sectionForm.get('sectionEndTime').enable();
        break;
    }
  }

  /**
   * Function to calculate duration time
   */
  public durationTimeChange(): void {
    this.subscription.add(
      this.sectionForm.get('sectionEndTime').valueChanges.subscribe(val => {
        if (val && val !== 'Invalid date') {
          const timeFormat = /^(0\d|1\d|2[0-3]):[0-5]\d$/;
          const startTime = this.sectionForm.get('sectionStartTime').value;
          if (timeFormat.test(val) && startTime) {
            if (!this.sharedService.isStartTimeBeforeEndTime(startTime, val)) {
              this.sectionForm
                .get('sectionEndTime')
                .setErrors({ invalid: true });
              this.sectionForm.updateValueAndValidity();
            } else {
              this.durationTime(startTime, val);
            }
          }
        } else {
          this.sectionForm.patchValue(
            {
              sectionDuration: null
            },
            { emitEvent: false }
          );
        }
      })
    );
  }

  /**
   * Function to calculate end time on duration change
   */
  public durationChange(): void {
    this.subscription.add(
      this.sectionForm.get('sectionDuration').valueChanges.subscribe(val => {
        if (val && val !== 'Invalid date') {
          const timeFormat = /^(0\d|1\d|2[0-3]):[0-5]\d$/;
          const startTime = this.sectionForm.get('sectionStartTime').value;
          if (timeFormat.test(val) && startTime) {
            const hoursToMinutes = moment.duration(val).asMinutes();
            const minutesToAdd = moment(startTime, 'HH:mm')
              .add(hoursToMinutes, 'minutes')
              .format('HH:mm');
            this.sectionForm.patchValue(
              {
                sectionEndTime: minutesToAdd
              },
              { emitEvent: false }
            );
          }
        } else {
          this.sectionForm.patchValue(
            {
              sectionEndTime: null
            },
            { emitEvent: false }
          );
        }
      })
    );
  }

  /**
   * Function to adjust dependent custom title inputs states
   */
  public titleChange(): void {
    this.subscription.add(
      this.sectionForm.get('customSectionTitles').valueChanges
        .pipe(
          debounceTime(500),
          takeUntil(this.ngUnsubscribe)
        )
        .subscribe(() => {
          const enVal = this.sectionForm.get('customSectionTitles').get('sectionTitleEn').value;
          const gaVal = this.sectionForm.get('customSectionTitles').get('sectionTitleGa').value
          this.canRequestTitleTranslation = !!enVal && !gaVal && !this.isUnsavedSection;

          this.isSaveRequiredBeforeTranslation = this.canRequestTitleTranslation
            && (
              (this.sectionData.businessDaySectionTitle || '') !== enVal ||
              (this.sectionData.businessDaySectionTitleGle || '') !== gaVal
            );
        })
    );
  }

  /**
   * Method to check if first arrangement input has value
   */
  public firstArrangementInputHasValue(): boolean {
    const firstArrangement = this.arrangementsFormArray
      .controls[0] as UntypedFormGroup;
    if (
      !isEmpty(firstArrangement) &&
      !isEmpty(firstArrangement.controls.arrangements.value)
    ) {
      this.sectionForm.controls.arrangementsProposedTitle.enable();
    } else {
      this.sectionForm.controls.arrangementsProposedTitle.disable();
    }
    return (
      !isEmpty(firstArrangement) &&
      !isEmpty(firstArrangement.controls.arrangements.value)
    );
  }

  /**
   * Method to check if form is valid
   */
  public isFormValid(): boolean {
    if (this.sectionForm.controls.sectionArrangements.value === true) {
      return (
        (this.sectionForm.valid && this.firstArrangementInputHasValue()) ||
        (this.sectionForm.valid && this.sectionHasArrangements())
      );
    } else {
      return this.sectionForm.valid;
    }
  }

  /**
   * Method to check if has at least one work item (in case requred) and form is valid
   */
  public workItemsAreValid(): boolean {
    return this.hasWorkItems && !this.isDeferredDivisions
      ? ((this.sectionData || {}).workItems || []).length > 0 &&
          this.sectionForm.valid
      : this.sectionForm.valid;
  }

  /**
   * Show or hide add new work item accordion
   */
  public toggleAddWorkItem(event: boolean) {
    this.addNewItems = !event;
  }


  /**
   * Method to handle when workitem is droped
   * @param event - reordered list
   */
  public onDrop(event: CdkDragDrop<WorkItem[]>): void {
    moveItemInArray(
      this.sectionData.workItems,
      event.previousIndex,
      event.currentIndex
    );
    this.updateWorkItemPositions();
    this.workItemsDirty = true;
    this.workItemsUpdated.emit(this.sectionData.workItems);
  }

  public sectionCopyCurrentBcr(bsDayDate: string): void {
    // Timeout required to make sure all section details were updated correctly.
    setTimeout(() => {
      const sectionToSave = cloneDeep(this.sectionToSave);
      const bsDay = this.reportStore.reportData.businessScheduleDetail.businessScheduleDays
        .find(day => day.bsDayDate === bsDayDate);
      const updatedSection = findLast(bsDay.businessDaySectionHeaders, header => header.bsDaySectionTitle === this.businessDaySectionHeader.bsDaySectionTitle);

      sectionToSave.bsSectionStartTime = updatedSection.bsSectionStartTime;
      sectionToSave.bsSectionEndTime = updatedSection.bsSectionEndTime;
      sectionToSave.businessDaySectionId = 0;
      sectionToSave.businessDaySectionPositionNo = updatedSection.businessDaySectionPositionNo;

      if (sectionToSave.arrangementOfSections && sectionToSave.arrangementOfSections.length) {
        sectionToSave.arrangementOfSections = sectionToSave.arrangementOfSections.map(arr => ({...arr, arrangementOfSectionId: 0}));
      }

      this.reportStore.storeSectionData(
        updatedSection.businessDaySectionPositionNo,
        this.sharedService.cleanObjectNullValue(sectionToSave),
        this.businessDaySectionHeader.businessDaySectionType.bdSectionTypeId,
        bsDayDate
      );
    }, 100);
  }

  public requestTitleTranslation(details: TranslationModalDetails | TranslationWithdrawModalDetails): void {
    const titleTranslationPayload = {
      ...details,
      id: this.businessDaySectionHeader.bdSectionId as number
    };

    if (this.isSaveRequiredBeforeTranslation) {
      this.saveSection(true);
    }

    this.reportService.triggerTitleTranslation(titleTranslationPayload, this.isSaveRequiredBeforeTranslation);

    if (this.titleTranslationModal.isVisible) {
      this.titleTranslationModal.toggle();
    }
  }

  private updateWorkItemPositions() {
    this.sectionData.workItems = this.sectionData.workItems.map((i, index) => ({...i, businessDaySectionWorkItemPositionNo: index}));
  }

  /**
   * Function to check is day instance has arrangements
   */
  private sectionHasArrangements(): boolean {
    const arrangements = this.sectionData.arrangementOfSections;

    return arrangements ? arrangements.length > 0 : false;
  }

  /**
   * Function to calculate duration time
   * @param start Start time
   * @param end End time
   */
  private durationTime(start: string, end: string): void {
    const minutes = moment(end, GLOBALS.timeFormat).diff(
      moment(start, GLOBALS.timeFormat),
      'minutes',
      true
    );
    const durationHours = minutes / 60;
    const durationMinutes = minutes % 60;
    const hours = moment
      .utc()
      .hours(durationHours)
      .minutes(durationMinutes)
      .format(GLOBALS.timeFormat);
    this.sectionForm.patchValue(
      {
        sectionDuration: hours
      },
      { emitEvent: false }
    );
    this.sectionForm.updateValueAndValidity();
  }
  /**
   * Function to populate arrangement form
   */
  private populateArrangementForm(): void {
    if (!isEmpty(this.sectionData.arrangementOfSections)) {
      // Loop try arrangements and populate form
      this.sectionData.arrangementOfSections.forEach((arrangement, i) => {
        this.addArrangementFormControl(
          arrangement.arrangementOfSectionId,
          { value: i, title: arrangement.arrangementOfSectionDescription },
          arrangement.isDissentingMembersAssigned,
          arrangement.dissentingMembers.map(x => ({
            value: x.id,
            title: x.name
          }))
        );
      });
    }
  }

  /**
   * Function to create arrangement form
   * If there params ar passed then patch them
   * @param arrangement Arrangements
   * @param dissentMembersBox Dissenting Memebers checkbox
   * @param dissentMembers Dissenting member list
   */
  private addArrangementFormControl(
    arrangementOfSectionId?: number,
    arrangement?: any,
    dissentMembersBox?: any,
    dissentMembers?: any
  ): void {
    // Creating arrangement form
    const arrangementForm: UntypedFormGroup = this.fb.group({
      arrangementOfSectionId: 0,
      arrangements: [[]],
      dissentMembersBox: false,
      dissentMembers: [[], Validators.required]
    });
    this.arrangementsFormArray.push(arrangementForm);

    // Subscribing to value changes
    this.subscription.add(
      arrangementForm.controls.arrangements.valueChanges
        .pipe(filter(val => !isEmpty(val) && isArray(val) && !isEmpty(val[0])))
        .subscribe(res => {
          arrangementForm.controls.arrangements.patchValue(res[0].title);
        })
    );

    // Show hide fields
    this.sharedService.showFieldIfOtherFieldValueIs(
      this.subscription,
      arrangementForm,
      'dissentMembersBox',
      true,
      'dissentMembers'
    );

    // If arrangemet exist patch value for it
    if (arrangement) {
      arrangementForm.patchValue({
        arrangementOfSectionId,
        arrangements: [arrangement],
        dissentMembersBox,
        dissentMembers
      });
    }
  }

  /**
   * On Arrangement Toggle trigger
   */
  private onArrangementToggle(): void {
    this.subscription.add(
      this.sectionForm.controls.sectionArrangements.valueChanges.subscribe(
        res => {
          if (res) {
            this.addArrangementFormControl();
          } else {
            this.clearArrangementsForm();
          }
        }
      )
    );
  }

  /**
   * Clear arrangement form
   */
  private clearArrangementsForm(): void {
    while (this.arrangementsFormArray.length) {
      this.arrangementsFormArray.removeAt(0);
    }
  }

  /**
   * Subscribe to form changes
   */
  private subscribeToFormValueChange(): void {
    this.keyUp
      .pipe(
        debounceTime(2000),
        distinctUntilChanged(),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(res => {
        const arrangement = this.arrangementsFormArray.controls[
          res.id
        ] as UntypedFormGroup;
        if (isEmpty(arrangement.controls.arrangements.value)) {
          arrangement.controls.arrangements.setValue(
            [{ value: 0, title: res.value }],
            { onlySelf: true, emitEvent: false }
          );
        }
      });
    this.subscription.add(
      this.sectionForm.valueChanges.subscribe(formValue => {
        if (isArray(formValue.sectionArrangementFormGroup)) {
          // Loop through arrangementsFormArray and remove empty input form groups
          formValue.sectionArrangementFormGroup.forEach((element, i) => {
            // Skip last element as it can be empty
            if (
              i < formValue.sectionArrangementFormGroup.length - 1 &&
              isEmpty(element.arrangements)
            ) {
              this.arrangementsFormArray.removeAt(i);
            }
          });

          // If last form group has Arrangement value then add new form group
          if (
            !isEmpty(
              formValue.sectionArrangementFormGroup[
                formValue.sectionArrangementFormGroup.length - 1
              ]
            ) &&
            !isEmpty(
              formValue.sectionArrangementFormGroup[
                formValue.sectionArrangementFormGroup.length - 1
              ].arrangements
            )
          ) {
            this.addArrangementFormControl();
          }
        }
      })
    );
  }

  /**
   * Method to enable work item section
   */
  private enableWorkItemSection(): any {
    this.hasWorkItems = [
      FormTemplate.privateMembersBill,
      FormTemplate.privateMembersBusiness,
      FormTemplate.motions,
      FormTemplate.governmentBusiness,
      FormTemplate.billsForIntroduction,
      FormTemplate.referralToCommittee,
      FormTemplate.deferredDivisions
    ].includes(this.sectionTypeId);
  }

  validateArrangementLength(arrangement: UntypedFormControl, { editor }: ChangeEvent) {
    const value = editor.getData();

    return this.sharedService.validateArrangementLength(arrangement, value);
  }

  get sectionTypeId(): number | null {
    return (((this.sectionData || {}).businessDaySectionType || {})
    .bdSectionTypeId || (this.sectionData || {}).businessDaySectionTypeId) || null;
  }
  
  get isWorkItemLimitReached(): boolean {
    return (this.isBillsForIntroduction
        || this.isDeferredDivisions
        || this.isReferralToCommittee)
      && this.sectionData
      && this.sectionData.workItems
      && this.sectionData.workItems.length >= (this.isDeferredDivisions ? 20 : 10);
  }

  get isBillsForIntroduction(): boolean {
    return this.sectionTypeId === FormTemplate.billsForIntroduction
  }

  get isDeferredDivisions(): boolean {
    return this.sectionTypeId === FormTemplate.deferredDivisions;
  }

  get isReferralToCommittee(): boolean {
    return this.sectionTypeId === FormTemplate.referralToCommittee;
  }

  public get isUnsavedSection(): boolean {
    return this.sectionData && typeof this.sectionData.businessDaySectionId === 'string';
  }

  private get sectionToSave() {
    const startDate =
      moment(this.sharedService.convertDate(this.bsDayDate))
        .utc()
        .format(GLOBALS.dateFormat) +
      ' ' +
      this.sectionForm.get('sectionStartTime').value;
    const endDate =
      moment(this.sharedService.convertDate(this.bsDayDate))
        .utc()
        .format(GLOBALS.dateFormat) +
      ' ' +
      this.sectionForm.get('sectionEndTime').value;
    const englishTitleController = this.sectionForm.get('customSectionTitles')
      ? this.sectionForm.get('customSectionTitles').get('sectionTitleEn')
      : null;
    const irishTitleController = this.sectionForm.get('customSectionTitles')
      ? this.sectionForm.get('customSectionTitles').get('sectionTitleGa')
      : null;

    return {
      departments: this.sharedService.getMultiselectValues(
        this.departmentMultiselectLookup,
        this.sectionForm,
        'department',
        'departmentId'
      ),
      businessDaySectionId: this.businessDaySectionHeader.bdSectionId,
      businessDaySectionTypeId: this.sectionData.businessDaySectionTypeId
        ? this.sectionData.businessDaySectionTypeId
        : null,
      businessDaySectionTitle: englishTitleController
        ? englishTitleController.value || this.sectionData.businessDaySectionTitle
        : this.sectionData.businessDaySectionTitle,
      businessDaySectionTitleGle: irishTitleController && irishTitleController.dirty
        ? irishTitleController.value
        : this.sectionData.businessDaySectionTitleGle,
      partiesToAskQuestions: this.sectionForm.controls.parties.value || null,
      bsSectionStartTime: this.sharedService.convertDate(startDate),
      bsSectionEndTime: this.sharedService.convertDate(endDate),
      businessDaySectionDuration:
        this.sectionForm.get('sectionDuration').value || null,
      businessDaySectionNote: this.sectionForm.get('sectionDetailsFreeTextBox')
        .value,
      businessDaySectionPositionNo:
        this.sectionData.businessDaySectionPositionNo,
      businessScheduleArrangementProposal: this.sectionForm.get(
        'arrangementsProposedTitle'
      ).value,
      isSectionArrangementUsed:
        this.sectionForm.get('sectionArrangements').value === true ? 1 : 0,
      isSectionNoteUsed:
        this.sectionForm.get('sectionDetails').value === true ? 1 : 0,
      isStartTimeFixed: this.sectionForm.get('isStartTimeFixed').value,
      isVisibleOnDailBusiness: !this.sectionForm.get('isHiddenOnDailBusiness').value,
      isSectionNextWeekBusinessUsed:
        this.sectionForm.get('nextWeeksBusiness').value,
      sectionDetailNextWeekBusiness: this.sectionForm.get(
        'nextWeeksBusinessFreeTextBox'
      ).value,
      arrangementOfSections: this.collectArrangementData(),
      workItems: this.sectionData.workItems || null
    };
  }

  private get reportStatusId(): number {
    return this.reportStore.reportData.reportStatusId || 0;
  }
}
