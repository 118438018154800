// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-content {
  padding: 20px 0;
}
.arrangements-top {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.arrangement-members {
  padding: 10px 0;
}
`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
