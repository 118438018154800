import * as Moment from 'moment';
const moment: any = (Moment as any).default || Moment;

export class DatePickerModel {
  public date: Date;
  public dates: Array<Date> = [];
  public dayLabels: Array<string> = [];
}

export class Date {
  public value: string;
  public momentDate: any;
  public day: string;
  public month: string;
  public year: string;
  public active: boolean;
  public disabled: boolean;
  public isValid: boolean;

  constructor(value: string, disabled?: boolean, active?: boolean) {

    this.disabled = disabled;
    this.active = active;

    let formats = ['D/MM/YYYY', 'DD/MM/YYYY', 'D/M/YYYY', 'DD/MM/YYYY'];
    this.isValid = moment(value, ['D/MM/YYYY', 'D/M/YYYY']).isValid();
    if (this.isValid) {
      this.value = moment(value, formats).format('DD/MM/YYYY');
      this.momentDate = moment(value, formats);
      this.day = moment(this.value, formats).format('D');
      this.month = moment(this.value, formats).format('M');
      this.year = moment(this.value, formats).format('YYYY');
    }
  }
}

export class ViewDate {
  public value: string;
  public month: string;
  public monthString: string;
  public year: string;

  constructor(value: string) {
    let formats = ['D/MM/YYYY', 'DD/MM/YYYY', 'D/M/YYYY', 'DD/MM/YYYY'];

    let isValid = moment(value, ['D/MM/YYYY', 'D/M/YYYY']).isValid();
    if (isValid) {
      this.value = moment(value, formats).format('DD/MM/YYYY');
      this.month = moment(this.value, formats).format('MM');
      this.monthString = moment(this.value, formats).format('MMMM');
      this.year = moment(this.value, formats).format('YYYY');
    }
  }
}

export class DatePickerOptions {
  public minDate: string;
  public maxDate: string;
  public readonly: boolean;

  constructor(minDate: string = '', maxDate: string = '', readonly: boolean = false) {
    this.minDate = minDate;
    this.maxDate = maxDate;
    this.readonly = readonly;
  }
}
