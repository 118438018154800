// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.outstanding-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin-left: -20px;
  background-color: #f4f2ea;
  border-bottom: 1px solid #d4cfbe;
}
.outstanding-item:nth-child(2n) {
  background-color: #fff;
}
`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
