// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-content {
  padding: 20px 0;
}
.col-sm-6:nth-of-type(1),
.col-sm-6:nth-of-type(2) {
  padding-right: 15px;
}
.translate-wrapper div {
  margin: 20px 0px;
}
.translate-wrapper div p {
  margin-left: 30px;
}
.translate-wrapper div .translate-placeholder {
  position: absolute;
  right: 0px;
}
`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
