// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select-body-container .options-container .option-container .text {
  margin-left: 0;
}
.select-body-container .options-container .option-container.selected {
  color: #ffffff;
  background-color: #7F6C2E;
}
.select-body-container .options-container .heading.selectable:hover {
  background-color: #7F6C2E !important;
}
.select-body-container .options-container .heading.selectable:hover span {
  color: #ffffff;
}
.increaseZIndex {
  z-index: 11;
}
ngbdropdown {
  outline: 1px solid;
  outline: auto -webkit-focus-ring-color;
  outline-color: #b39841;
  border: none;
}
button {
  min-height: 40px;
  background-color: #ffffff;
  border: 1px solid #d4cfbc;
  padding: 8px 11px 8px 11px;
  font-family: Montserrat-Light;
  font-size: 15px;
  position: relative;
  width: 100%;
  text-align: left;
  border-radius: 0px;
}
.dropdown-menu button {
  border: none;
}
.dropdown-menu .dropdown-item-search:focus,
.dropdown-menu .dropdown-item-search:active {
  border: 1px solid #7F6C2E !important;
  outline: none !important;
  scrollbar-shadow-color: 0px 0px !important;
}
.dropdown-menu .select-all:focus,
.dropdown-menu .select-all:active {
  border: none !important;
  outline: none !important;
  scrollbar-shadow-color: 0px 0px !important;
}
.dropdown-menu .select-all button:focus,
.dropdown-menu .select-all button:active {
  border: none !important;
  outline: none !important;
  scrollbar-shadow-color: 0px 0px !important;
}
.dropdown-item:hover:not(.disabled):not(.select-all),
.dropdown-item:active:not(.disabled):not(.select-all),
.dropdown-item:focus:not(.disabled):not(.select-all) {
  color: #ffffff;
  background-color: #7F6C2E !important;
  width: 100%;
}
.dropdown-item:hover:not(.disabled):not(.select-all) .select-all-btn,
.dropdown-item:active:not(.disabled):not(.select-all) .select-all-btn,
.dropdown-item:focus:not(.disabled):not(.select-all) .select-all-btn {
  color: #ffffff;
}
.dropdown-toggle {
  text-align: left;
  border: 1px solid #d4cfbc;
  outline: none;
  box-shadow: none;
  border-radius: none !important;
  min-height: 40px;
}
.dropdown-menu {
  padding: 0px;
  margin: 0px;
  width: 100%;
  border-radius: 0px;
  border-top: none;
  max-height: 500px;
  overflow-y: scroll;
  margin-bottom: 85px;
  z-index: 11;
}
.dropdown-toggle-placeholder {
  color: #777;
  background-color: #ffffff;
  width: 100%;
  border-radius: 0px !important;
}
.collapse {
  display: block;
  width: 23px;
  height: 23px;
  float: right;
  background-image: url('closed-chev-big.847c1b52d4b4a306.svg');
  min-height: auto;
}
.collapse.active {
  background-image: url('open-chev-big.f05d398ec6e57c90.svg');
}
.collapse:hover {
  cursor: pointer;
}
.clear-input {
  position: absolute;
  z-index: 5;
  bottom: 5px;
  right: 40px;
  height: 24px;
  width: 24px;
  background-image: url('cross.94f7db39b7176d24.svg');
  background-repeat: no-repeat;
  background-position: center;
  visibility: hidden;
}
.clear-input:hover {
  background-image: url('cross-hover.51d99dd21ec0e20c.svg');
  cursor: pointer;
}
button:hover:not(.disabled) {
  cursor: pointer;
}
button:hover:not(.disabled) .clear-input {
  visibility: visible;
}
.dropdown-item {
  margin: 0px;
}
.dropdown-item-search {
  padding-right: 35px;
}
.dropdown-item-search-container .dropdown-item-search {
  width: 100%;
}
.dropdown-item-search-container {
  width: 100%;
  height: 65px;
  padding: 15px 10px 10px;
}
.dropdown-menu .heading {
  color: #b39841;
}
.dropdown-menu .selectable:focus,
.dropdown-menu .selectable:active,
.dropdown-menu .selectable:hover {
  color: white;
}
.checkbox {
  padding-left: 10px;
}
.checkbox .check {
  width: 16px;
  height: 16px;
  background-image: url('off-check.b27a559404abf43a.svg');
  background-position: left;
  background-size: cover;
  position: absolute;
}
.checkbox .check.checked {
  background-image: url('on-check.dcb1a0af428956b0.svg');
}
.checkbox .text:not(.select-all-btn) {
  margin-left: 28px;
}
.checkbox.headingtext .text:not(.select-all-btn) {
  margin-left: 0px;
}
.select-all-text {
  display: block;
  left: 27px;
  position: relative;
  top: -2px;
}
.pills-container {
  margin-top: 4px;
  display: inline-block;
  width: 95%;
}
.pills-container .pill {
  width: auto;
  float: left;
  background-color: #7F6C2E;
  padding: 4px 8px 4px 8px;
  opacity: 1;
  margin-right: 5px;
  transition: opacity 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  min-height: auto;
  white-space: normal;
}
.pills-container .pill span {
  color: #ffffff;
}
.pills-container .pill .remove {
  display: inline-block;
  background-image: url('dropdown-x.d3c3408902547efd.svg');
  background-size: cover;
  width: 15px;
  height: 15px;
  vertical-align: middle;
  margin-bottom: 3px;
  margin-left: 8px;
}
.pills-container .pill.animate {
  opacity: 0;
}
.pills-container .pill:focus,
.pills-container .pill:active,
.pills-container .pill:hover {
  background-color: #f4f2ea;
}
.pills-container .pill:focus span,
.pills-container .pill:active span,
.pills-container .pill:hover span {
  color: #7F6C2E !important;
}
.pills-container .pill:focus .remove,
.pills-container .pill:active .remove,
.pills-container .pill:hover .remove {
  background-image: url('hover-x.51d99dd21ec0e20c.svg');
}
#dropdownMultiSelect {
  padding: 4px 11px 4px 11px;
  width: 100%;
}
#dropdownMultiSelect .placeHolderText {
  width: calc(100% - 23px) !important;
}
.select-all-btn,
.clear-selected {
  color: #b39841;
}
.select-all-btn {
  margin-left: 0px;
  width: 80%;
}
.select-all {
  height: 40px;
}
::ng-deep .dropdown-menu .checkbox .select-all {
  height: 40px;
  padding: 8px 11px 8px 11px;
}
.checkbox button {
  width: unset;
  padding: 0px;
  border: none;
  height: 18px;
}
.clear-selected {
  margin-right: 10px;
  float: right;
  width: auto;
}
.clear-selected:hover,
.clear-selected:active,
.clear-selected:focus {
  color: #685925;
  border: none;
  outline: none;
  box-shadow: 0px 0px;
}
.select-all .select-all-btn:hover,
.select-all .select-all-btn:active,
.select-all .select-all-btn:focus {
  color: #685925;
  border: none;
  outline: none;
  box-shadow: 0px 0px;
}
.chev {
  outline: none;
  border: none;
}
.multi-select-chev {
  top: 4px;
}
.placeHolderText {
  margin-top: 5px;
  display: inline-block;
  color: #777777;
  font-family: Montserrat-Light;
  font-size: 15px;
  width: 140px;
  float: left;
}
.headingtext .text {
  color: #b39841;
  transform: translate(0, 0);
  margin-left: 0px;
}
.headingtext:hover,
.headingtext:active,
.headingtext:focus {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
  cursor: default !important;
}
.dropdown ::ng-deep .dropdown-toggle.disabled {
  cursor: default !important;
}
::ng-deep oir-multiselect.invalid .dropdown-toggle {
  background-color: #ffe5e9 !important;
  border: 1px solid #c30e2e !important;
}
::ng-deep oir-multiselect.invalid .dropdown-toggle .pills-container {
  background-color: #ffe5e9 !important;
  font-family: Montserrat-Light !important;
}
::ng-deep oir-multiselect.invalid .dropdown-toggle .collapse {
  background-color: #ffe5e9 !important;
}
.button-options-container {
  max-height: 435px;
  overflow-y: scroll;
  width: 100%;
}
.remove-scroll {
  max-height: unset !important;
  overflow-y: hidden !important;
}
.button-options-container-with-select {
  max-height: 395px;
  overflow-y: scroll;
  width: 100%;
}
.button-options-container-with-select-no-search {
  max-height: 460px;
  overflow-y: scroll;
  width: 100%;
}
`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
