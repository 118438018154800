import {
  Component,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  OnInit
} from '@angular/core';
import { AnnouncementOfBusinessDays } from 'src/app/shared/models/order-of-business.model';
import { ToggleableAccordionComponent } from 'src/app/shared/components/toggleable-accordion/toggleable-accordion.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'oir-announcement-business-item',
  templateUrl: './announcement-business-item.component.html',
  styleUrls: ['./announcement-business-item.component.less']
})
export class AnnouncementBusinessItemComponent implements OnInit {
  private _announcementOfBusinessDays: AnnouncementOfBusinessDays;

  public canToggle = true;
  public dayTitleGovernment: string;
  public dayTitleBusiness: string;
  public dayTitleBill: string;
  public dayOfTheWeek: string;
  public gbAfterMwd: boolean;

  @Input() set announcementOfBusinessDays(value: AnnouncementOfBusinessDays) {
    this._announcementOfBusinessDays = value;
    if (value) {
      const governmentBusiness = value.governmentBusiness.length
        ? value.governmentBusiness[0].sectionPosition
        : 0;
      const motionsWithoutDebates = value.motionsWithoutDebate.length
        ? value.motionsWithoutDebate[0].sectionPosition
        : 0;
      this.gbAfterMwd = governmentBusiness > motionsWithoutDebates;
      this.dayOfTheWeek = value.dayName.split(' ')[0];
    }
  }

  get announcementOfBusinessDays(): AnnouncementOfBusinessDays {
    return this._announcementOfBusinessDays;
  }

  @Output() toggleAccordion: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('accordionComponent')
  accordionComponent: ToggleableAccordionComponent;

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    this.dayTitleGovernment = this.translateService.instant(
      'ORDER_OF_BUSINESS_GOVERNMENT_BUSINESS.TITLE'
    );
    this.dayTitleBusiness = this.translateService.instant(
      'ORDER_OF_BUSINESS_PRIVATE_MEMBERS_BUSINESS.TITLE'
    );
    this.dayTitleBill = this.translateService.instant(
      'ORDER_OF_BUSINESS_PRIVATE_MEMBERS_BILL.TITLE'
    );
  }

  /**
   * Method to close accordion
   */
  public closeDayAccordionBusinessItem(): void {
    this.canToggle = true;
    setTimeout(() => {
      this.accordionComponent.click();
    }, 1);
  }

  /**
   * Event emmiter after toggle
   * @param isOpen boolean
   */
  public afterToggle(isOpen: boolean) {
    this.toggleAccordion.emit(isOpen);
  }
}
