// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.announcement-days .days-closeBtn {
  display: flex;
  justify-content: flex-end;
  padding: 10px 0;
}
.announcement-days .business-shall {
  padding: 5px 0;
}
`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
