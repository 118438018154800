import { Component, Input, ViewChild, OnChanges, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastNotificationsService, TypeaheadValue } from 'proceduralsystem-clientcomponents';
import { ReportService } from 'src/app/services/report.service';
import { take } from 'rxjs/operators';
import { SharedService } from 'src/app/services/shared.service';
import { CancelConfirmModalComponent } from 'src/app/create-report/cancel-confirm-modal/cancel-confirm-modal.component';
import { CustomModalComponent } from 'src/app/shared/components/custom-modal/custom-modal.component';
import { isEmpty } from 'lodash-es';
import { ReportStoreService } from 'src/app/services/report-store.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'oir-circulate-bcr-modal',
  templateUrl: './circulate-bcr-modal.component.html',
  styleUrls: ['./circulate-bcr-modal.component.less']
})
export class CirculateBcrModalComponent implements OnInit, OnChanges {

  @Input() startBcrTime: string;
  @Input() endBcrTime: string;
  @Input() reportNote: string;
  @Input() report: any;

  @ViewChild('circulateBcrModal') circulateBcrModal: CustomModalComponent;
  @ViewChild('cancelConfirmModalComponent') cancelConfirmModalComponent: CancelConfirmModalComponent;

  public form: UntypedFormGroup;
  public recipientsEmails: TypeaheadValue<any>[] = [];
  public ccEmalis: TypeaheadValue<any>[] = [];
  public bccEmalis: TypeaheadValue<any>[] = [];
  public emailSubjectDefaultValue: string;
  public emailBodyDefaultValue: string;
  public emailBodyRevisedDefaultValue: string;
  public revisedBcrNumber: string;
  public updateBcrNumber: string;
  public action: string;
  public startDate;
  public endDate;
  public newRevisionNumber;
  public newUpdateNumber;

  private defaultEmail = 'journal.office@oireachtas.ie';
  private circulateTriggered = false;

  constructor(
    private reportStoreService: ReportStoreService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private translateService: TranslateService,
    private toastService: ToastNotificationsService,
    private reportService: ReportService,
    private sharedService: SharedService
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      toEmail: [[], Validators.required],
      ccEmail: [],
      bccEmail: [],
      emailSubject: ['', Validators.maxLength(250)],
      emailBody: ['', Validators.maxLength(1000)],
    });
    this.reportService.getMailsList().pipe(take(1)).subscribe(res => {
      res.map((emails) => {
        if (emails.recipientEmail !== null) {
          this.recipientsEmails.push({ value: emails.recipientId, title: emails.recipientEmail });
        }
      });
    });
    // Only temporary for development team.
    this.recipientsEmails = this.sharedService.addDevEmails(this.recipientsEmails);
  }

  ngOnChanges() {
    this.startDate = this.sharedService.convertDate(this.startBcrTime);
    this.endDate = this.sharedService.convertDate(this.endBcrTime);
    if (!isEmpty(this.report)) {
      this.revisedBcrNumber = this.report.reportVerison.toString().split('.')[0];
      this.updateBcrNumber = this.report.reportVerison.toString().split('.')[1];
      this.newRevisionNumber = parseInt(this.revisedBcrNumber, 10) + 1;
      this.newUpdateNumber = '01';
    }
  }

  /**
   * Open the ciruculate modal
   */
  public open(action: string): void {
    this.revisedBcrNumber = this.newRevisionNumber.toString();
    this.updateBcrNumber = this.newUpdateNumber;
    this.createMailText();
    this.action = action;
    this.form.patchValue({
      toEmail: [{ value: 0, title: this.defaultEmail}],
      emailSubject: this.emailSubjectDefaultValue,
      emailBody: this.action === 'circulate' ? this.emailBodyDefaultValue : this.emailBodyRevisedDefaultValue,
    });
    this.circulateBcrModal.toggle();
  }

  /**
   * Circulate BCR modal
   */
  public circulate(): void {
    const report = this.reportStoreService.getReportData();
    report.reportNote = this.reportNote;

    this.reportStoreService.deleteCustomProps();
    const reportId = report.reportId || report.businessScheduleDetail.bsDetailId;

    // Convert dates to BE readable
    this.report.businessScheduleDetail.bsDetailFirstDay =
      this.sharedService.convertDate(this.report.businessScheduleDetail.bsDetailFirstDay);
    this.report.businessScheduleDetail.bsDetailLastDay =
      this.sharedService.convertDate(this.report.businessScheduleDetail.bsDetailLastDay);

    const toEmail = this.form.controls.toEmail.value ? this.sharedService.convertRecipientsToString(this.form.controls.toEmail.value) : '';
    const ccEmail = this.form.controls.ccEmail.value ? this.sharedService.convertRecipientsToString(this.form.controls.ccEmail.value) : '';
    const bccEmail = this.form.controls.bccEmail.value ? this.sharedService.convertRecipientsToString(this.form.controls.bccEmail.value) : '';

    this.circulateTriggered = true;
    this.circulateBcrModal.close();

    if (this.action === 'circulate') {
      this.reportService.circulateReport(
        toEmail, ccEmail, bccEmail,
        this.form.controls.emailSubject.value || '',
        this.form.controls.emailBody.value || '',
        report, reportId).pipe(take(1)).subscribe(() => {
          this.circulateTriggered = false;
          this.toastService.addNotification({
            title: this.translateService.instant('TOAST.BUSINESS_COMMITTEE_REPORT_CIRCULATED.TITLE'),
            description: this.translateService.instant('TOAST.BUSINESS_COMMITTEE_REPORT_CIRCULATED.BODY',
              [this.startBcrTime, this.endBcrTime])});
          this.router.navigate(['/dashboard']);
        });
      return;
    }
    this.reportService.reviseCirculateReport(
      toEmail, ccEmail, bccEmail,
      this.form.controls.emailSubject.value || '',
      this.form.controls.emailBody.value || '',
      report, reportId).pipe(take(1)).subscribe(() => {
        this.circulateTriggered = false;
        this.toastService.addNotification({
          title: this.translateService.instant('TOAST.BUSINESS_COMMITTEE_REPORT_REVISED_CIRCULATED.TITLE'),
          description: this.translateService.instant('TOAST.BUSINESS_COMMITTEE_REPORT_REVISED_CIRCULATED.BODY',
            [this.startBcrTime, this.endBcrTime, this.revisedBcrNumber, this.updateBcrNumber])});
        this.router.navigate(['/dashboard']);
      });
  }

  /**
   * Method to close circulate bcr modal without cirucalting
   */
  public cancelCirculateModal(event: any): void {
    if (event) {
      this.circulateBcrModal.close();
      this.form.reset();
    }
  }
  /**
   * Cancel the ciruculate modal
   */
  public cancel(): void {
    this.cancelConfirmModalComponent.toggle();
  }

  /**
   * Method to close circulate bcr modal without cirucalting
   */
  public onModalClose(): void {
    if (this.circulateTriggered) {
      return;
    }
    this.cancelConfirmModalComponent.toggle();
  }

  /**
   * Function to create mail subject text and body
   */
  private createMailText(): void {
    this.emailSubjectDefaultValue = this.startDate === this.endDate ?
      this.translateService.instant('VIEW_REPORT_SECTION.CIRCULATE_EMAIL.SUBJECT.DEFAULT_SINGLE_DAY_VALUE',
        [this.startBcrTime, this.revisedBcrNumber, this.updateBcrNumber]) :
      this.translateService.instant('VIEW_REPORT_SECTION.CIRCULATE_EMAIL.SUBJECT.DEFAULT_VALUE',
        [this.startBcrTime, this.endBcrTime, this.revisedBcrNumber, this.updateBcrNumber]);
    this.emailBodyDefaultValue = this.startDate === this.endDate ?
      this.translateService.instant('VIEW_REPORT_SECTION.CIRCULATE_EMAIL.BODY.DEFAULT_SINGLE_DAY_VALUE',
        [this.startBcrTime]) :
      this.translateService.instant('VIEW_REPORT_SECTION.CIRCULATE_EMAIL.BODY.DEFAULT_VALUE',
        [this.startBcrTime, this.endBcrTime]);
    this.emailBodyRevisedDefaultValue = this.startDate === this.endDate ?
      this.translateService.instant('VIEW_REPORT_SECTION.CIRCULATE_EMAIL.BODY.DEFAULT_SINGLE_DAY_REVISED_VALUE',
        [this.startBcrTime]) :
      this.translateService.instant('VIEW_REPORT_SECTION.CIRCULATE_EMAIL.BODY.DEFAULT_REVISED_VALUE',
        [this.startBcrTime, this.endBcrTime]);
  }
}
