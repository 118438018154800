// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.collapsible-container .collapsible-heading-container {
  position: relative;
}
.collapsible-container .collapsible-heading-container .arrow {
  background-image: url('closed-chev-big.847c1b52d4b4a306.svg');
  width: 24px;
  height: 24px;
  position: absolute;
  top: 0;
  right: 0;
  transform: rotate(180deg);
}
.collapsible-container .collapsible-heading-container .arrow:hover {
  cursor: pointer;
}
.collapsible-container .collapsible-content-container {
  max-height: 100%;
  position: relative;
}
.collapsible-container .collapsible-content-container .scroll-top {
  position: absolute;
  right: -2px;
  bottom: -25px;
  transform: rotate(-90deg);
  width: 52px;
  height: 52px;
  background-image: url('filled-chev-up.11c94d73d6cc8e53.svg');
}
.collapsible-container .collapsible-content-container .scroll-top:hover {
  cursor: pointer;
}
.collapsible-container.collapsed .collapsible-heading-container .arrow {
  transform: none;
}
.collapsible-container.collapsed .collapsible-content-container {
  overflow: hidden;
  max-height: 0;
}
.collapsible-container.collapsed .scroll-top {
  display: none;
}
.collapsible-container .arrow {
  outline: 0;
  text-shadow: none;
}
.collapsible-container .arrow:hover,
.collapsible-container .arrow:focus,
.collapsible-container .arrow:active {
  outline: 1px solid;
  outline: auto -webkit-focus-ring-color;
  outline-color: #b39841;
}
`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
