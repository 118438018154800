// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.req-title-trans-modal .modal-description:last-child {
  margin-bottom: 15px;
}
.req-title-trans-modal .translation-details {
  font-family: 'Montserrat-Light', sans-serif;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}
.req-title-trans-modal form {
  padding-top: 20px;
}
.req-title-trans-modal form .trans-duedate-wrapper {
  max-width: 50%;
}
.req-title-trans-modal form .text-wrapper {
  width: 100%;
  margin-top: 20px;
}
.req-title-trans-modal form .text-wrapper textarea {
  width: 100%;
}
`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
