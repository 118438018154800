// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*----------------------------------------------------------------------------------------------Colors*/
/*----------------------------------------------------------------------------------------------Fonts*/
@font-face {
  font-family: 'WarnockPro-Regular';
  src: url('WarnockPro-Regular.52384534371887ec.eot');
  src: url('WarnockPro-Regular.a9452d76ab3cd12b.woff2') format('woff2'), url('WarnockPro-Regular.50ac2d396144917e.woff') format('woff'), url('WarnockPro-Regular.c6d2aa0f0a243b17.ttf') format('truetype');
}
@font-face {
  font-family: 'WarnockPro-Semibold';
  src: url('WarnockPro-Semibold.5527f98fac103e98.eot');
  src: url('WarnockPro-Semibold.fa72131c4b4d7de6.woff2') format('woff2'), url('WarnockPro-Semibold.e7a5d1af37c1939e.woff') format('woff'), url('WarnockPro-Semibold.885d23ffe51647f6.ttf') format('truetype');
}
@font-face {
  font-family: 'WarnockPro-Bold';
  src: url('WarnockPro-Bold.04ec19e8fb282a7b.eot');
  src: url('WarnockPro-Bold.ad38f222da689bec.woff2') format('woff2'), url('WarnockPro-Bold.8842adaf0ca2d562.woff') format('woff'), url('WarnockPro-Bold.d78b1b45fece0e2b.ttf') format('truetype');
}
@font-face {
  font-family: 'Montserrat-Regular';
  src: url('Montserrat-Regular.ffbd8f1fc587e82c.eot');
  src: url('Montserrat-Regular.f8a9025f8c91e3fc.woff2') format('woff2'), url('Montserrat-Regular.8c695c68e4f07aed.woff') format('woff'), url('Montserrat-Regular.754afb352c33217a.ttf') format('truetype');
}
@font-face {
  font-family: 'Montserrat-Light';
  src: url('Montserrat-Light.a2ae87bac8547b5a.eot');
  src: url('Montserrat-Light.197dc375564ff3f5.woff2') format('woff2'), url('Montserrat-Light.adfecf183cbc0770.woff') format('woff'), url('Montserrat-Light.b168e1b004117a18.ttf') format('truetype');
}
@font-face {
  font-family: 'Montserrat-LightItalic';
  src: url('Montserrat-LightItalic.0e2dcee9c4f449ee.eot');
  src: url('Montserrat-LightItalic.8ebf4be0febebcd4.woff2') format('woff2'), url('Montserrat-LightItalic.1efc114173f04d1a.woff') format('woff'), url('Montserrat-LightItalic.11cbdacc7818bdfb.ttf') format('truetype');
}
@font-face {
  font-family: 'Montserrat-Bold';
  src: url('Montserrat-Bold.6118685eaf94ebc4.eot');
  src: url('Montserrat-Bold.cd47a5db97758adf.woff2') format('woff2'), url('Montserrat-Bold.201ea68b33787439.woff') format('woff'), url('Montserrat-Bold.76de74475d79c182.ttf') format('truetype');
}
@font-face {
  font-family: 'Montserrat-Semibold';
  src: url('Montserrat-SemiBold.1f05f3c86ab2ee20.eot');
  src: url('Montserrat-SemiBold.430052671068fbd0.woff2') format('woff2'), url('Montserrat-SemiBold.d8107065216232d5.woff') format('woff'), url('Montserrat-SemiBold.611c7e7272a8a9c2.ttf') format('truetype');
}
.vs-copy-btn,
.vs-print-btn,
.vs-notify-btn,
.vs-email-btn {
  display: inline-block;
  height: 40px;
  width: 40px;
  border-radius: 25px;
  border: 2px solid #444444;
  background: #ffffff;
  margin: 0 10px 5px 10px;
  padding: 7.5px;
  text-align: center;
}
.vs-copy-btn:hover,
.vs-print-btn:hover,
.vs-notify-btn:hover,
.vs-email-btn:hover {
  background-color: #b39841;
  border-color: #b39841;
  cursor: pointer;
}
.vs-copy-btn--ripple,
.vs-print-btn--ripple,
.vs-notify-btn--ripple,
.vs-email-btn--ripple {
  position: relative;
  overflow: hidden;
}
.vs-copy-btn img,
.vs-print-btn img,
.vs-notify-btn img,
.vs-email-btn img {
  position: relative;
  width: 20px;
  height: 20px;
}
.order-of-business-ckEditor {
  background-color: #ffffff;
  padding: 20px;
  margin-top: 15px;
}
.week-announcement,
.week-proposed-arrangement {
  background-color: #ffffff;
  padding: 20px;
  margin-top: 15px;
  margin-bottom: 15px;
}
.week-announcement .day-items,
.week-proposed-arrangement .day-items {
  border: 1px solid #cccccc;
  padding: 8px;
}
`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
