import {
  APP_INITIALIZER,
  Injector,
  NgModule,
} from "@angular/core";
import { CommonModule, APP_BASE_HREF } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { routing } from "./app.routes";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { OverlayModule } from "@angular/cdk/overlay";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TabViewModule } from "primeng/tabview";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { HTTP_INTERCEPTORS, HttpClient } from "@angular/common/http";
import { ClientFrameworkModule } from "proceduralsystem-clientcomponents";
import { AppComponent } from "./app.component";
import { PageComponent } from "./page.component";
import { SpanStylePipe } from "./pipes/span-style.pipe";
import { AmendmentByValuePipe } from "./pipes/amendment-by-value.pipe";

import {
  EventType,
  InteractionType,
  PublicClientApplication,
} from "@azure/msal-browser";
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalService,
  MsalGuard,
  MsalBroadcastService,
} from "@azure/msal-angular";
import { AuthenticationResult } from "@azure/msal-common/dist/response/AuthenticationResult";
import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { forkJoin, map, Observable, of, switchMap } from "rxjs";
import { NgxPaginationModule } from "ngx-pagination";
import { environment } from "src/environments/environment";
import { ResponseInterceptor } from "./shared/interceptors/response.interceptor";
import { UserAuthInterceptor } from "./shared/interceptors/user-auth.interceptor";
import { AppConfigService } from "./services/app-config.service";
import { TextMaskModule } from 'angular-ngx-textmask';
import { DeleteModalComponent } from "./shared/modals/delete-modal/delete-modal.component";
import { WorkItemInstanceComponent } from "./shared/components/work-item-instance/work-item-instance.component";
import { DayInstanceComponent } from "./bcr-report/day-instance/day-instance.component";
import { AddNewSectionComponent } from "./bcr-report/day-instance/add-new-section/add-new-section.component";
import { DayArrangementComponent } from "./bcr-report/day-instance/day-arrangement/day-arrangement.component";
import { MoveSectionModalComponent } from "./bcr-report/day-instance/section/modals/move-section-modal/move-section-modal.component";
import { RequestTitleTranslationModalComponent } from "./bcr-report/day-instance/section/modals/request-title-translation-modal/request-title-translation-modal.component";
import { SectionsFormComponent } from "./bcr-report/day-instance/section/sections-form/sections-form.component";
import { SectionComponent } from "./bcr-report/day-instance/section/section.component";
import { ActionBarEmailModalComponent } from "./bcr-report/modals/action-bar-email-modal/action-bar-email-modal.component";
import { CirculateBcrModalComponent } from "./bcr-report/modals/circulate-bcr-modal/circulate-bcr-modal.component";
import { ViewReportComponent } from "./bcr-report/view-report.component";
import { CreateReportComponent } from "./create-report/create-report.component";
import { CancelConfirmModalComponent } from "./create-report/cancel-confirm-modal/cancel-confirm-modal.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { CurrentDraftReportComponent } from "./dashboard/current-draft-report/current-draft-report.component";
import { DeleteConfirmationModalComponent } from "./dashboard/delete-confirmation-modal/delete-confirmation-modal.component";
import { OutstandingItemsComponent } from "./dashboard/outstanding-items/outstanding-items.component";
import { OutstandingWorkItemsModalComponent } from "./dashboard/outstanding-work-items-modal/outstanding-work-items-modal.component";
import { OrderOfBusinessComponent } from "./order-of-business/order-of-business.component";
import { AnnouncementBusinessItemComponent } from "./order-of-business/announcement-business-item/announcement-business-item.component";
import { AnnouncementProposedArrangementComponent } from "./order-of-business/announcement-proposed-arrangement/announcement-proposed-arrangement.component";
import { ActionBarEmailModalObComponent } from "./order-of-business/modals/action-bar-email-modal-ob/action-bar-email-modal-ob.component";
import { CirculateModalComponent } from "./order-of-business/modals/circulate-modal/circulate-modal.component";
import { ConfirmCancelModalComponent } from "./order-of-business/modals/confirm-cancel-modal/confirm-cancel-modal.component";
import { RapportuersReportComponent } from "./rapportuers-report/rapportuers-report.component";
import { AnnouncementDaysComponent } from "./rapportuers-report/announcement-days/announcement-days.component";
import { CancelReportComponent } from "./rapportuers-report/modals/cancel-report/cancel-report.component";
import { SendToRapporteurComponent } from "./rapportuers-report/modals/send-to-rapporteur/send-to-rapporteur.component";
import { SearchComponent } from "./search/search.component";
import { EmitterCollapsiblePanelComponent } from "./shared/components/emitter-collapsible-panel/emitter-collapsible-panel.component";
import { AccordionHeaderComponent } from "./shared/components/accordion-header/accordion-header.component";
import { CustomModalComponent } from "./shared/components/custom-modal/custom-modal.component";
import { CustomMultiselectComponent } from "./shared/components/custom-multiselect/custom-multiselect.component";
import { CustomTypeaheadComponent } from "./shared/components/custom-typeahead/custom-typeahead.component";
import { DatePickerCustomComponent } from "./shared/components/datepicker-custom/datepicker-custom.component";
import { ToggleableAccordionComponent } from "./shared/components/toggleable-accordion/toggleable-accordion.component";
import { WorkItemFormComponent } from "./shared/components/work-item-form/work-item-form.component";
import { AddNewSectionDeleteModalComponent } from "./shared/modals/add-new-section-delete-modal/add-new-section-delete-modal.component";

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, "./assets/i18n/", ".json");
}

export class TranslationHandlerService implements MissingTranslationHandler {
  /**
   * Missing translation handler.
   */
  public handle(params: MissingTranslationHandlerParams): Observable<string> {
    return of(params.key);
  }
}

/**
 * MSAL Angular retrieve tokens for authorizaion
 */
export function MSALInstanceFactory(
  config: AppConfigService
): PublicClientApplication {
  const msalConfig = environment.msalConfig;
  const endpoint = config.getValue("AzureAd");
  if (endpoint) {
    msalConfig.auth.clientId = endpoint.ClientId;
    msalConfig.auth.authority = `${endpoint.Instance}${endpoint.TenantId}`;
  }

  const msalInstance = new PublicClientApplication(msalConfig);

  // Account selection logic is app dependent. Adjust as needed for different use cases.
  const account = msalInstance.getActiveAccount();
  if (!account) {
    // Set active account on page load
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length > 0) {
      msalInstance.setActiveAccount(accounts[0]);
    } else {
      // handle auth redirect/do all initial setup for msal
      msalInstance.addEventCallback((event) => {
        // set active account after redirect
        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
          msalInstance.setActiveAccount(
            (event.payload as AuthenticationResult).account
          );
        }
      });
    }
  }

  return msalInstance;
}

export function MSALInterceptorConfigFactory(
  config: AppConfigService
): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, string[]>();
  let endpoint = config.getValue("ApiEndpoint") as any;
  if (endpoint) {
    protectedResourceMap.set(endpoint.url, endpoint.scopes);
  }
  endpoint = config.getValue("CommsEndpoint");
  if (endpoint) {
    protectedResourceMap.set(endpoint.url, endpoint.scopes);
  }

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(
  config: AppConfigService
): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: { scopes: config.getValue("ApiEndpoint").scopes },
  };
}

@NgModule({
  declarations: [
    AppComponent,
    PageComponent,
    SpanStylePipe,
    AmendmentByValuePipe,
    DeleteModalComponent,
    WorkItemInstanceComponent,
    DayInstanceComponent,
    AddNewSectionComponent,
    DayArrangementComponent,
    MoveSectionModalComponent,
    RequestTitleTranslationModalComponent,
    SectionsFormComponent,
    SectionComponent,
    ActionBarEmailModalComponent,
    CirculateBcrModalComponent,
    ViewReportComponent,
    CreateReportComponent,
    CancelConfirmModalComponent,
    DashboardComponent,
    CurrentDraftReportComponent,
    DeleteConfirmationModalComponent,
    OutstandingItemsComponent,
    OutstandingWorkItemsModalComponent,
    OrderOfBusinessComponent,
    AnnouncementBusinessItemComponent,
    AnnouncementProposedArrangementComponent,
    ActionBarEmailModalObComponent,
    CirculateModalComponent,
    ConfirmCancelModalComponent,
    RapportuersReportComponent,
    AnnouncementDaysComponent,
    CancelReportComponent,
    SendToRapporteurComponent,
    SearchComponent,
    EmitterCollapsiblePanelComponent,
    AccordionHeaderComponent,
    CustomModalComponent,
    CustomMultiselectComponent,
    CustomTypeaheadComponent,
    DatePickerCustomComponent,
    ToggleableAccordionComponent,
    WorkItemFormComponent,
    WorkItemInstanceComponent,
    AddNewSectionDeleteModalComponent,
    DeleteModalComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule,
    ClientFrameworkModule,
    BrowserAnimationsModule,
    NgbModule,
    NgxPaginationModule,
    TextMaskModule,
    OverlayModule,
    FormsModule,
    ReactiveFormsModule,
    TabViewModule,
    CKEditorModule,
    DragDropModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient, AppConfigService],
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: TranslationHandlerService,
      },
      defaultLanguage: "en",
      isolate: false,
    }),
    routing,
  ],
  exports: [
    DeleteModalComponent,
    WorkItemInstanceComponent,
    DayInstanceComponent,
    AddNewSectionComponent,
    DayArrangementComponent,
    MoveSectionModalComponent,
    RequestTitleTranslationModalComponent,
    SectionsFormComponent,
    SectionComponent,
    ActionBarEmailModalComponent,
    CirculateBcrModalComponent,
    ViewReportComponent,
    CreateReportComponent,
    CancelConfirmModalComponent,
    DashboardComponent,
    CurrentDraftReportComponent,
    DeleteConfirmationModalComponent,
    OutstandingItemsComponent,
    OutstandingWorkItemsModalComponent,
    OrderOfBusinessComponent,
    AnnouncementBusinessItemComponent,
    AnnouncementProposedArrangementComponent,
    ActionBarEmailModalObComponent,
    CirculateModalComponent,
    ConfirmCancelModalComponent,
    RapportuersReportComponent,
    AnnouncementDaysComponent,
    CancelReportComponent,
    SendToRapporteurComponent,
    SearchComponent,
    EmitterCollapsiblePanelComponent,
    AccordionHeaderComponent,
    CustomModalComponent,
    CustomMultiselectComponent,
    CustomTypeaheadComponent,
    DatePickerCustomComponent,
    ToggleableAccordionComponent,
    WorkItemFormComponent,
    WorkItemInstanceComponent,
    AddNewSectionDeleteModalComponent,
    DeleteModalComponent
  ],
  providers: [
    { provide: APP_BASE_HREF, useValue: "/" },
    { provide: HTTP_INTERCEPTORS, useClass: UserAuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: (config: AppConfigService, injector: Injector) => () => {
        return config.init().pipe(
          switchMap(initResult => {
            const translateService = injector.get(TranslateService);
            return forkJoin([
              translateService.get("en"),
              translateService.reloadLang("ga"),
            ]).pipe(
              map(() => initResult)
            );
          })
        ).toPromise();
      },
      deps: [AppConfigService, Injector],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
      deps: [AppConfigService],
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
      deps: [AppConfigService],
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
      deps: [AppConfigService],
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
