// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.advanced-filter {
  border-bottom: 1px solid #d4cfbe;
  border-top: 1px solid #d4cfbe;
}
.col-sm-1:nth-of-type(1),
.col-sm-1:nth-of-type(2) {
  padding-left: 15px;
}
.col-sm-6:nth-of-type(2) {
  padding-left: 15px;
}
.advanced-filter-tags {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}
.search-arrangement {
  padding: 10px;
  height: 40px;
}
.report-placholder {
  border: 1px solid #d4cfbc;
  border-left: 5px solid #b39841;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}
.report-placholder .report-title {
  margin-left: 45px;
  vertical-align: -15px;
}
`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
