// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tab-container .tab-container-finalise ::ng-deep .p-tabview p-component ::ng-deep p-element .ui-tabview {
  margin-left: 25px;
}
.tab-container .tab-container-finalise ::ng-deep .p-tabview p-component ::ng-deep p-element .ui-tabview-top ul {
  padding-left: 0px;
  margin-bottom: 20px;
  border-bottom: 1px solid #d4cfbe;
}
.tab-container .tab-container-finalise ::ng-deep .p-tabview p-component ::ng-deep p-element .ui-tabview-top ul li {
  display: inline-block;
  list-style: none;
}
.tab-container .tab-container-finalise ::ng-deep .p-tabview p-component ::ng-deep p-element .ui-tabview-top ul li.ui-state-default {
  display: inline-block;
  height: auto;
  width: auto;
  padding: 20px 30px 15px 30px;
  background-color: white;
  background: none;
  color: #444;
  border: none;
  font-size: 15px;
  font-family: 'Montserrat-Regular', sans-serif;
  font-weight: bold;
  vertical-align: middle;
  text-align: center;
}
.tab-container .tab-container-finalise ::ng-deep .p-tabview p-component ::ng-deep p-element .ui-tabview-top ul li.ui-state-default.ui-state-active {
  box-shadow: inset 0px -5px 0px 0px #b39841;
}
.tab-container .tab-container-finalise ::ng-deep .p-tabview p-component ::ng-deep p-element .ui-tabview-top ul li :hover {
  text-decoration: none;
}
.tab-container .tab-container-finalise ::ng-deep .p-tabview p-component ::ng-deep p-element .ui-tabview-top ul li a {
  text-decoration: none !important;
  color: #444 !important;
}
.tab-container .tab-container-finalise ::ng-deep .p-tabview p-component ::ng-deep p-element .ui-tabview-top .ui-tabview-panel.ui-helper-hidden {
  display: none;
}
.tab-container .tab-container-finalise ::ng-deep .p-tabview p-component ::ng-deep p-element ::ng-deep oir-pagination ul {
  border-bottom: 0!important;
}
::ng-deep #pn_id_1_content > div > div > p-tabview > div > div.p-tabview-nav-container > div > ul,
::ng-deep #pn_id_2_content > div > div > p-tabview > div > div.p-tabview-nav-container > div > ul {
  margin-left: 0;
}
::ng-deep #pn_id_1_content > div > div > p-tabview > div > div.p-tabview-nav-container > div,
::ng-deep #pn_id_2_content > div > div > p-tabview > div > div.p-tabview-nav-container > div {
  margin-left: -25px;
}
`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
