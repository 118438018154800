// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.day-instance-section-wraper {
  padding: 10px;
  border: 1px solid #d4cfbe;
}
.isEditMode {
  opacity: 0.5;
  pointer-events: none;
}
.custom-small-title {
  font-weight: bold;
  padding-top: 10px;
}
.col-sm-5:nth-of-type(1),
.col-sm-5:nth-of-type(2) {
  padding-right: 15px;
}
.col-sm-10 {
  padding-right: 15px;
}
.day-template-custom-margins {
  margin-top: 10px;
  margin-bottom: 0;
}
.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.cdk-drag-placeholder {
  opacity: 0;
}
.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
.dragable-items.cdk-drop-list-dragging .drag-item:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
.day-instance-disabled input {
  pointer-events: none!important;
  background-color: #bfbbaa !important;
  border: 1px solid #bfbbaa !important;
}
.day-instance-disabled ::ng-deep oir-toggle {
  pointer-events: none;
}
`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
