import {
    Component,
    Input,
    ViewChild,
    OnInit,
    Output,
    EventEmitter
  } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { CustomModalComponent } from 'src/app/shared/components/custom-modal/custom-modal.component';
import { TranslationModalDetails, TranslationWithdrawModalDetails } from 'src/app/shared/models/report.model';
import * as moment from 'moment';

  @Component({
    selector: 'oir-request-title-translation-modal',
    templateUrl: './request-title-translation-modal.component.html',
    styleUrls: ['./request-title-translation-modal.component.less']
  })
  
  export class RequestTitleTranslationModalComponent implements OnInit {
    @ViewChild('requestTitleTranslationModal') requestTitleTranslationModal: CustomModalComponent;
    @ViewChild('withdrawConfirmationModal') withdrawConfirmationModal: CustomModalComponent;
    @Input() textToReview: string;
    @Input() dueDate: string;
    @Output() public translationRequested = new EventEmitter<TranslationModalDetails|TranslationWithdrawModalDetails>();

    public form: UntypedFormGroup;
    public isWithdraw = false;
    public isVisible = false;
  
    constructor(
      private formBuilder: UntypedFormBuilder,
    ) {}
  
    ngOnInit(): void {
      this.form = this.formBuilder.group({
        dueDate: ['', Validators.required],
        note: '',
      });
    }
  
    public toggle(isWithdraw = false): void {
      this.isVisible = !this.isVisible;
      this.isWithdraw = !!isWithdraw
      this.form.reset();

      if (isWithdraw) {
        this.form.get('dueDate').disable();
      } else {
        this.form.get('dueDate').enable();
      }

      this.requestTitleTranslationModal.toggle();
    }

    public sendRequest(confirmWithdrawal = false): void {
      if (confirmWithdrawal) {
        this.requestTitleTranslationModal.toggle();
        this.withdrawConfirmationModal.toggle();
        return;
      }

      const formValue = this.form.getRawValue();
      const payload = {
        dueDate: formValue.dueDate && moment.utc(formValue.dueDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        note: formValue.note || '',
      } as TranslationModalDetails;

      if (this.isWithdraw) {
        delete payload.dueDate;
        this.withdrawConfirmationModal.toggle();
        this.isVisible = false;
        this.translationRequested.emit(payload as TranslationWithdrawModalDetails);
      } else {
        this.translationRequested.emit(payload);
      }
    }
}
