import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, ChangeDetectionStrategy, AfterViewInit } from '@angular/core';
import { TabIndexPipe } from 'proceduralsystem-clientcomponents';
import { ReportStoreService } from 'src/app/services/report-store.service';

@Component({
  selector: 'oir-toggleable-accordion',
  templateUrl: './toggleable-accordion.component.html',
  styleUrls: ['./toggleable-accordion.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToggleableAccordionComponent implements OnInit, AfterViewInit {
  @Input('shouldDisable') shouldDisable = false;
  @Input('subAccordion') subAccordion = false;
  @Input('defaultClosed') defaultClosed: any;
  @Input() bsDayDate;
  @Input() businessDaySectionHeader;
  @Input('canToggle') canToggle: boolean = true;
  @Input() defaultOpened = false;

  @Input('ariaAccordionDescription') ariaAccordionDescription;
  @Output('beforeToggle') beforeToggle: EventEmitter<any> = new EventEmitter();
  @Output('afterToggle') afterToggle: EventEmitter<any> = new EventEmitter();

  @Output('toggledWhenOpened') toggledWhenOpened: EventEmitter<any> = new EventEmitter();
  @Output('toggledWhenDefaultOpened') toggledWhenDefaultOpened: EventEmitter<any> = new EventEmitter();

  @ViewChild('btnToggle') btnToggle;
  @ViewChild('accordionPanel') collapsablePanel: ElementRef;

  public openImageUrl = 'url(./assets/unfilled-plus.svg)';
  public readonly closeImageUrl = 'url(./assets/unfilled-circle-x.svg)';

  public set setOpenImageUrl(value: 'filled-circle-true' | 'unfilled-plus') {
    this.openImageUrl = `url(./assets/${value}.svg)`;
  }

  isOpen: boolean;

  constructor(private reportStoreService: ReportStoreService) { }

  ngOnInit() {
    this.isOpen = this.isOpened();
    this.setSectionIcon();
  }

  ngAfterViewInit() {
    if (this.defaultClosed === 'true' || this.defaultClosed || this.defaultOpened) {
      this.click();
      this.toggledWhenDefaultOpened.emit(true);
    }
  }

  public click() {
    if (this.btnToggle && this.btnToggle.nativeElement) {
      this.btnToggle.nativeElement.click();
    }
  }

  public isOpened() {
    return this.btnToggle && this.btnToggle.nativeElement && this.btnToggle.nativeElement.classList.contains('opened');
  }

  public toggle($event) {
    if (this.canToggle) {
      this.isOpen = !this.isOpen;
      this.beforeToggle.emit(!this.isOpened());

      const $sender = $event.currentTarget;
      let parent = $sender.offsetParent;
      const iconClassList = $sender.getElementsByTagName('button')[0].classList;

      if (parent == null) {
        parent = $sender.parentElement.parentElement;
      }

      if (!$sender.classList.contains('opened') && parent.classList.contains('disabled')) {
        return;
      }

      iconClassList.toggle('open-left');
      iconClassList.toggle('close-left');
      $sender.classList.toggle('opened');
      $sender.parentElement.children[1].classList.toggle('opened');
      $sender.parentElement.nextElementSibling.classList.toggle('hidden');
      this.setTabIndex();
      $event.preventDefault();

      this.afterToggle.emit(this.isOpened());
    } else {
      this.toggledWhenOpened.emit();
    }
  }

  setTabIndex() {
    new TabIndexPipe().transform(this.isOpened(), this.collapsablePanel);
  }

  setSectionIcon(): void {

    if (this.businessDaySectionHeader && this.businessDaySectionHeader.bdSectionId && this.reportStoreService.doesSectionHaveAllRequiredData(this.bsDayDate, this.businessDaySectionHeader.bdSectionId)) {
      this.setOpenImageUrl = 'filled-circle-true';
    } else {
      this.setOpenImageUrl = 'unfilled-plus';
    }

  }
}

